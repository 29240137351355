import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ExceptionScheduleTemplate, MappedDataPoint } from 'src/app/models/data-point';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BackendService } from '../../services/backend/backend.service';
import { NotificationService } from '../../services/notification/notification.service';
import { UtilsService } from '../../services/utils/util.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cbms-exception-schedule-dialog',
    templateUrl: './exception-schedule-dialog.component.html',
    styleUrls: ['./exception-schedule-dialog.component.scss']
})
export class ExceptionScheduleDialogComponent implements OnInit {
    @Input()
    public exceptionScheduleInput: any;
    @Input()

    public selectedDataPoint: MappedDataPoint;
    public isLoading: boolean = false;
    public isRequestDone: boolean = false;
    public saveTemplateForm: FormGroup;
    public exceptionSchedule: any;
    public exceptionScheduleObject: any;

    private getExceptionScheduleSubscription: Subscription;
    public responseMessage: string;
    public isSuccessResponse: boolean;



    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private backendService: BackendService,
        private formBuilder: FormBuilder,
        private utilsService: UtilsService,
        private notificationService: NotificationService) { }


    ngOnInit() {
        this.selectedDataPoint = this.config.data;
            this.getExceptionScheduleSubscription = this.backendService.getDataPointExceptionSchedule(this.selectedDataPoint.id).subscribe(response => {
                this.exceptionScheduleObject = response;
                this.exceptionSchedule = this.utilsService.mapExceptionSchedule(response);
                this.isRequestDone = true;
                this.isSuccessResponse = true;
            }, error => {
                this.responseMessage = '';
                this.isRequestDone = true;
                this.isSuccessResponse = false;
            });

        this.saveTemplateForm = this.formBuilder.group({
            templateName: new FormControl('', Validators.required),
        });

    }

    get templateName() {
        return this.saveTemplateForm.get('templateName') as FormControl;
    }

    public close() {
        if (this.getExceptionScheduleSubscription) {
            this.getExceptionScheduleSubscription.unsubscribe();
        }
        this.ref.close();
    }

    public saveTemplate() {
        let templateName = this.saveTemplateForm.value.templateName;
        if (!templateName) { return; }

        let exceptionScheduleTemplate: ExceptionScheduleTemplate = {
            name: templateName,
            exceptionScheduleObject: this.exceptionScheduleObject
        };

        this.isLoading = true;

        this.backendService.saveScheduleAsTemplate(exceptionScheduleTemplate).subscribe(response => {
            this.isLoading = false;
            this.templateName.setValue('');
            this.notificationService.addSuccessMessage('Exception Schedule', 'Template successfully saved.', false);
        }, (error) => {
            this.isLoading = false;
            this.notificationService.addErrorMessage('Exception Schedule', error);
        });
    }
}
