import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { FilterService } from '../filter.service';
import _ from 'lodash';
import { UtilsService } from 'src/app/services/utils/util.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { DataPointTag } from 'src/app/models/data-point';
import { DataService } from 'src/app/services/data/data.service';

@Component({
    selector: 'cbms-tag-filter',
    templateUrl: './tag-filter.component.html',
    styleUrls: ['../filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TagFilterComponent implements OnInit {
    @Output()
    valueChange = new EventEmitter();

    @Input()
    selectedTagList: DataPointTag[];

    @Input()
    tagLogicalOperator: string;

    tagList: DataPointTag[];

    constructor(
        private filterService: FilterService,
        private utilsService: UtilsService,
        private dataService: DataService,
        private backendService: BackendService) {
    }

    public ngOnInit(): void {
        this.backendService.getDataPointTagsAutoSuggestions().subscribe((response: any) => {
            this.tagList = this.utilsService.orderListByFieldName(response.content, 'name');
        });

        // add new created tags in tagList dd
        this.dataService.newTagsCreatedStatus.subscribe(tagsStatus => {
            if (!tagsStatus || !tagsStatus.dataPointsTagsUpdated) {
                return;
            }

            this.backendService.getDataPointTagsAutoSuggestions().subscribe((response: any) => {
                this.tagList = this.utilsService.orderListByFieldName(response.content, 'name');
                this.updateTagSelectionBasedOnCurrentTagList();
            });
        });
    }

    public emitTagsData() {
        this.valueChange.emit({ tagList: this.selectedTagList, tagLogicalOperator: this.tagLogicalOperator });
    }

    private updateTagSelectionBasedOnCurrentTagList() {
        //remove selection for deleted tags and update the model
        this.selectedTagList = this.selectedTagList.filter((selectedTag) => this.tagList.findIndex(existingTag => existingTag.name === selectedTag.name) !== -1)
        this.emitTagsData();
    }
}
