<h2>SMART Alert Console</h2>
<div class="table-wrapper">
    <p-table
        *ngIf="triggeredAlerts$ | async as dataSource; else loading"
        class="cbms-generic-table-style"
        #dt
        [value]="dataSource"
        [columns]="cols"
        [rows]="50"
        [paginator]="true"
        [scrollable]="true"
        responsiveLayout="scroll"
        scrollHeight="flex"
        sortField="startDate"
        [sortOrder]="-1"
        [rowHover]="true"
        dataKey="id"
        stateStorage="session"
        stateKey="alert-console-session"
        (sortFunction)="customSort($event)"
        [customSort]="true"
    >
        <ng-template pTemplate="caption">
            <div class="flex align-items-center">
                <button
                    pButton
                    label="Clear"
                    class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                    icon="pi pi-filter-slash"
                    (click)="clear(dt)"
                ></button>
                <!-- <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto" [disabled]="true">
                    Export Alarm Log
                </button> -->
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="flex: 0 0 3rem !important; min-width: 3rem">
                    <div class="cbms-disply-flex-row-centered">
                        <span>Actions</span>
                    </div>
                </th>
                <th style="min-width: 6rem" pSortableColumn="status.value">
                    <div class="flex align-items-center">
                        Status <p-sortIcon field="status.value"></p-sortIcon>
                        <p-columnFilter
                            field="status"
                            matchMode="in"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
                        >
                            <ng-template pTemplate="header">
                                <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">Status Picker</span>
                                </div>
                            </ng-template>
                            <ng-template
                                pTemplate="filter"
                                let-value
                                let-filter="filterCallback"
                            >
                                <p-multiSelect
                                    [ngModel]="value"
                                    [options]="statusOptions"
                                    placeholder="Any"
                                    (onChange)="filter($event.value)"
                                    optionLabel="value"
                                >
                                    <ng-template let-option pTemplate="item">
                                        <div
                                            class="inline-block vertical-align-middle"
                                        >
                                            <span class="ml-1 mt-1">{{
                                                option.value
                                            }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th style="min-width: 10rem" pSortableColumn="startDate">
                    <div class="flex align-items-center">
                        Start <p-sortIcon field="startDate"></p-sortIcon>
                        <p-columnFilter
                            type="date"
                            field="startDate"
                            display="menu"
                        ></p-columnFilter>
                    </div>
                </th>
                <th style="min-width: 10rem" pSortableColumn="endDate">
                    <div class="flex align-items-center">
                        End <p-sortIcon field="endDate"></p-sortIcon>
                        <p-columnFilter
                            type="date"
                            field="endDate"
                            display="menu"
                        ></p-columnFilter>
                    </div>
                </th>
                <th style="min-width: 10rem" pSortableColumn="durationInHours">
                    <div class="flex align-items-center">
                        Duration[Hours:Minutes]
                        <p-sortIcon field="durationInHours"></p-sortIcon>
                        <p-columnFilter
                            type="text"
                            field="durationInHours"
                            display="menu"
                            matchMode="contains"
                        ></p-columnFilter>
                    </div>
                </th>
                <th style="min-width: 15rem" pSortableColumn="alarmConfig.name">
                    <div class="flex align-items-center">
                        Alert Name
                        <p-sortIcon field="alarmConfig.name"></p-sortIcon>
                        <p-columnFilter
                            type="text"
                            field="alarmConfig.name"
                            display="menu"
                            matchMode="contains"
                        ></p-columnFilter>
                    </div>
                </th>
                <th style="min-width: 12rem" pSortableColumn="alarmConfig.type">
                    <div class="flex align-items-center">
                        Alert Type
                        <p-sortIcon field="alarmConfig.type"></p-sortIcon>
                        <p-columnFilter
                            type="text"
                            field="alarmConfig.type"
                            display="menu"
                            matchMode="contains"
                        ></p-columnFilter>
                    </div>
                </th>
                <th style="min-width: 12rem" pSortableColumn="priority.value">
                    <div class="flex align-items-center">
                        Alert Priority
                        <p-sortIcon field="priority.value"></p-sortIcon>
                        <p-columnFilter
                            field="priority"
                            matchMode="in"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false"
                        >
                            <ng-template pTemplate="header">
                                <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold"
                                        >Priority Picker</span
                                    >
                                </div>
                            </ng-template>
                            <ng-template
                                pTemplate="filter"
                                let-value
                                let-filter="filterCallback"
                            >
                                <p-multiSelect
                                    [ngModel]="value"
                                    [options]="priorityOptions"
                                    placeholder="Any"
                                    (onChange)="filter($event.value)"
                                    optionLabel="value"
                                >
                                    <ng-template let-option pTemplate="item">
                                        <div
                                            class="inline-block vertical-align-middle"
                                        >
                                            <span class="ml-1 mt-1">{{
                                                option.value
                                            }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th
                    style="min-width: 12rem"
                    pSortableColumn="alarmConfig.site.name"
                >
                    <div class="flex align-items-center">
                        Site
                        <p-sortIcon field="alarmConfig.site.name"></p-sortIcon>
                        <p-columnFilter
                            type="text"
                            field="alarmConfig.site.name"
                            display="menu"
                            matchMode="contains"
                        ></p-columnFilter>
                    </div>
                </th>
                <th style="min-width: 15rem" pSortableColumn="comment">
                    <div class="flex align-items-center">
                        Comment <p-sortIcon field="comment"></p-sortIcon>
                        <p-columnFilter
                            type="text"
                            field="comment"
                            display="menu"
                            matchMode="contains"
                        ></p-columnFilter>
                    </div>
                </th>
                <th style="min-width: 10rem">
                    <div class="flex align-items-center">Edit</div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rule let-columns="columns">
            <tr>
                <td style="flex: 0 0 3rem !important; min-width: 3rem">
                    <button
                        pButton
                        type="button"
                        icon="pi pi-bell"
                        class="ui-button-info cbms-margin-left"
                        (click)="viewAlertDetails(rule)"
                    ></button>
                </td>
                <td
                    *ngFor="let col of columns"
                    style="flex-basis: {{ col.width }}; min-width: {{
                        col.width
                    }}"
                >
                    <ng-container [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'status.value'">
                            {{ rule['status']['value'] === 'EXPIRED' ? 'EXPIRED' : '' }}
                            <p-dropdown
                                appendTo="body"
                                *ngIf="rule['status']['value'] !== 'EXPIRED'"
                                class="dd-min-width200 min-width300"
                                name="selectedSite"
                                [options]="changeStatusOptionsList"
                                [(ngModel)]="rule['status']['value']"
                                placeholder="Change Status"
                                [filter]="true"
                                filterBy="label"
                                (onChange)="onStatusChange(rule)"
                            >
                            </p-dropdown>
                        </ng-container>
                        <ng-container *ngSwitchCase="'alarmConfig.name'">
                            {{ rule['alarmConfig']['name'] }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'alarmConfig.type'">
                            {{ rule['alarmConfig']['type'] }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'priority.value'">
                            {{ rule['priority']['value'] }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'alarmConfig.site.name'">
                            {{ rule['alarmConfig']['site']['name'] }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'startDate'">
                            {{ rule[col.field] }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'endDate'">
                            {{
                                rule[col.field] === null
                                    ? 'N/A'
                                    : rule[col.field]
                            }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'comment'">
                            {{ rule.comment }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>{{
                            rule[col.field]
                        }}</ng-container>
                    </ng-container>
                </td>
                <td>
                    <button
                        *ngIf="!editing[rule.id]"
                        pButton
                        type="button"
                        icon="pi pi-pencil"
                        class="ui-button-info"
                        (click)="onEditInit(rule)"
                    ></button>

                    <p-dialog
                        *ngIf="editing[rule.id]"
                        [(visible)]="editing[rule.id]"
                        [style]="{ width: '960px' }"
                        header="Comment"
                        [modal]="true"
                        styleClass="p-fluid"
                        [closable]="false"
                    >
                        <ng-template pTemplate="content">
                            <textarea
                                style="width: 100%"
                                rows="5"
                                cols="60"
                                pInputTextarea
                                [(ngModel)]="rule.comment"
                                maxlength="500"
                            ></textarea>
                        </ng-template>

                        <ng-template pTemplate="footer">
                            <button
                                pButton
                                class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                                type="submit"
                                data-cy="send-btn"
                                label="Submit"
                                (click)="saveCommentsChanges(rule)"
                            ></button>
                            <button
                                pButton
                                class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
                                label="Cancel"
                                (click)="onEditCancel(rule)"
                                data-cy="close-btn"
                            ></button>
                        </ng-template>
                    </p-dialog>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<ng-template #loading>
    <div class="cbms-spinner-wrapper">
        <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
    </div>
</ng-template>
