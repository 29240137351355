<main class="content">
    <div class="color-box box-red"></div>
    <div class="color-box box-yellow"></div>
    <div class="brand">
        <div class="heading-optimum">
            <h1>E.ON Optimum Connect and Control.</h1>
            <p>Energy management has never been so easy.</p>
        </div>
        <div class="main-footer">
            <p>© {{ currentYear }} E.ON UK</p>
        </div>
    </div>
    <div class="main">
        <div class="main-content">
            <div class="main-heading">
                <p class="eon_optimum_logo">
                    <a href="https://www.eon.com/?ref=optimum" rel="noopener noreferrer"><span></span></a></p>
            </div>
            <div class="form-wrapper">
                <button id="backToLogin" (click)="goBack()">Back to login</button>
                <form [formGroup]="form">
                    <h3>Enter corporate email</h3>
                    <input autocomplete="on" name="email" formControlName="email" id="email" class="email-input">
                    <div class="buttons">
                        <button (click)="signInTo(IDP_PROVIDER.TESCO_CC)">Sign In to C&amp;C Core App</button>
                        <button (click)="signInTo(IDP_PROVIDER.TESCO_DASHBOARDS)">Sign In to C&amp;C Dashboard App</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</main>
