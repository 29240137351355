import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { Customer } from 'src/app/models/customer';
import { Site } from 'src/app/models/site';
import { UserFiltering, Role, RoleResource, User } from 'src/app/models/user';
import { BackendService } from 'src/app/services/backend/backend.service';
import { UtilsService } from 'src/app/services/utils/util.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { DataService } from 'src/app/services/data/data.service';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { FilterService } from 'src/app/components/filter/filter.service';
import moment from 'moment-timezone';
import { SelectItem } from 'primeng/api';
import { TimeFramesValues } from 'src/app/config/constants';
import { HttpResponse } from '@angular/common/http';
import _ from 'lodash';
import { SortOptions } from 'src/app/models/columns-filter.interface';

const ROLES_MAPPER = {
    CUSTOMER_USER: 'Customer User',
    CUSTOMER_OPERATOR: 'Customer Operator',
    GLOBAL_ADMIN: 'System Admin',
    CUSTOMER_ADMIN: 'Customer Admin',
};

//User roles
const GLOBAL_ADMIN_ROLE = 'GLOBAL_ADMIN';
const CUSTOMER_ADMIN_ROLE = 'CUSTOMER_ADMIN';
const CUSTOMER_USER_ROLE = 'CUSTOMER_USER';
const CUSTOMER_OPERATOR_ROLE = 'CUSTOMER_OPERATOR';
const FULL_ESTATE = 'Full Estate';
@Component({
    selector: 'cbms-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
    public Optimum_IDP = 'OPTIMUM_CC_IDENTITY_PROVIDER';
    public userList: User[] = [];
    public emptyUser = {
        id: null,
        username: '',
        resourcePermissionList: [],
        customerList: [],
        role: '',
        expiryDate: '',
        firstName: '',
        lastName: '',
        status: 'ACTIVE',
        isSendEmailInvite: true,
        fullEstate: false,
        loginId: null,
        identityProvider: this.Optimum_IDP,
    };
    public totalRecords: number = null;
    public selectedUserList: User[] = [];
    public user: User;
    public userDialog: boolean = false;
    public submitted: boolean = false;
    public userRoles: Role[] = [
        { name: ROLES_MAPPER[CUSTOMER_USER_ROLE], code: CUSTOMER_USER_ROLE },
        {
            name: ROLES_MAPPER[CUSTOMER_OPERATOR_ROLE],
            code: CUSTOMER_OPERATOR_ROLE,
        },
        { name: ROLES_MAPPER[CUSTOMER_ADMIN_ROLE], code: CUSTOMER_ADMIN_ROLE },
    ];
    public userRoleList: Role[];
    public selectedUserRole: Role;

    public userRoleFilterList: Role[];
    public selectedUserRoleFilter: string;
    public timeframeFilter: Date[] = [];
    public pageSize = this.utilsService.getDataPointsPageSize();
    public pageNumber = 0;

    public selectedResources: RoleResource[] = [];
    public elementShouldDisplayFlagMap: {};
    public customerList: Customer[];
    public siteList: Site[];
    public selectedCustomer: Customer;
    public selectedSiteList: Site[] = [];
    public selectedSiteIdList: string[] = [];
    public isLoading: boolean = false;

    public identityProviderList = [];
    private customerId: string;
    private customerName: string;

    private userListSubscription: Subscription;
    private customersSubscription: Subscription;
    private sitesSubscription: Subscription;

    public cols: any[] = [
        { field: 'id', header: 'ID', position: 1, width: '150px' },
        {
            field: 'firstName',
            header: 'First Name',
            position: 2,
            width: '100px',
        },
        { field: 'lastName', header: 'Last Name', position: 3, width: '100px' },
        { field: 'username', header: 'Email', position: 4, width: '150px' },
        { field: 'roleName', header: 'User Role', position: 5, width: '100px' },
        {
            field: 'lastLoginTimestamp',
            header: 'Last Seen',
            position: 6,
            width: '100px',
        },
        { field: 'status', header: 'Status', position: 6, width: '100px' },
    ];

    public start: Date = moment.utc().subtract(7, 'days').toDate();
    public end: Date = moment.utc().toDate();
    public maxDateValue: Date = moment.utc().add(1, 'days').toDate();
    public minDateValue: Date = moment.utc().subtract(90, 'days').toDate();
    public rangeDates: Date[];
    public timeOptionList: SelectItem[] = [
        { label: 'Select time', value: '' },
        { label: 'Today', value: TimeFramesValues.TODAY },
        { label: 'Yesterday', value: TimeFramesValues.YESTERDAY },
        { label: 'Last 7 days', value: TimeFramesValues.LAST_7_DAYS },
        { label: 'Last 30 days', value: TimeFramesValues.LAST_30_DAYS },
        { label: 'Last 365 days', value: TimeFramesValues.LAST_365_DAYS },
    ];
    public selectedTime: SelectItem = { label: 'Select time', value: '' };
    public maxYearRangeLimit: number = new Date().getFullYear() + 2;
    sortOptions: SortOptions;
    exportUserDataSubscription: Subscription;

    constructor(
        private backendService: BackendService,
        private utilsService: UtilsService,
        private notificationService: NotificationService,
        private dataService: DataService,
        private confirmationService: ConfirmationService,
        private filterService: FilterService
    ) {}

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('userManagement');
        this.filterService.customerFilterChanges$.subscribe((data) => {
            this.customerId = data.selectedCustomer.id;
            this.customerName = data.selectedCustomer?.name;
            this.getUserList(this.pageNumber, this.pageSize);
            this.getCustomerList();
        });
        if (!this.customerId) {
            this.getCurrentCustomer();
        }
        this.getUserList(this.pageNumber, this.pageSize);
        this.getCustomerList();
        this.getIdentityProviderList();
    }

    /**
     * @description get current customer
     * @input none
     * @output void trigger customerFilterChanges$ variable
     */
    getCurrentCustomer() {
        this.backendService.getCustomers().subscribe((customerList) => {
            let selectedCustomer: { id; name };
            let customerArr = this.utilsService.orderListByFieldName(
                customerList.map((customer) => {
                    return { id: customer.id, name: customer.name };
                }),
                'name'
            );
            this.backendService.getLastCustomer().subscribe((response) => {
                if (_.isEmpty(response)) {
                    selectedCustomer = customerArr[0];
                } else {
                    selectedCustomer = response;
                }
                this.customerId = selectedCustomer.id;
                this.customerName = selectedCustomer?.name;
            });
        });
    }

    ngOnDestroy(): void {
        if (this.userListSubscription) {
            this.userListSubscription.unsubscribe();
        }
        if (this.exportUserDataSubscription) {
            this.exportUserDataSubscription.unsubscribe();
        }
    }

    /**
     * @description generate filtering parameters
     * @input none
     * @returns {lastLoginFrom, lastLoginTo, role}
     */
    generateFilteringParameters(): UserFiltering {
        const lastLoginFrom = this.rangeDates?.length
            ? this.rangeDates[0].toISOString()
            : '';
        const lastLoginTo = this.rangeDates?.length
            ? this.rangeDates[1].toISOString()
            : '';
        const role = this.selectedUserRoleFilter || '';
        return { lastLoginFrom, lastLoginTo, role };
    }

    getUserList(
        pageNumber: number,
        pageSize: number,
        sortOption?: SortOptions
    ) {
        this.isLoading = true;
        const filteringParameters = this.generateFilteringParameters();
        const userRole = sessionStorage.getItem('userRole');
        if (userRole === 'ROLE_' + GLOBAL_ADMIN_ROLE) {
            this.userListSubscription = this.backendService
                .getUsersBySystemAdmin(
                    pageNumber,
                    pageSize,
                    filteringParameters,
                    this.customerId,
                    sortOption
                )
                .subscribe({
                    next: (response) => {
                        this.userList = response.content;
                        this.totalRecords = response.totalElements;
                        this.isLoading = false;
                    },
                    error: (err) => console.log('err: ', err),
                });
        } else {
            this.userListSubscription = this.backendService
                .getUsersByCustomerAdmin(
                    this.pageNumber,
                    this.pageSize,
                    filteringParameters,
                    sortOption
                )
                .subscribe({
                    next: (response) => {
                        this.userList = response.content;
                        this.totalRecords = response.totalElements;
                        this.isLoading = false;
                    },
                    error: (err) => console.log('err: ', err),
                });
        }
    }

    getCustomerList() {
        if (this.customersSubscription) {
            this.customersSubscription.unsubscribe();
        }
        this.customersSubscription = this.backendService
            .getCustomers()
            .subscribe((customerList) => {
                this.customerList = customerList.map((customer) => {
                    return { id: customer.id, name: customer.name };
                });
            });
    }

    getIdentityProviderList() {
        this.backendService.getIdentityProviderList().subscribe((response) => {
            response.map((item) => {
                this.identityProviderList.push({
                    name: item.description,
                    code: item.code,
                });
            });
        });
    }

    fetchCustomerList($event) {
        if (this.customerList) {
            return;
        }
        this.getCustomerList();
    }

    getSiteList(customerId: string) {
        if (this.sitesSubscription) {
            this.sitesSubscription.unsubscribe();
        }
        this.sitesSubscription = this.backendService
            .getSitesByCustomer(customerId)
            .subscribe((response) => {
                this.siteList = this.utilsService.orderListByFieldName(
                    response.content,
                    'name'
                );
            });
    }

    updateUserRoleList() {
        this.userRoleList = [...this.userRoles];
        if (sessionStorage.getItem('userRole') === 'ROLE_ADMIN') {
            this.userRoleList.push({
                name: ROLES_MAPPER[GLOBAL_ADMIN_ROLE],
                code: GLOBAL_ADMIN_ROLE,
            });
        }
    }

    updateUserRoleFilterList() {
        this.userRoleFilterList = [...this.userRoles];
        if (sessionStorage.getItem('userRole') === 'ROLE_GLOBAL_ADMIN') {
            this.userRoleFilterList.push({
                name: ROLES_MAPPER[GLOBAL_ADMIN_ROLE],
                code: GLOBAL_ADMIN_ROLE,
            });
        }
    }

    getUserCustomerList() {
        let customerList = [];

        this.selectedResources.map((resource: any) => {
            let index = customerList.findIndex(
                (customer) => customer.id === resource.customer.id
            );
            if (index !== -1) {
                customerList[index].siteList = [
                    ...customerList[index].siteList,
                    resource.site,
                ];
            } else {
                customerList.push({
                    id: resource.customer.id,
                    name: resource.customer.name,
                    siteList: [resource.site],
                });
            }
        });

        return customerList;
    }

    openNew() {
        this.submitted = false;
        this.user = { ...this.emptyUser };
        this.selectedResources = [];
        this.selectedSiteIdList = [];
        this.selectedUserRole = null;
        this.userDialog = true;
        this.computeelementShouldDisplayFlagMap(this.user.role);
    }

    editUser(user: User) {
        this.submitted = false;
        this.user = { ...user };
        this.updateUserRoleList();
        this.populateResourceTableFromUserCustomerList(this.user);
        this.populateUserRoleDD(this.user.role);
        this.populateCustomerIfNeeded(this.user);
        this.computeelementShouldDisplayFlagMap(this.user.role);
        this.userDialog = true;
    }

    populateResourceTableFromUserCustomerList(user: User) {
        this.selectedResources = [];
        this.selectedSiteIdList = [];

        if (this.user.fullEstate) {
            this.selectedResources.push({
                customer: {
                    id: user.customerList[0].id,
                    name: user.customerList[0].name,
                },
                site: { name: FULL_ESTATE, id: 'full_estate' },
            });

            return;
        }

        user.customerList.forEach((customer) => {
            customer.siteList.forEach((site) => {
                this.selectedResources.push({
                    customer: { id: customer.id, name: customer.name },
                    site: { id: site.id, name: site.name },
                });
                this.selectedSiteIdList.push(site.id);
            });
        });
    }

    populateUserRoleDD(role: string) {
        this.selectedUserRole = {
            name: ROLES_MAPPER[role],
            code: role,
        };
    }

    private computeelementShouldDisplayFlagMap(role: string) {
        if (role === GLOBAL_ADMIN_ROLE) {
            this.elementShouldDisplayFlagMap = {};
        } else if (role === CUSTOMER_ADMIN_ROLE) {
            this.elementShouldDisplayFlagMap = {
                customerDropdown: true,
            };
        } else {
            this.elementShouldDisplayFlagMap = {
                customerDropdown: true,
                siteDropdown: true,
                applyButton: true,
                resourcesTable: true,
                fullEstateCheckbox: true,
            };
        }
    }

    deleteSelectedUsers() {
        this.backendService
            .deleteSelectedUsers(this.selectedUserList.map((user) => user.id))
            .subscribe(
                (response) => {
                    this.notificationService.addSuccessMessage(
                        'Success',
                        'Users successfully deleted.'
                    );
                    this.selectedUserList = [];
                    this.getUserList(this.pageNumber, this.pageSize);
                },
                (err) => {
                    this.notificationService.addErrorMessage(
                        'Error',
                        'Users could not be deleted. Please try again.'
                    );
                }
            );
    }

    deleteUser(user: User) {
        // todo: implemnet api call to delete user
        this.backendService.deleteUser(user.id).subscribe(
            (response) => {
                this.notificationService.addSuccessMessage(
                    'Success',
                    'User successfully deleted.'
                );
            },
            (err) => {
                this.notificationService.addErrorMessage(
                    'Error',
                    'User could not be deleted. Please try again.'
                );
            }
        );
    }

    hideDialog() {
        this.userDialog = false;
    }

    createUser() {
        const userModel = {
            id: null,
            username: this.user.username,
            role: this.selectedUserRole?.code,
            expiryDate: null,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            status: 'ACTIVE',
            customerList: this.getCustomerListByUserRole(),
            isSendEmailInvite:
                this.user.identityProvider === this.Optimum_IDP
                    ? this.user.isSendEmailInvite
                    : false,
            fullEstate: this.user.fullEstate,
            identityProvider: this.user.identityProvider,
            loginId:
                this.user.identityProvider === this.Optimum_IDP
                    ? null
                    : this.user.loginId,
        };

        this.backendService.createUserWithPermissions(userModel).subscribe(
            (response) => {
                this.notificationService.addSuccessMessage(
                    'Success',
                    'User successfully created.'
                );
                this.userDialog = false;
                this.getUserList(this.pageNumber, this.pageSize);
            },
            (err) => {
                this.notificationService.addErrorMessage(
                    'Error',
                    'User could not be created. Please try again.'
                );
            }
        );
    }

    updateUser() {
        const userModel = {
            id: this.user.id,
            username: this.user.username,
            role: this.selectedUserRole?.code,
            expiryDate: this.user.expiryDate,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            status: 'ACTIVE',
            customerList: this.getCustomerListByUserRole(),
            isSendEmailInvite:
                this.user.identityProvider === this.Optimum_IDP
                    ? this.user.isSendEmailInvite
                    : false,
            fullEstate: this.user.fullEstate,
            identityProvider: this.user.identityProvider,
            loginId:
                this.user.identityProvider === this.Optimum_IDP
                    ? null
                    : this.user.loginId,
        };

        this.backendService.updateUserWithPermissions(userModel).subscribe(
            (response) => {
                this.notificationService.addSuccessMessage(
                    'Success',
                    'User successfully updated.'
                );
                this.userDialog = false;
                this.getUserList(this.pageNumber, this.pageSize);
            },
            (err) => {
                this.notificationService.addErrorMessage(
                    'Error',
                    'User could not be updated. Please try again.'
                );
            }
        );
    }

    private getCustomerListByUserRole() {
        if (this.selectedUserRole?.code === GLOBAL_ADMIN_ROLE) {
            return [];
        } else if (
            this.selectedUserRole?.code === CUSTOMER_ADMIN_ROLE ||
            this.user.fullEstate
        ) {
            return [
                {
                    id: this.selectedCustomer.id,
                    name: this.selectedCustomer.name,
                    siteList: [],
                },
            ];
        } else {
            return this.getUserCustomerList();
        }
    }

    /**
     * @description export user data
     * @param pageNumber
     * @param pageSize
     * @output void
     */
    exportUserData() {
        this.isLoading = true;
        const filteringParameters = this.generateFilteringParameters();
        const userRole = sessionStorage.getItem('userRole');
        let emailParam: SortOptions;
        if (this.sortOptions?.email) {
            emailParam = { email: this.sortOptions.email };
        }
        if (userRole === 'ROLE_' + GLOBAL_ADMIN_ROLE) {
            this.exportUserDataSubscription = this.backendService
                .exportExportUserDataFileBySystemAdmin(
                    this.pageNumber,
                    this.pageSize,
                    filteringParameters,
                    this.customerId,
                    emailParam
                )
                .subscribe(
                    (response: HttpResponse<Blob>) => {
                        this.handleDataAfterExportSucessfully(response);
                    },
                    (err) => {
                        this.callbackFunctionToHandleError(err);
                    }
                );
        } else {
            this.exportUserDataSubscription = this.backendService
                .exportUserDataFileByCustomerAdmin(
                    this.pageNumber,
                    this.pageSize,
                    filteringParameters,
                    emailParam
                )
                .subscribe(
                    (response: HttpResponse<Blob>) => {
                        this.handleDataAfterExportSucessfully(response);
                    },
                    (err) => {
                        this.callbackFunctionToHandleError(err);
                    }
                );
        }
    }

    callbackFunctionToHandleError(err) {
        console.log(err);
        this.isLoading = false;
    }

    handleDataAfterExportSucessfully(response: HttpResponse<Blob>) {
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = `${this.customerName} Users Data ${this.getDateNowAsText()}.xlsx`;
        if (contentDisposition) {
            const matches = contentDisposition.match(/filename="(.+?)"/);
            if (matches && matches[1]) {
                filename = matches[1];
            }
        }
        // Save the file
        const blob = response.body as Blob;
        saveAs(blob, filename);
        this.isLoading = false;
    }

    saveChanges() {
        this.submitted = true;

        if (
            !this.user.firstName ||
            !this.user.lastName ||
            !this.user.username ||
            !this.selectedUserRole ||
            (this.user.identityProvider !== this.Optimum_IDP &&
                !this.user.loginId)
        ) {
            return;
        }

        if (
            this.selectedResources.length == 0 &&
            [CUSTOMER_OPERATOR_ROLE, CUSTOMER_USER_ROLE].includes(
                this.selectedUserRole.code
            )
        ) {
            return;
        }

        if (this.selectedUserRole?.code === GLOBAL_ADMIN_ROLE) {
            this.confirmationService.confirm({
                message: `You are assigning the role of system admin, this role is not intended for customers.<br /> <br />
						  Assigning this role to a customer is a violation of GDPR.<br />  <br />
			 	 		  Are you sure you want to proceed?`,
                accept: () => {
                    //Actual logic to perform a confirmation
                    this.sendChanges();
                },
            });
            return;
        }

        this.sendChanges();
    }

    sendChanges() {
        if (this.user.id) {
            this.updateUser();
            return;
        }
        this.createUser();
    }

    onRoleChosen() {
        this.selectedCustomer = null;
        this.selectedSiteList = [];
        this.selectedSiteIdList = [];
        this.selectedResources = [];
        this.submitted = false;
        this.computeelementShouldDisplayFlagMap(this.selectedUserRole.code);
    }

    onCustomerChosen() {
        this.getSiteList(this.selectedCustomer.id);
    }

    applyRole() {
        if (!this.user.fullEstate) {
            this.selectedSiteList.map((selectedSite) => {
                if (this.selectedSiteIdList.includes(selectedSite.id)) {
                    return;
                }
                this.selectedSiteIdList.push(selectedSite.id);
                this.selectedResources.push({
                    customer: {
                        name: this.selectedCustomer.name,
                        id: this.selectedCustomer.id,
                    },
                    site: { name: selectedSite.name, id: selectedSite.id },
                });
            });

            this.selectedSiteList = [];
        } else {
            this.selectedResources = [];
            this.selectedResources.push({
                customer: {
                    name: this.selectedCustomer.name,
                    id: this.selectedCustomer.id,
                },
                site: { name: FULL_ESTATE, id: 'full_estate' },
            });
        }
    }

    removeResource(resource: RoleResource) {
        this.selectedSiteIdList = this.selectedSiteIdList.filter(
            (siteId) => siteId !== resource.site.id
        );
        this.selectedResources = this.selectedResources.filter(
            (selectedResource) => selectedResource.site.id !== resource.site.id
        );
    }

    loadDPLazy(event: LazyLoadEvent) {
        const pageNumber =
            event.first !== 0 ? event.first / this.pageSize : this.pageNumber;
        this.sortOptions = null;

        if (event.sortField) {
            const fieldCode =
                event.sortField === 'roleName' ? 'role' : event.sortField; // roleName is used for displaying in table, role is used for sorting data
            this.sortOptions = {
                sortField: fieldCode,
                sortOrder: event.sortOrder === 1 ? 'asc' : 'desc',
            };
        }
        if (event.globalFilter) {
            this.sortOptions = Object.assign(
                {},
                {
                    ...this.sortOptions,
                    email: encodeURIComponent(event.globalFilter),
                }
            );
        }

        this.getUserList(pageNumber, this.pageSize, this.sortOptions);
    }

    applyFilter() {
        this.sortOptions = null; // Clear sort option
        this.getUserList(this.pageNumber, this.pageSize);
    }

    clearFilter() {
        this.sortOptions = null;
        this.selectedUserRoleFilter = '';
        this.rangeDates = [];
        this.resetSelectedTime();
        this.getUserList(this.pageNumber, this.pageSize);
    }

    onFilter() {
        this.setCalendarInterval();
    }

    onIntervalOptionSelected() {
        this.setCalendarInterval();
    }

    public onIntervalSelected(event: any) {
        this.resetSelectedTime();
    }

    private resetSelectedTime() {
        this.selectedTime = { label: 'Select time', value: '' };
    }

    private setCalendarInterval() {
        if (this.selectedTime.value === '') {
            return;
        }

        let start = moment('00:00:00', 'HH:mm:ss')
            .utc()
            .subtract(this.selectedTime.value, 'days')
            .toDate();
        let end = moment('23:59:00', 'HH:mm:ss').utc().toDate();

        if (this.selectedTime.value === TimeFramesValues.YESTERDAY) {
            end = moment(start)
                .endOf('day')
                .seconds(0)
                .milliseconds(0)
                .toDate();
        }

        this.rangeDates = [start, end];
    }

    private setDefaultTimeframe() {
        this.selectedTime = {
            label: 'Last 7 days',
            value: TimeFramesValues.LAST_7_DAYS,
        };
        this.setCalendarInterval();
    }

    private populateCustomerIfNeeded(user: User) {
        if (user.role == CUSTOMER_ADMIN_ROLE || user.fullEstate) {
            let customer = user.customerList[0];
            this.selectedCustomer = {
                id: customer.id,
                name: customer.name,
            };
        }
    }

    private getDateNowAsText() {
        let date = new Date();
        let time = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()} ${date.getHours()}${date.getMinutes()}`;
        return time;
    }

    protected readonly GLOBAL_ADMIN_ROLE = GLOBAL_ADMIN_ROLE;
    protected readonly CUSTOMER_ADMIN_ROLE = CUSTOMER_ADMIN_ROLE;
}
