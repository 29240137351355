import { BrowserModule } from '@angular/platform-browser';
import {
    APP_INITIALIZER,
    ErrorHandler,
    NgModule,
    CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { Router } from '@angular/router';

import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BackendService } from './services/backend/backend.service';
import { DataPointsComponent } from './views/data-points/data-points.component';
import { FormsModule } from '@angular/forms';
import { GatewaysComponent } from './views/gateways/gateways.component';
import { SvgService } from './services/svg/svg.service';
import { HttpBaseService } from './services/http/http-base.service';
import { DeviceCardComponent } from './views/gateways/device-card/device-card.component';
import { TelemetryDialogService } from './services/dialog-config/telemetry-dialog.service';
import { DevicesComponent } from './views/devices/devices.component';
import { DeviceDataPointsTableComponent } from './views/devices/device-data-points-table/device-data-points-table.component';
import { NavigationService } from './services/navigation/navigation.service';
import { NotificationService } from './services/notification/notification.service';
import { UtilsService } from './services/utils/util.service';
import { PrimeNGCorrectionService } from './services/utils/primeNgCorrection.service';
import { TimeseriesChartComponent } from './components/highcharts/timeseries-chart/timeseries-chart.component';
import { DialogConfigService } from './services/dialog-config/dialog-config.service';
import { SetpointDialogComponent } from './dialogs/setpoint/setpoint.dialog';
import moment from 'moment-timezone';

import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { OptionCardComponent } from './views/gateways/option-card/option-card.component';
import { DataPointsTableComponent } from './components/data-points-table/data-points-table.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule, DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FilterComponent } from './components/filter/filter.component';
import { FieldFilterComponent } from './components/filter/field-filter/field-filter.component';
import { SiteFilterComponent } from './components/filter/site-filter/site-filter.component';
import { DeviceFilterComponent } from './components/filter/device-filter/device-filter.component';
import { TagFilterComponent } from './components/filter/tag-filter/tag-filter.component';
import { QuickFilterComponent } from './components/filter/quick-filter/quick-filter.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FileUploadModule } from 'primeng/fileupload';
import { PaginatorModule } from 'primeng/paginator';
import { TelemetryDialogComponent } from './dialogs/telemetry/telemetry.dialog';
import { TagManagementComponent } from './dialogs/tag-management/tag-management.component';
import { DataPointPropertiesComponent } from './dialogs/data-point-properties/data-point-properties.component';
import { DataService } from './services/data/data.service';
import { TrimTextPipe } from './pipes/trim-text.pipe';
import { WatchDataPointsComponent } from './dialogs/watch-data-points/watch-data-points.component';
import { GatewayMaintenanceComponent } from './dialogs/gateway-maintenance/gateway-maintenance.component';
import { WatchActionComponent } from './components/actions/watch-action/watch-action.component';
import { EditTagsActionComponent } from './components/actions/edit-tags-action/edit-tags-action.component';
import { SetpointChangeActionComponent } from './components/actions/setpoint-change-action/setpoint-change-action.component';
import { AnalysisActionComponent } from './components/actions/analysis-action/analysis-action.component';
import { ViewDataPointPropsActionComponent } from './components/actions/view-data-point-props-action/view-data-point-props-action.component';
import { SchedulesComponent } from './views/schedules/schedules.component';
import { CopyActionComponent } from './components/actions/copy-action/copy-action.component';
import { CopyScheduleComponent } from './dialogs/copy-schedule/copy-schedule.component';
import { SelectRowComponent } from './components/actions/select-row/select-row.component';
import { ScheduleCalendarComponent } from './components/schedule-calendar/schedule-calendar.component';
import { ShowScheduleActionComponent } from './components/actions/show-schedule-action/show-schedule-action.component';
import { ScheduleCalendarDialogComponent } from './dialogs/schedule-calendar/schedule-calendar.component';
import { ScheduleTemplatesComponent } from './components/schedule-templates/schedule-templates.component';
import { ScheduleTemplateDeleteActionComponent } from './components/actions/schedule-template-delete-action/schedule-template-delete-action.component';
import { ShowTemplateActionComponent } from './components/actions/show-template-action/show-template-action.component';
import { TemplateCalendarDialogComponent } from './dialogs/template-calendar/template-calendar.component';
import { ScheduleTemplateSelectActionComponent } from './components/actions/schedule-template-select-action/schedule-template-select-action.component';
import { ShowExceptionScheduleActionComponent } from './components/actions/show-exception-schedule-action/show-exception-schedule-action.component';
import { ExceptionScheduleDialogComponent } from './dialogs/exception-schedule/exception-schedule-dialog.component';
import { ExceptionScheduleTemplatesComponent } from './components/exception-schedule-templates/exception-schedule-templates.component';
import { ShowExceptionScheduleTemplateActionComponent } from './components/actions/show-exception-schedule-template-action/show-exception-schedule-template-action.component';
import { ExceptionScheduleTemplateComponent } from './dialogs/exception-schedule-template/exception-schedule-template.component';
import { SitesComponent } from './views/sites/sites.component';
import { SitesTableComponent } from './components/tables/sites-table/sites-table.component';
import { CreateTwinFromScheduleActionComponent } from './components/actions/create-twin-from-schedule-action/create-twin-from-schedule-action.component';
import { CreateTwinFromScheduleDialogComponent } from './dialogs/create-twin-from-schedule-dialog/create-twin-from-schedule-dialog.component';
import { ExceptionScheduleTableComponent } from './components/tables/exception-schedule-table/exception-schedule-table.component';
import { CompareScheduleToTwinActionComponent } from './components/actions/compare-schedule-to-twin-action/compare-schedule-to-twin-action.component';
import { CompareScheduleToTwinDialogComponent } from './dialogs/compare-schedule-to-twin-dialog/compare-schedule-to-twin-dialog.component';
import { WriteTwinToScheduleActionComponent } from './components/actions/write-twin-to-schedule-action/write-twin-to-schedule-action.component';
import { WriteTwinToScheduleDialogComponent } from './dialogs/write-twin-to-schedule-dialog/write-twin-to-schedule-dialog.component';
import { AuditComponent } from './views/audit/audit.component';
import { AuditTrailsTableComponent } from './components/audit-trails-table/audit-trails-table.component';
import { CustomerFilterComponent } from './components/filter/customer-filter/customer-filter.component';
import { ColumnFilterComponent } from './components/column-filter/column-filter.component';
import { TimeframeFilterComponent } from './components/filter/timeframe-filter/timeframe-filter.component';

import {
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';

import { ErrorComponent } from './views/error/error.component';
import { CountDownComponent } from './components/count-down/count-down.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DashboardComponent as DashboardSettingsComponent } from './views/settings/dashboard/dashboard.component';
import { DeleteDatapointsActionComponent } from './components/actions/delete-datapoints-action/delete-datapoints-action.component';
import { SitesTagManagementComponent } from './dialogs/sites-tag-management/sites-tag-management.component';
import { DashboardPocComponent } from './views/dashboard-poc/dashboard-poc.component';
import { CreateTwinFromDatapointActionComponent } from './components/actions/create-twin-from-datapoint-action/create-twin-from-datapoint-action.component';
import { CreateTwinFromDatapointDialogComponent } from './dialogs/create-twin-from-datapoint-dialog/create-twin-from-datapoint-dialog.component';
import { EquipmentWidgetComponent } from './views/dashboard-poc/equipment-widget/equipment-widget.component';
import { EquipmentAccordionComponent } from './views/dashboard-poc/equipment-accordion/equipment-accordion.component';
import { SettingsComponent } from './views/settings/settings.component';
import { PolicyMakerComponent } from './views/settings/policy-maker/policy-maker.component';
import { WriteTwinToDatapointComponent } from './components/actions/write-twin-to-datapoint/write-twin-to-datapoint.component';
import { AssignOcTenantActionComponent } from './components/actions/assign-oc-tenant-action/assign-oc-tenant-action.component';
import { AddOcTenantDialogComponent } from './dialogs/add-oc-tenant-dialog/add-oc-tenant-dialog.component';
import { SyncWithOcActionComponent } from './components/actions/sync-with-oc-action/sync-with-oc-action.component';
import { UserManagementComponent } from './views/settings/user-management/user-management.component';
import { GatewayFilterComponent } from './components/filter/gateway-filter/gateway-filter.component';
import { AssignSiteComponent } from './dialogs/assign-site/assign-site.component';
import { DashboardsManagementComponent } from './dialogs/dashboards-management/dashboards-management.component';
import { ReportsComponent } from './views/settings/reports/reports.component';
import { SmartAlertComponent } from './views/settings/smart-alert/smart-alert.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { SmartAlertConsoleComponent } from './views/settings/smart-alert-console/smart-alert-console.component';
import { FilterService } from 'primeng/api';
import { AddSmartAlertRuleComponent } from './views/settings/smart-alert/add-smart-alert-rule/add-smart-alert-rule.component';
import { QueryBuilderComponent } from './views/settings/smart-alert/add-smart-alert-rule/query-builder/query-builder.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SmartAlertDetailsComponent } from './dialogs/smart-alert-details/smart-alert-details.component';
import * as Sentry from '@sentry/angular';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
    AutoOptimisationComponent
} from './views/settings/auto-optimisation/auto-optimisation/auto-optimisation.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CompareDatapointToTwinDialogComponent } from './dialogs/compare-datapoint-to-twin-dialog/compare-datapoint-to-twin-dialog.component';
import { CompareDatapointToTwinActionComponent } from './components/actions/compare-datapoint-to-twin-action/compare-datapoint-to-twin-action.component';
import { DetailActionLogComponent } from './dialogs/detail-action-log/detail-action-log.component';
import { AddSmartAlertTemplateComponent } from './views/settings/smart-alert-templates/add-smart-alert-template/add-smart-alert-template.component';
import { SitesSelectionComponent } from './dialogs/sites-selection/sites-selection.component';
import { CorporateSsoComponent } from './views/corporate-sso/corporate-sso.component';
import { CookieService } from 'ngx-cookie-service';
import { DeleteTwinFromDataPointComponent } from './dialogs/delete-twin-from-data-point/delete-twin-from-data-point.component';
import { DeleteTwinFromDataPointDialogComponent } from './dialogs/delete-twin-from-data-point-dialog/delete-twin-from-data-point-dialog.component';
import { BuildingOpeningTimeDialogComponent } from './dialogs/building-opening-time-dialog/building-opening-time-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        DataPointsComponent,
        GatewaysComponent,
        DevicesComponent,
        DeviceCardComponent,
        DeviceDataPointsTableComponent,
        DataPointsTableComponent,
        OptionCardComponent,
        TimeseriesChartComponent,
        FilterComponent,
        FieldFilterComponent,
        SiteFilterComponent,
        DeviceFilterComponent,
        TagFilterComponent,
        QuickFilterComponent,
        SetpointDialogComponent,
        TelemetryDialogComponent,
        TagManagementComponent,
        DataPointPropertiesComponent,
        TrimTextPipe,
        WatchDataPointsComponent,
        GatewayMaintenanceComponent,
        WatchActionComponent,
        EditTagsActionComponent,
        SetpointChangeActionComponent,
        AnalysisActionComponent,
        ViewDataPointPropsActionComponent,
        SchedulesComponent,
        CopyActionComponent,
        CopyScheduleComponent,
        SelectRowComponent,
        ScheduleCalendarComponent,
        ShowScheduleActionComponent,
        ScheduleCalendarDialogComponent,
        ScheduleTemplatesComponent,
        ScheduleTemplateDeleteActionComponent,
        ShowTemplateActionComponent,
        TemplateCalendarDialogComponent,
        ScheduleTemplateSelectActionComponent,
        ShowExceptionScheduleActionComponent,
        ExceptionScheduleDialogComponent,
        ExceptionScheduleTemplatesComponent,
        ShowExceptionScheduleTemplateActionComponent,
        ExceptionScheduleTemplateComponent,
        SitesComponent,
        SitesTableComponent,
        CreateTwinFromScheduleActionComponent,
        CreateTwinFromScheduleDialogComponent,
        ExceptionScheduleTableComponent,
        CompareScheduleToTwinActionComponent,
        CompareScheduleToTwinDialogComponent,
        CompareDatapointToTwinDialogComponent,
        CompareDatapointToTwinActionComponent,
        WriteTwinToScheduleActionComponent,
        WriteTwinToScheduleDialogComponent,
        AuditComponent,
        AuditTrailsTableComponent,
        CustomerFilterComponent,
        ColumnFilterComponent,
        TimeframeFilterComponent,
        ErrorComponent,
        CountDownComponent,
        DashboardComponent,
        DashboardSettingsComponent,
        DeleteDatapointsActionComponent,
        SitesTagManagementComponent,
        DashboardPocComponent,
        CreateTwinFromDatapointActionComponent,
        CreateTwinFromDatapointDialogComponent,
        EquipmentWidgetComponent,
        EquipmentAccordionComponent,
        SettingsComponent,
        PolicyMakerComponent,
        PolicyMakerComponent,
        WriteTwinToDatapointComponent,
        AssignOcTenantActionComponent,
        AssignOcTenantActionComponent,
        AddOcTenantDialogComponent,
        SyncWithOcActionComponent,
        UserManagementComponent,
        GatewayFilterComponent,
        AssignSiteComponent,
        DashboardsManagementComponent,
        ReportsComponent,
        SmartAlertComponent,
        SmartAlertConsoleComponent,
        AddSmartAlertRuleComponent,
        AddSmartAlertTemplateComponent,
        SitesSelectionComponent,
        QueryBuilderComponent,
        SmartAlertDetailsComponent,
        AutoOptimisationComponent,
        DetailActionLogComponent,
        CorporateSsoComponent,
        DeleteTwinFromDataPointComponent,
        DeleteTwinFromDataPointDialogComponent,
        BuildingOpeningTimeDialogComponent
    ],
    imports: [
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        MatTreeModule,
        MatCardModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTabsModule,
        MatExpansionModule,
        MatDialogModule,
        MatMenuModule,
        AppRoutingModule,
        InputSwitchModule,
        OverlayPanelModule,
        MultiSelectModule,
        CheckboxModule,
        TableModule,
        MessageModule,
        TooltipModule,
        ToastModule,
        DialogModule,
        ConfirmDialogModule,
        InputNumberModule,
        InputTextareaModule,
        KeyFilterModule,
        InputTextModule,
        InputMaskModule,
        DynamicDialogModule,
        DropdownModule,
        CalendarModule,
        AutoCompleteModule,
        SidebarModule,
        TabViewModule,
        RadioButtonModule,
        TreeTableModule,
        TreeModule,
        FileUploadModule,
        PaginatorModule,
        AccordionModule,
        ListboxModule,
        ConfirmPopupModule,
        FullCalendarModule,
        TabMenuModule,
        ToggleButtonModule,
        OAuthModule.forRoot(
            // {
            //     resourceServer: {
            //         allowedUrls: ['https://api.dev.cbms.eon-optimum.com'],
            //         sendAccessToken: true,
            //     },
            // }
            ),
        SplitButtonModule,
    ],
    providers: [
        BackendService,
        SvgService,
        HttpBaseService,
        NavigationService,
        MessageService,
        NotificationService,
        UtilsService,
        PrimeNGCorrectionService,
        DialogConfigService,
        DialogService,
        ConfirmationService,
        TelemetryDialogService,
        DataService,
        FilterService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
        CookieService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor() {
        // little hack to make moment accessable to highcharts
        window['moment'] = moment;
    }
}
