import { Component, OnInit, OnDestroy } from '@angular/core';
import { SvgService } from './services/svg/svg.service';
import { Subject } from 'rxjs';
import { BackendService } from './services/backend/backend.service';
import { DataService } from './services/data/data.service';
import { MenuItem } from 'primeng/api';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import {
    ENV_NAME,
    AUTH0_CONFIG,
    LOGIN_IDP,
    IDP_PROVIDER,
    TESCO_CONFIG,
} from './config/constants';
import { ROLE_PERMISSIONS } from './models/user';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from './services/utils/authorization.service';
import { environment } from '../environments/environment';

export function getEnvironmentStageFromUrl(): ENV_NAME {
    switch (window.location.hostname) {
        case 'localhost':
        case 'dev.cbms.eon-optimum.com':
            return ENV_NAME.DEV;
        default:
            return ENV_NAME.PROD;
    }
}

const ENV = getEnvironmentStageFromUrl();

@Component({
    selector: 'cbms-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    adminMenuItems: MenuItem[] = [
        { label: 'Sites', routerLink: 'sites', icon: 'sites', visible: true },
        {
            label: 'Gateways',
            routerLink: 'gateways',
            icon: 'gateways',
            visible: true,
        },
        {
            label: 'Devices',
            routerLink: 'devices',
            icon: 'devices',
            visible: true,
        },
        {
            label: 'Data Points',
            routerLink: 'datapoints',
            icon: 'datapoints',
            visible: true,
        },
        {
            label: 'Schedules',
            routerLink: 'schedules',
            icon: 'schedule',
            visible: true,
        },
        {
            label: 'Action Log',
            routerLink: 'audit',
            icon: 'menu_book',
            visible: true,
        },
        {
            label: 'Settings',
            routerLink: 'settings/dashboard',
            icon: 'settings',
            visible: true,
        },
    ];

    customerRoleMenuItems = [
        {
            label: 'Data Points',
            routerLink: 'datapoints',
            icon: 'datapoints',
            visible: true,
        },
        {
            label: 'Schedules',
            routerLink: 'schedules',
            icon: 'schedule',
            visible: true,
        },
        {
            label: 'Settings',
            routerLink: 'settings/dashboard',
            icon: 'settings',
            visible: true,
        },
    ];

    menuItems: MenuItem[];

    public user: string;
    public loading = false;
    public shouldShowSiteFilter = true;
    public shouldShowCustomerFilter = true;
    public shouldHideHeader = false;
    public userIsPresent = false;

    public isIframe = false;
    public isSysAdmin: boolean = false;

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private svgService: SvgService,
        private backendService: BackendService,
        private dataService: DataService,
        private authService: OAuthService,
        private router: Router,
        private cookieService: CookieService,
        private authorizationService: AuthorizationService
    ) {
        if (window.location.pathname !== '/corporate-sso') {
            this.initAuthentication();
        }
    }

    ngOnInit(): void {
        this.menuItems = [...this.customerRoleMenuItems];
        this.isIframe = window !== window.parent && !window.opener;

        this.dataService.currentTabChanges$.subscribe((tabName) => {
            if (!tabName) {
                return;
            }
            this.shouldHideHeader = ['error', 'corporate-sso'].includes(
                tabName
            );
            if (tabName === 'dataPoints' && this.authService.clientId === '') {
                //init auth if not already done - coming here after app init (from corporate sso page for example)
                this.initAuthentication();
            }
        });
    }

    private computeRoleAndPermissions() {
        //todo set localStorage.setItem('role', response.role);

        this.backendService
            .getUserRolePermissionList()
            .subscribe((response: any) => {
                let rolePermissionList = response.userRolePermissionList;
                sessionStorage.setItem(
                    'userRolePermissionList',
                    rolePermissionList
                );
                sessionStorage.setItem('userRole', response.userRole);

                this.menuItems = [
                    {
                        label: 'Sites',
                        routerLink: 'sites',
                        icon: 'sites',
                        visible: rolePermissionList.includes(
                            ROLE_PERMISSIONS.VIEW_SITES_TAB
                        ),
                    },
                    {
                        label: 'Gateways',
                        routerLink: 'gateways',
                        icon: 'gateways',
                        visible: rolePermissionList.includes(
                            ROLE_PERMISSIONS.VIEW_GATEWAYS_TAB
                        ),
                    },
                    {
                        label: 'Devices',
                        routerLink: 'devices',
                        icon: 'devices',
                        visible: rolePermissionList.includes(
                            ROLE_PERMISSIONS.VIEW_DEVICES_TAB
                        ),
                    },
                    {
                        label: 'Data Points',
                        routerLink: 'datapoints',
                        icon: 'datapoints',
                        visible: rolePermissionList.includes(
                            ROLE_PERMISSIONS.VIEW_DATAPOINTS_TAB
                        ),
                    },
                    {
                        label: 'Schedules',
                        routerLink: 'schedules',
                        icon: 'schedule',
                        visible: rolePermissionList.includes(
                            ROLE_PERMISSIONS.VIEW_SCHEDULES_TAB
                        ),
                    },
                    {
                        label: 'Action Log',
                        routerLink: 'audit',
                        icon: 'menu_book',
                        visible: rolePermissionList.includes(
                            ROLE_PERMISSIONS.VIEW_AUDIT_TAB
                        ),
                    },
                    {
                        label: 'Settings',
                        routerLink: 'settings/dashboard',
                        icon: 'settings',
                        visible: rolePermissionList.includes(
                            ROLE_PERMISSIONS.VIEW_SETTINGS_TAB
                        ),
                    },
                ];

                if (
                    this.authService.hasValidIdToken() &&
                    !rolePermissionList.includes(
                        ROLE_PERMISSIONS.VIEW_DATAPOINTS_TAB
                    )
                ) {
                    this.router.navigateByUrl('/no-access');
                    return false;
                }
            });
    }

    logout() {
        this.authorizationService.logout();
    }

    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
    }

    private initAuthentication(): void {
        let authConfig: AuthConfig;
        const loginIdp =
            this.cookieService.get(LOGIN_IDP) || IDP_PROVIDER.AUTH0;
        if (loginIdp === IDP_PROVIDER.AUTH0) {
            authConfig = AUTH0_CONFIG[ENV];

            const params = new Proxy(
                new URLSearchParams(window.location.search),
                {
                    get: (searchParams, prop) =>
                        searchParams.get(prop as string),
                }
            );

            if (
                params &&
                params['invitation'] &&
                params['organization'] &&
                params['organization_name']
            ) {
                authConfig.customQueryParams = {
                    invitation: params['invitation'],
                    organization: params['organization'],
                    organization_name: params['organization_name'],
                };
            }
        }

        if (loginIdp.startsWith('tesco-')) {
            authConfig = TESCO_CONFIG[ENV];
            if (loginIdp === IDP_PROVIDER.TESCO_DASHBOARDS) {
                authConfig.redirectUri = environment.dashboardsAppUrl;
            }
        }
        this.authService.configure(authConfig);

        if (this.authService.hasValidAccessToken()) {
            this.authService.setupAutomaticSilentRefresh(undefined, 'id_token');
        }

        this.authService.loadDiscoveryDocumentAndLogin().then((value) => {
            if (value) {
                const claims = this.authService.getIdentityClaims();
                this.user = claims.email || claims.preferred_username;
                this.computeRoleAndPermissions();
                this.backendService.updateLastLogin(this.user).subscribe();
                this.userIsPresent = true;
            }
        });
    }
}
