export interface User {
    id: string,
    expiryDate?: string,
    globalAdmin?: boolean,
    firstName: string,
    lastName: string,
    status: string,
    username: string
    resourcePermissionList?: resourcePermission[],
    role: string,
    customerList: customerList[],
    isSendEmailInvite: boolean,
    lastEmailInvitationTimestamp?: string,
    lastLoginTimestamp?: string,
    fullEstate: boolean,
    loginId?: string,
    identityProvider: string,
}

export interface resourcePermission {
    id: string,
    value: string
    name?: string,
    type?: string,

}

export interface customerList {
    id: string,
    name: string,
    siteList: siteList[]
}

export interface siteList {
    id: string,
    name: string,
}

export interface Role {
	name: string,
	code: string
}

export interface RoleResource {
	customer: {
		name: string,
		id: string
	},
	site: {
		name: string,
		id: string
	}
}

export interface UserFiltering {
    lastLoginFrom: string;
    lastLoginTo: string;
    role: string;
}

export const ROLE_PERMISSIONS ={
    VIEW_GATEWAYS_TAB:'VIEW_GATEWAYS_CORE_APP',
    VIEW_DEVICES_TAB:'VIEW_DEVICES_CORE_APP',
    VIEW_SITES_TAB:'VIEW_SITES_CORE_APP',
    VIEW_DATAPOINTS_TAB:'VIEW_DATAPOINTS_CORE_APP',
    VIEW_SCHEDULES_TAB:'VIEW_SCHEDULES_CORE_APP',
    VIEW_SETTINGS_DASHBOARD_TAB:'VIEW_DASHBOARD_KPI_SETTIONS_CORE_APP',
    VIEW_SETTINGS_USER_MANAGEMENT_TAB:'VIEW_USER_MANAGEMENT_CORE_APP',
    VIEW_REPORTS_TAB:'VIEW_REPORTS_CORE_APP',
    VIEW_ALERTS_RULES_TAB:'VIEW_ALERTS_RULES_CORE_APP',
    VIEW_ALERT_CONSOLE_TAB:'VIEW_ALERT_CONSOLE_CORE_APP',
    VIEW_AUDIT_TAB:'VIEW_AUDIT_CORE_APP',
    VIEW_SETTINGS_TAB:'VIEW_SETTINGS_CORE_APP',

    DELETE_SITE_BUTTON_SITE_TAB_CORE_APP:'DELETE_SITE_BUTTON.SITE_TAB.CORE_APP',
    EDIT_SITE_BUTTON_SITE_TAB_CORE_APP:'EDIT_SITE_BUTTON.SITE_TAB.CORE_APP',
    EXPORT_SITE_DATA_MODEL_BUTTON_SITE_TAB_CORE_APP:'EXPORT_SITE_DATA_MODEL_BUTTON.SITE_TAB.CORE_APP',
    IMPORT_SITE_DATA_MODEL_BUTTON_SITE_TAB_CORE_APP:'IMPORT_SITE_DATA_MODEL_BUTTON.SITE_TAB.CORE_APP',
    EDIT_SITE_DASHBOARD_FLAG_BUTTON_SITE_TAB_CORE_APP:'EDIT_SITE_DASHBOARD_FLAG_BUTTON.SITE_TAB.CORE_APP',
    EDIT_SITE_TAGS_FLAG_BUTTON_SITE_TAB_CORE_APP:'EDIT_SITE_TAGS_FLAG_BUTTON.SITE_TAB.CORE_APP',
    DELETE_ALL_SITES_BUTTON_SITE_TAB_CORE_APP:'DELETE_ALL_SITES_BUTTON.SITE_TAB.CORE_APP',
    EXPORT_SITE_EXCEL_BUTTON_SITE_TAB_CORE_APP:'EXPORT_SITE_EXCEL_BUTTON.SITE_TAB.CORE_APP',
    IMPORT_SITE_EXCEL_BUTTON_SITE_TAB_CORE_APP:'IMPORT_SITE_EXCEL_BUTTON.SITE_TAB.CORE_APP',
    CREATE_SITE_BUTTON_SITE_TAB_CORE_APP:'CREATE_SITE_BUTTON.SITE_TAB.CORE_APP',
}
