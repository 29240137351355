<div class="content">
    <div class="list-wrapper">
        <p-listbox
            #sitesListbox
            [options]="sites"
            styleClass="cbms-checkbox-list"
            [(ngModel)]="selectedSites"
            (onChange)="handleValueChange()"
            [multiple]="true"
            optionLabel="name"
            filterPlaceHolder="Sites"
            checkbox="true"
            filter="true"
        >
        </p-listbox>
    </div>
</div>

<div class="footer">
    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
        (click)="saveSelectedSites()"
    >
        Save
    </button>
    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-small-margin-left"
        (click)="close()"
    >
        Cancel
    </button>
</div>
