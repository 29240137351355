import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AsyncPipe,
    NgForOf,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
} from '@angular/common';
import { ButtonDirective } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, of, Subscription } from 'rxjs';
import { DataService } from '../../../services/data/data.service';
import { FilterService } from '../../../components/filter/filter.service';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend/backend.service';
import { AlertTemplate, TemplateOverviewReq } from 'src/app/models/alert-rule.model';
import { UtilsService } from 'src/app/services/utils/util.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import _ from 'lodash';

@Component({
    selector: 'cbms-smart-alert-templates',
    standalone: true,
    imports: [
        AsyncPipe,
        ButtonDirective,
        DropdownModule,
        InputSwitchModule,
        MatIcon,
        MatProgressSpinner,
        NgForOf,
        NgIf,
        NgSwitchCase,
        OverlayPanelModule,
        PrimeTemplate,
        TableModule,
        TooltipModule,
        FormsModule,
        NgSwitch,
        NgSwitchDefault,
    ],
    templateUrl: './smart-alert-templates.component.html',
    styleUrl: './smart-alert-templates.component.scss',
})
export class SmartAlertTemplatesComponent implements OnInit, OnDestroy {
    public cols: any[] = [
        { field: 'name', header: 'Template Name', position: 2, width: '100px' },
        { field: 'templateType', header: 'Template Type', position: 3, width: '100px' },
        {
            field: 'isEnabled',
            header: 'Activation Status',
            position: 4,
            width: '150px',
        },
    ];
    alertTemplateList: Observable<AlertTemplate[]>;

    private customerId: string;
    customerSubscription = new Subscription();

    constructor(
        private dataService: DataService,
        private filterService: FilterService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private backendService: BackendService,
        private notificationService: NotificationService,
        private utilsService: UtilsService
    ) {}

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('smartAlertTemplates');
        this.customerSubscription = this.filterService.customerFilterChanges$.subscribe((data) => {
                this.customerId = data.selectedCustomer?.id;
                this.getAlertTemplates();
            });

        if (_.isEmpty(this.customerId)) {
            this.getCurrentCustomerId();
        }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.customerSubscription.unsubscribe();
    }

    getCurrentCustomerId() {
        this.backendService.getCustomers().subscribe((customerList) => {
            let customerArr = this.utilsService.orderListByFieldName(
                customerList.map((customer) => {
                    return { id: customer.id, name: customer.name };
                }),
                'name'
            );
            this.backendService.getLastCustomer().subscribe((response) => {
                if (_.isEmpty(response)) {
                    this.customerId = customerArr[0].id;
                } else {
                    this.customerId = response.id;
                }
                this.getAlertTemplates();
            });
        });
    }

    addTemplate(): void {
        this.router.navigate(['add-smart-alert'], {
            relativeTo: this.activatedRoute,
        });
    }

    editTemplate(template: AlertTemplate): void {
        this.router.navigate([`edit-smart-alert`], {
            state: { templateId: template.id },
            relativeTo: this.activatedRoute,
        });
    }

    deleteSmartAlertTemplate(template: AlertTemplate): void {
        this.backendService
            .deleteAlertTemplate(template.id, this.customerId)
            .subscribe(() => {
                this.notificationService.addSuccessMessage(
                    'Smart Alert Template',
                    'Template successfully updated!'
                );
                this.getAlertTemplates();
            });
    }

    changeAlertTemplateStatus(template: AlertTemplate): void {
        this.backendService
            .changeAlertTemplateStatus(template.isEnabled, template.id)
            .subscribe(() => {
                this.notificationService.addSuccessMessage(
                    'Smart Alert Template',
                    'Template successfully updated!'
                );
            });
    }

    private getAlertTemplates(): void {
        const inputParam: TemplateOverviewReq = {
            customerId: this.customerId,
            pagination: { page: 0, size: 1000, sort: 'name' },
        };
        this.backendService
            .getAlertTemplateList(inputParam)
            ?.subscribe((res) => {
                this.alertTemplateList = of(res.content);
            });
    }
}
