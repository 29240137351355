import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MappedDataPoint, DataPoint } from 'src/app/models/data-point';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-compare-datapoint-to-twin-action',
    templateUrl: './compare-datapoint-to-twin-action.component.html',
    styleUrls: ['./compare-datapoint-to-twin-action.component.scss']
})
export class CompareDatapointToTwinActionComponent implements OnInit {
    @Input()
    public selectedDataPoints: MappedDataPoint[];

    constructor(private dialogConfigService: DialogConfigService) {
    }

    ngOnInit() {
    }

    public showCompareDataPointToTwinDialog() {
        this.dialogConfigService.showCompareDataPointToTwinDialog(this.selectedDataPoints);
    }
}
