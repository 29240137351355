<div class="siteName">Site: {{ siteName }}</div>
<table>
    <tr *ngFor="let day of days">
        <ng-container>
            <td>{{ day | titlecase }}</td>
            <td>
                {{ siteSchedule[day]?.openTime ? 'Open' : 'Closed' }}
            </td>
            <td class="time">
                <span>
                    {{
                        siteSchedule[day]?.openTime
                            ? siteSchedule[day]?.openTime +
                              ' - ' +
                              siteSchedule[day]?.closeTime
                            : ''
                    }}
                </span>
            </td>
        </ng-container>
    </tr>
</table>
<div class="footer">
    <button
        class="p-button-raised p-button-text cbms-button cbms-margin-left p-button p-component"
        (click)="onCancel()"
    >
        CLOSE
    </button>
</div>
