import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataPoint, ExtendedDataPoint } from 'src/app/models/data-point';
import { BackendService } from 'src/app/services/backend/backend.service';
import _ from 'lodash';
import { NotificationService } from '../../services/notification/notification.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cbms-delete-twin-from-data-point-dialog',
    templateUrl: './delete-twin-from-data-point-dialog.component.html',
    styleUrl: './delete-twin-from-data-point-dialog.component.scss',
})
export class DeleteTwinFromDataPointDialogComponent implements OnInit {
    public dataPoints: ExtendedDataPoint[] = [];
    public deleteTwinFromScheduleSubscription: Subscription;
    public isLoading = false;
    public isLoadingDataPoints = false;
    public requestSent = false;

    public cols: any[] = [
        { field: 'siteName', header: 'Site', sortable: true, width: '' },
        { field: 'deviceName', header: 'Device', sortable: true, width: '' },
        { field: 'objectName', header: 'Name', sortable: true, width: '' },
        {
            field: 'description',
            header: 'Source Description',
            sortable: true,
            width: '',
        },
        {
            field: 'customName',
            header: 'Custom Name',
            sortable: true,
            width: '',
        },
        { field: 'result', header: 'Result', sortable: true, width: '' },
    ];

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private backendService: BackendService,
        private notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.isLoadingDataPoints = true;
        const filterParams = {
            dataPointIdFilter: Object.keys(this.config.data).map(
                (key) => this.config.data[key]
            ),
        };
        const pageSize = filterParams.dataPointIdFilter.length;

        this.backendService
            .getDataPointsDetails(filterParams, 0, pageSize)
            .subscribe((response) => {
                this.dataPoints = response.content.map(
                    (dataPoint: DataPoint) => {
                        return Object.assign({}, dataPoint, {
                            siteName: dataPoint.site.name,
                            deviceName: dataPoint.deviceName,
                            result: {},
                        });
                    }
                );
                this.isLoadingDataPoints = false;
            });
    }

    public deleteTwin() {
        this.isLoading = true;
        this.requestSent = true;
        this.deleteTwinFromScheduleSubscription = this.backendService
            .deleteTwinFromDatapoint(
                this.dataPoints.map((dataPoint) => dataPoint.id)
            )
            .subscribe(
                (response) => {
                    const [twin_value_deleted_msg, does_not_have_a_twin] =
                        Object.keys(response);
                    this.dataPoints.forEach((dataPoint) => {
                        if (
                            response[twin_value_deleted_msg]?.includes(
                                dataPoint.id
                            )
                        ) {
                            dataPoint.success = true;
                            dataPoint.result.message = twin_value_deleted_msg;
                        } else {
                            dataPoint.success = false;
                            dataPoint.result.message = does_not_have_a_twin;
                        }
                    });
                    this.isLoading = false;
                },
                (err) => {
                    this.isLoading = false;
                    this.notificationService.addErrorMessage(
                        'Delete Twin',
                        err
                    );
                }
            );
    }

    public close() {
        if (this.deleteTwinFromScheduleSubscription) {
            this.deleteTwinFromScheduleSubscription.unsubscribe();
        }
        this.ref.close();
    }
}
