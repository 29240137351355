<div class="timeframe-filter">
    <span class="cbms-margin-right" *ngIf="showInfoText"><strong>Filter by time</strong></span>
    <p-calendar
        #calendar
        [styleClass]="styleClass"
        [(ngModel)]="rangeDates"
        hideOnDateTimeSelect="false"
        selectionMode="range"
        yearRange="2000:2030"
        dateFormat="dd.mm.yy"
        [maxDate]="maxDateValue"
        [readonlyInput]="true"
        placeholder="Select range"
        (onSelect)="onIntervalSelected($event)"
    >
        <p-header>
            <div
                class=""
                style="
                    padding: 0 0 10px 0;
                    margin: 0 0 10px 0;
                    border-bottom: 1px #404040 solid;
                "
            >
                <p-dropdown
                    [options]="timeOptionList"
                    [(ngModel)]="selectedTime"
                    optionLabel="label"
                    placeholder="Select Time"
                    (onChange)="onIntervalOptionSelected()"
                >
                </p-dropdown>
                <button
                    pButton
                    class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
                    (click)="onFilter(); calendar.toggle()"
                >
                    Apply
                </button>
            </div>
        </p-header>
    </p-calendar>
</div>
