import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BackendService } from '../../../services/backend/backend.service';
import { ViewDevice } from '../../../models/device';
import { DataPointTag, MappedDataPoint } from '../../../models/data-point';
import { ConfirmationService } from 'primeng/api';
import { DialogConfigService } from '../../../services/dialog-config/dialog-config.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { DataService } from '../../../services/data/data.service';
import { AutoComplete } from 'primeng/autocomplete';
import { ASSIGN_BY, DatapointsScan, DATAPOINTS_OP, ExternalSource, FieldList } from '../../../config/constants';
import { FieldFilter, FilterParams } from 'src/app/components/filter/interfaces/filter-params.interface';
import { interval, Observable, Subscription } from 'rxjs';
import { Table } from 'primeng/table/table';
import { UtilsService } from 'src/app/services/utils/util.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'cbms-device-data-points-table',
    templateUrl: './device-data-points-table.component.html',
    styleUrls: ['./device-data-points-table.component.scss']
})

export class DeviceDataPointsTableComponent implements OnInit, OnDestroy {
    @Input()
    public showCaption = true;

    @Input()
    public device: ViewDevice;

    @Input()
    public dataPoints: MappedDataPoint[];

    @Input()
    public isHierarchyVisible: boolean;

    @Input()
    public deviceSource: string;

    @Output()
    public resetHierarchy = new EventEmitter<boolean>();

    @Output()
    public nrOfSelectedDataPoints = new EventEmitter<number>();

    @ViewChild(AutoComplete)
    public autocompleteRef: AutoComplete;

    @ViewChild('dataPointsTable') dataPointsTable: Table;
    @ViewChild('assign') assignOverlay: OverlayPanel;

    public editMode = false;
    public showSidebar = false;
    public isScanning = false;
    public selectedDataPoint: MappedDataPoint;
    public isLoading = false;
    public selectedDataPoints = [];
    public allDPSelected: boolean = false;
    public visible = true;
    private page = 0;
    private size = 10000;

    public cols: any[] = [
        { field: 'objectName', header: 'Name', sortable: true, width: '180px' },
        { field: 'site.customer.name', header: 'Customer', sortable: true, width: '180px' },
        { field: 'site.name', header: 'Site', sortable: true, width: '180px' },
        { field: 'polled', header: 'Polled', sortable: true, width: '120px' },
        { field: 'sourceType', header: 'IO-Type', sortable: true, width: '140px' },
        { field: 'instanceExtension', header: 'Instance', sortable: true, width: '140px' },
        { field: 'description', header: 'Description', sortable: true, width: '' },
        { field: 'lastRecordedValue', header: 'Last Recorded Value', sortable: true, width: '200px' },
        { field: 'units', header: 'Units', sortable: true, width: '130px' },
        { field: 'tags', header: 'Tags', sortable: true, width: '' }
    ];

    public wiseMeterCols: any[] = [
        { field: 'objectName', header: 'Name', sortable: true, width: '180px' },
        { field: 'site.customer.name', header: 'Customer', sortable: true, width: '180px' },
        { field: 'site.name', header: 'Site', sortable: true, width: '180px' },
        { field: 'polled', header: 'Polled', sortable: true, width: '120px' },
        { field: 'sourceType', header: 'IO-Type', sortable: true, width: '140px' },
        { field: 'sourceId', header: 'ID', sortable: true, width: '140px' },
        { field: 'classificationExtension', header: 'Classification', sortable: true, width: '' },
        { field: 'lastRecordedValue', header: 'Last Recorded Value', sortable: true, width: '' },
        { field: 'units', header: 'Units', sortable: true, width: '130px' },
        { field: 'tags', header: 'Tags', sortable: true, width: '' }
    ];

    public text: string;
    public results: DataPointTag[];
    public spinnerValue: number;
    public tableName = 'devicesDpTable';
    public isPollingGroupSelectionSidebarVisible: boolean = false;
    public pollingType: string = 'global';
    public pollingGroupSelection = {
        allInput: false,
        allOutput: false,
        allPhysical: false,
        allLogical: false,
        allSchedule: false
    }

    public globalPresetsList: string[] = [];
    public protocolSpecificList: { global: string, specific: string }[] = [];

    public globalPresetsUIList: any;
    public protocolSpecificUIList: any;

    loadingText: string;

    private pollDataPoint$: Subscription;
    private isWiseMeterDevice: boolean;

    private protocolTypeOrder = {
        'Input': 1,
        'Output': 2,
        'Logical': 3,
        'Schedule': 4
    }
    private datapointChange$ = new Subscription();

    constructor(private backendService: BackendService,
        private dialogService: DialogConfigService,
        private notificationService: NotificationService,
        private dataService: DataService,
        private utilsService: UtilsService,
        private confirmationService: ConfirmationService) {

    }

    public ngOnInit() {
        this.spinnerValue = 0;
        this.isWiseMeterDevice = ExternalSource.WISEMETER === this.deviceSource;
        this.datapointChange$ = this.dataService.datapointsInDeviceTabChange$.subscribe(() => {
            this.loadDataPoints();
        })
    }

    public ngOnDestroy(): void {
        this.doUnsubscribe();
        this.datapointChange$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.isHierarchyVisible && changes.isHierarchyVisible.currentValue === false) {
            if (sessionStorage.getItem(`${this.tableName}-session`)) {
                setTimeout(() => {
                    this.refreshTableOnShowHierarchyButtonToggle();
                }, 300);
            }
        }

        if (changes.dataPoints && changes.dataPoints.currentValue) {
            this.selectedDataPoints = [];
            this.allDPSelected = false;

            this.dataPoints = changes.dataPoints.currentValue.map((dataPoint: MappedDataPoint): MappedDataPoint => {
                return Object.assign({}, dataPoint, { selected: false });
            });

            setTimeout(() => {
                this.nrOfSelectedDataPoints.emit(0);
            });
        }
    }

    public scan() {
        this.resetHierarchy.emit(true);
        this.selectedDataPoints = [];
        this.allDPSelected = false;

        this.isLoading = true;
        this.spinnerValue = 0;
        this.isScanning = true;
        this.dataPoints = null;
        this.device.dataPoints = null;
        this.loadingText = 'Scanning data points - it can take a while...';
        this.backendService.scanForDataPoints(this.device.id)
            .subscribe(dataPoints => {
                this.isLoading = false;
                if (dataPoints.data.datapoint_count === 0) {
                    this.loadDatapointsAndClearPollSubscription();
                    return;
                }
                this.loadingText = `Waiting for ${dataPoints.data.datapoint_count} data points - it can take a while...`;

                this.scanStatus(dataPoints.headers.cookie.sessionId, dataPoints.data.datapoint_count);
            }, () => {
                this.loadDatapointsAndClearPollSubscription();
            });
    }

    public loadDataPoints() {
        this.isLoading = true;
        const filterParams: FilterParams = {
            deviceFilter: [this.device]
        };

        this.backendService.getDataPointsDetails(filterParams, this.page, this.size, false).subscribe(response => {
            this.dataPoints = response.content;
            this.device.dataPointsCopy = [...response.content];
            this.device.dataPoints = [...response.content];
            this.isLoading = false;
            this.isScanning = false;
        });
    }

    public toggleEditMode() {
        this.editMode = !this.editMode;
    }

    public cancel() {
        this.dataPoints.forEach(dataPoint => {
            this.device.dataPointsCopy.forEach(dataPointCopy => {
                if (dataPoint.id === dataPointCopy.id) {
                    dataPoint.polled = dataPointCopy.polled;
                }
            });
        });
        this.toggleEditMode();
    }

    public save() {
        const { idListToPoll, idListToUnpoll}  = this.generatePolledUnpolledLists(this.device.dataPointsCopy, this.dataPoints);

        this.isLoading = true;
        this.backendService.setPollingDataPoints(this.device.id, idListToPoll, idListToUnpoll).subscribe(() => {
                const filterParams: FilterParams = {
                    deviceFilter: [this.device]
                };
                //TODO maybe we can remove this extra call to BE, we already have the latest updates...
                // it is needed in order to have the right polling status
                this.backendService.getDataPointsDetails(filterParams, this.page, this.size, false).subscribe(response => {
                    this.device.dataPointsCopy = response.content;
                    this.device.totalRecords = response.totalElements;

                    this.updateDataPointPolling(this.dataPoints, response.content);
                    this.isLoading = false;
                });
                this.isLoading = false;
            });
        this.toggleEditMode();
    }

    public onPollingHeaderChange(event: {checked: boolean, originalEvent: Event}) {
        event.originalEvent.stopPropagation();
        this.dataPoints.forEach(dataPoint => dataPoint.polled = event.checked);
    }

    public showTelemetryDialog(dataPoints: MappedDataPoint[]) {
        this.dialogService.showTelemetryDialog(dataPoints, true);
    }

    public showSetpointDialog(dataPoints: MappedDataPoint[]) {
        this.dialogService.showSetpointDialog({dataPoints: dataPoints, operation: DATAPOINTS_OP.MANAGE_VALUES});
    }

    public showDataPointPropertiesDialog(dataPoint: MappedDataPoint) {
        this.dialogService.showDataPointPropertiesDialog(dataPoint);
    }

    public search(event) {
        this.backendService.getDataPointTagsAutoSuggestions(event.query).subscribe(result => {
            this.results = result;
        });
    }

    public isValidTag(value: string) {
        const regexp = /^[a-zA-Z0-9_.]+$/; //only alphanumeric, no white space or special chars except "_" or "."
        return value.search(regexp) !== -1;
    }

    public onKeyUp(event: KeyboardEvent) {
        if (event.code !== 'Enter' && event.code !== 'Space') {
            return;
        }

        let tagInput = event.srcElement as any;
        let tagValue = tagInput.value.trim();

        if (tagValue && this.isValidTag(tagValue)) {
            if (this.selectedDataPoint.tags.find(tag => tag.name === tagValue)) {
                return;
            }

            this.selectedDataPoint.tags.push({ name: tagValue });
            tagInput.value = '';
        }
    }

    public displayTags(tags: DataPointTag[]) {
        if (!tags) {
            return '';
        }
        return tags.map(tag => tag.name).join(', ');
    }

    public showTagsSidebar(dataPoint: MappedDataPoint) {
        this.selectedDataPoint = Object.assign({}, dataPoint, { tags: [...dataPoint.tags] });
        this.showSidebar = true;
    }

    public tagsNotChanged(selectedDataPoint: MappedDataPoint) {
        let dataPointToUpdate = this.dataPoints.find(dataPoint => dataPoint.id === selectedDataPoint.id);

        return selectedDataPoint.tags.map(tag => tag.name).join() === dataPointToUpdate.tags.map(tag => tag.name).join();
    }

    public saveTags(selectedDataPoint: MappedDataPoint) {

        if (this.tagsNotChanged(selectedDataPoint)) {
            return;
        }

        this.backendService.updateDataPointTags(selectedDataPoint.id, selectedDataPoint.tags).subscribe((response) => {
            response.forEach(updatedTag => {
                selectedDataPoint.tags.forEach(localTag => {
                    if (updatedTag.name === localTag.name) {
                        localTag.id = updatedTag.id;
                    };
                });
            });

            this.dataService.changeNewTagsStatus({dataPointsTagsUpdated: true});
            this.updateDataPointsTags([selectedDataPoint]);

            this.showSidebar = false;
            this.selectedDataPoint = null;
            this.notificationService.addSuccessMessage('Edit Tags', 'Data Point successfully updated.', false);
        });
    }

    public cancelTagsEditing() {
        this.showSidebar = false;
        this.selectedDataPoint = null;
        this.autocompleteRef.multiInputEl.nativeElement.value = '';
    }

    public onPageChange() {
        setTimeout(() => {
            const el = document.getElementById(`id${this.device.id}`);
            el.scrollIntoView({ behavior: 'smooth' });
        });
    }

    public updateSelectedDPList(dataPoint: MappedDataPoint) {
        if (dataPoint.selected) {
            this.selectedDataPoints.push(dataPoint);
        } else {
            this.selectedDataPoints = this.selectedDataPoints.filter(selectedDataPoint => selectedDataPoint.id !== dataPoint.id);
        }

        this.nrOfSelectedDataPoints.emit(this.selectedDataPoints.length);
    }

    public toggleSelectedDP(event: MouseEvent) {
        event.stopPropagation();

        if (this.allDPSelected) {
            this.selectedDataPoints = this.dataPoints.map((dataPoint) => {
                dataPoint.selected = true;
                return dataPoint;
            });

            this.nrOfSelectedDataPoints.emit(this.selectedDataPoint ? this.selectedDataPoints.length - 1 : this.selectedDataPoints.length);
        } else {
            this.resetSelectedDataPoints();
        }
    }

    public resetSelectedDataPoints() {
        this.dataPoints.forEach(dataPoint => { dataPoint.selected = false; });
        this.selectedDataPoints = [];
        this.allDPSelected = false;
        this.nrOfSelectedDataPoints.emit(0);
    }

    public updateDataPointsTags(updatedDataPoints: MappedDataPoint[]) {
        updatedDataPoints.forEach(updatedDataPoint => {
            this.dataPoints.forEach(dataPoint => {
                if (dataPoint.id === updatedDataPoint.id) {
                    dataPoint.tags = updatedDataPoint.tags;
                };
            });

            this.device.dataPointsCopy.forEach(dataPoint => {
                if (dataPoint.id === updatedDataPoint.id) {
                    dataPoint.tags = updatedDataPoint.tags;
                };
            });
        });
    }

    public updateDataPointsList(deletedDataPointIdList: string[]) {
        this.dataPoints = this.dataPoints.filter(dataPoint => deletedDataPointIdList.indexOf(dataPoint.id) === -1);
        this.device.dataPointsCopy = this.device.dataPointsCopy.filter(dataPoint => deletedDataPointIdList.indexOf(dataPoint.id) === -1);
    }

    public globalPresetCheckboxUpdated(selectedOption) {
        if (this.isWiseMeterDevice) {
            if (selectedOption.globalType === 'Output' || selectedOption.globalType === 'Schedule') {
                this.globalPresetsUIList.forEach( (globalItem) => {
                    if (globalItem.globalType === 'Output' || globalItem.globalType === 'Schedule') {
                        globalItem.isChecked = selectedOption.isChecked;

                        this.protocolSpecificUIList.forEach( (specificItem) => {
                            if (specificItem.globalType === globalItem.globalType) {
                                specificItem.isChecked = globalItem.isChecked;
                            }
                        });
                    }
                });
            }
        }

        this.protocolSpecificUIList.forEach( (item) => {
            if (item.globalType === selectedOption.globalType) {
                item.isChecked = selectedOption.isChecked;
            }
        });
    }

    public protocolSpecificCheckboxUpdated() {
        this.resetGlobalPresets();
    }

    public showPollingGroupSelectionSidebar() {
        this.generateGroupPollingOptions();
        this.isPollingGroupSelectionSidebarVisible = true;
    }

    public applyPollingGroupSelection() {
        let selectedGlobalTypes = [];
        let selectedSpecificTypes = [];

        this.globalPresetsUIList.forEach( (item) => {
            if (item.isChecked) {
                selectedGlobalTypes.push(item.globalType);
            }
        });

        this.protocolSpecificUIList.forEach( (item) => {
            if (item.isChecked) {
                selectedSpecificTypes.push(item.specificType);
            }
        });

        if (this.pollingType === 'global') {
            this.dataPoints.forEach((item) => {
                if (selectedGlobalTypes.indexOf(item.type) !== -1) {
                    item.polled = true;
                } else {
                    item.polled = false;
                }
            });
        }

        if (this.pollingType === 'protocolSpecific') {
            this.dataPoints.forEach((item) => {
                if (selectedSpecificTypes.indexOf(item.sourceType) !== -1) {
                    item.polled = true;
                } else {
                    item.polled = false;
                }
            });
        }

        this.isPollingGroupSelectionSidebarVisible = false;
    }

    public cancelPollingGroupSelection() {
        this.isPollingGroupSelectionSidebarVisible = false;
    }

    public confirmGroupPolling() {
            this.confirmationService.confirm({
                message: `Warning: All previous poll selections will be reset and ones in this dialog box will be applied.<br/><br/>
                        However, you will still be able to manually select and de-select individual datapoints afterwards.<br/><br/>
                        Are you sure you want to continue?`,
                accept: () => {
                    this.applyPollingGroupSelection();
                }
            });
    }

    public assignSiteAction(event) {
        if (this.selectedDataPoints.filter(dataPoint => dataPoint.site).length) {
            this.assignOverlay.show(event);
            return;
        }
        this.showAssignDialog();
    }

    public showAssignDialog() {
        this.dialogService.showAssignDialog(this.selectedDataPoints.map(dataPoint => dataPoint.id), ASSIGN_BY.DATA_POINTS);
    }

    public unassignSite() {
        this.backendService.unassignDataPointsFromSite(this.selectedDataPoints.map(dataPoint => dataPoint.id)).subscribe(response => {
            this.notificationService.addSuccessMessage('Unassign Site', `${this.selectedDataPoints.length} data points successfully unassigned from site`, false);
            this.loadDataPoints();
        }, (err) => {
            this.notificationService.addErrorMessage('Unassign Site', err);
        });
    }

    private generateGroupPollingOptions() {
        this.globalPresetsList = [];
        this.protocolSpecificList = [];

        this.dataPoints.forEach( (dataPoint) => {
            if (this.globalPresetsList.indexOf(dataPoint.type) === -1 && dataPoint.type !== 'Undefined') {
                this.globalPresetsList.push(dataPoint.type);
            }
            if (this.protocolSpecificList.map(item => item.specific).indexOf(dataPoint.sourceType) === -1) {
                this.protocolSpecificList.push({global: dataPoint.type, specific: dataPoint.sourceType});
            }
        });

        this.globalPresetsUIList = this.utilsService.orderListByFieldName(this.globalPresetsList.map( (item) => {
            return  {
                order: this.protocolTypeOrder[item],
                label: item,
                globalType: item,
                isChecked: false
            };
        }), 'order');

        this.protocolSpecificUIList = this.utilsService.orderListByFieldName(this.protocolSpecificList.map( (item) => {
            return  {
                order: this.protocolTypeOrder[item.global],
                label: item.specific,
                globalType: item.global,
                specificType: item.specific,
                isChecked: false
            };
        }), 'order');
    }

    private resetGlobalPresets() {
        this.globalPresetsUIList.forEach( (item) => {
            item.isChecked = false;
        });
    }

    private updateDataPointPolling(listToUpdate: MappedDataPoint[], newData: MappedDataPoint[]) {
        listToUpdate.forEach((dataPointToUpdate: MappedDataPoint) => {
            newData.forEach((newDataPoint: MappedDataPoint) => {
                if (dataPointToUpdate.id === newDataPoint.id) {
                    dataPointToUpdate.polled = newDataPoint.polled;
                }
            });
        });
    }

    private generatePolledUnpolledLists(initialDataPoints, updatedDataPoints) {
        const initialPointsObj = initialDataPoints.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {});

        const updatedPointsObj = updatedDataPoints.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {});

        const idListToPoll = [];
        const idListToUnpoll = [];
        Object.keys(initialPointsObj).forEach(itemId => {
            if (updatedPointsObj[itemId].polled !== initialPointsObj[itemId].polled) {
                if (updatedPointsObj[itemId].polled) {
                    idListToPoll.push(itemId);
                } else {
                    idListToUnpoll.push(itemId);
                }
            }
        })

        return { idListToPoll, idListToUnpoll };
    }

    private loadDatapointsAndClearPollSubscription() {
        this.doUnsubscribe();
        this.loadDataPoints();
    }

    private scanStatus(sessionId: string, datapointCount: number) {
        const numberObservable: Observable<number> = interval(DatapointsScan.DATAPOINTS_SCAN_STATUS_MILISECOND_INTERVAL);
        this.pollDataPoint$ = numberObservable.subscribe(this.pollScanStatusUntillDone(sessionId, datapointCount));

    }

    private pollScanStatusUntillDone(sessionId: string, datapointCount: number) {
        return () => {
            this.backendService.getDataPointScanStatusBySessionId(sessionId).subscribe(result => {
                this.spinnerValue = Math.round((result.currentCounter / result.totalCounter) * 100);
                this.loadingText = `Waiting for ${datapointCount} data points - it can take a while... ${this.spinnerValue} % done...  (please check Audit Logs for status details)`;
                if (result.done) {
                    this.loadDatapointsAndClearPollSubscription();
                }
            }, () => {
                this.loadDatapointsAndClearPollSubscription();
            });
        };
    }


    private doUnsubscribe() {
        if (this.pollDataPoint$) {
            this.pollDataPoint$.unsubscribe();
        }
    }

    private refreshTableOnShowHierarchyButtonToggle() {
        if (this.dataPointsTable) {
            this.dataPointsTable.clearState();
        }
        this.visible = false;
        setTimeout(() => {
            this.visible = true;
        }, 10);
    }
}
