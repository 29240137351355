import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BackendService, getPageSortPair, SortOrderType } from '../../services/backend/backend.service';
import { LazyLoadEvent } from 'primeng/api';
import { AuditTrail } from '../../models/core/auditTrail';
import { PAGE_SIZE_50 } from '../../config/constants';
import _ from 'lodash';
import { UtilsService } from 'src/app/services/utils/util.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { ColumnsToFilter } from 'src/app/models/columns-filter.interface';
import { FilterService } from '../filter/filter.service';
import saveAs from 'file-saver';
import { IActionLogFileReq } from 'src/app/models/api/AuditTrail';

const SORTABLE_COLUMN_CLASS = 'ui-sortable-column';

@Component({
    selector: 'cbms-audit-table',
    templateUrl: './audit-trails-table.component.html',
    styleUrls: ['./audit-trails-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AuditTrailsTableComponent implements OnInit {

    public pageSize = PAGE_SIZE_50;
    public auditData: AuditTrail[];
    public totalRecords: any;
    public selectedColumns: any[];
    public cols = [
        { field: 'userName', header: 'User', position: 1, width: '85px' },
        { field: 'tenantName', header: 'Tenant', position: 2, width: '100px' },
        { field: 'customerName', header: 'Customer', position: 3, width: '105px' },
        { field: 'siteName', header: 'Site', position: 4, width: '100px' },
        { field: 'gatewayName', header: 'Gateway', position: 5, width: '140px' },
        { field: 'deviceName', header: 'Device', position: 6, width: '140px' },
        { field: 'operationId', header: 'Operation ID', position: 7, width: '230px' },
        { field: 'dataPointName', header: 'Data Point Name', position: 8, width: '200px' },
        { field: 'dataPointDescription', header: 'Data Point Description', position: 9, width: '180px' },
        { field: 'dataPointType', header: 'Data Point Type', position: 10, width: '120px' },
        { field: 'dataPointId', header: 'Data Point ID', position: 11, width: '220px' },
        { field: 'actionCategory', header: 'Action Category', position: 12, width: '110px' },
        { field: 'action', header: 'Action', position: 13, width: '110px' },
        { field: 'actionTimestamp', header: 'Timestamp', position: 14, width: '150px' },
        { field: 'actionResult', header: 'Result Message', position: 15, width: '105px' },
        { field: 'valueBefore', header: 'Value Before Change', position: 16, width: '230px' },
        { field: 'valueAfter', header: 'Value After Change', position: 17, width: '230px' }];
    public isLoading = false;
    private tableName = 'auditTrails';
    private hiddenColumns: string[] = ['tenantName', 'gatewayName', 'deviceName', 'operationId', 'dataPointId'];
    private columnsFilterSubscription: Subscription;
    private getAuditDataSubscription: Subscription;
    private siteSubscription: Subscription;
    private columnsTofilter: ColumnsToFilter;
    private timeframeFilter: Date[];
    private customerObj: {name: string, id: string};

    constructor(private backendService: BackendService,
        private utilsService: UtilsService,
        private dataService: DataService,
        private filterService: FilterService) {
    }

    ngOnInit(): void {
        this.initSelectedColumns();

        this.columnsFilterSubscription = this.dataService.columnsFilterChanges$.subscribe((columnsToFilter: ColumnsToFilter) => {
            this.auditData = [];
            this.columnsTofilter = columnsToFilter;
            if (this.getAuditDataSubscription) {
                this.getAuditDataSubscription.unsubscribe();
            }
            this.loadData();
        });

        this.filterService.customerFilterChanges$.subscribe(() => {
            if (this.getAuditDataSubscription) {
                this.getAuditDataSubscription.unsubscribe();
            }
            this.loadData();
        });

        this.siteSubscription = this.filterService.siteFilterChanges$.subscribe(() => {
            if (this.getAuditDataSubscription) {
                this.getAuditDataSubscription.unsubscribe();
            }
            this.loadData();
        });

        this.dataService.timeframeFilterChanges$.subscribe((timeframe: Date[]) => {
            this.auditData = [];
            this.timeframeFilter = timeframe;
            if (this.getAuditDataSubscription) {
                this.getAuditDataSubscription.unsubscribe();
            }
            this.loadData();
        });
    }

    ngOnDestroy() {
        if (this.columnsFilterSubscription) {
            this.columnsFilterSubscription.unsubscribe();
        }
        if (this.siteSubscription) {
            this.siteSubscription.unsubscribe();
        }
        if (this.getAuditDataSubscription) {
            this.getAuditDataSubscription.unsubscribe();
        }
    }

    public selectedColumnsHaveChanged(event) {
        this.selectedColumns = this.utilsService.orderListByFieldName(event, 'position');
        sessionStorage.setItem(`${this.tableName}-selectedColumns`, JSON.stringify(this.selectedColumns));
    }

    public loadAuditDataLazy(event: LazyLoadEvent) {
        this.isLoading = true;

        const { pageNumber, sortOptions } = getPageSortPair(event, this.pageSize);
        const sortOptionComputed = sortOptions.sortField ? sortOptions : this.getDefaultSortOptions();
        this.backendService.getAllAuditTrail(this.columnsTofilter, this.timeframeFilter, pageNumber, this.pageSize, sortOptionComputed).subscribe(response => {
            this.auditData = response.content;
            this.totalRecords = response.totalElements;
            this.isLoading = false;
        }, (err) => {
            this.isLoading = false;
        });
    }

    public onPage(event) {
        setTimeout(() => {
            let tableState = JSON.parse(sessionStorage.getItem('audit-session'));
            tableState.first = 0;
            sessionStorage.setItem('audit-session', JSON.stringify(tableState));
        }, 100);
    }

    private loadData() {
        this.isLoading = true;
        this.getAuditDataSubscription = this.backendService.getAllAuditTrail(this.columnsTofilter, this.timeframeFilter, 0, this.pageSize, this.getDefaultSortOptions()
        ).subscribe(response => {
            this.auditData = response.content;
            this.totalRecords = response.totalElements;
            this.isLoading = false;
        }, (err) => {
            this.isLoading = false;
        });
    }

    private getDefaultSortOptions() {
        return {
            sortField: 'actionTimestamp',
            sortOrder: SortOrderType.DESC
        };
    }

    private initSelectedColumns() {
        let selectedColumnsFromSession = JSON.parse(sessionStorage.getItem(`${this.tableName}-selectedColumns`));

        if (selectedColumnsFromSession) {
            this.selectedColumns = selectedColumnsFromSession;
        } else {
            this.selectedColumns = this.cols.filter(col => {
                return !this.hiddenColumns.includes(col.field);
            });
        }
    }

    exportActionLog() {
        let tableColumnHeaderList: string[] = [];
        this.selectedColumns?.map((column)=> {
            tableColumnHeaderList.push(column?.header);
        });
        const filterParam = this.backendService.generateFilterObject(this.columnsTofilter, this.timeframeFilter);
        const body: IActionLogFileReq = {
            columns: tableColumnHeaderList,
            filter: filterParam?.filter
        }

        this.backendService.exportActionLogFile(body).subscribe(
            (response) => {
                const data = response?.body;
                const fileName = this.getFileName(response?.type);
                saveAs(
                    data,
                    fileName
                );
            },
            (err) => {
                console.log(err);
            }
        );
    }

    /**
     * @description get file name from the response headers
     * @input contentDisposition: content-disposition gotten from response headers
     * @output file name in this format: ` 'Customer' Action Log YYYYMMDD HH_MM.csv`
     */
    getFileName(contentDisposition: string): string {
        if (!contentDisposition) return `Action Log ${this.utilsService.getDateNowAsText()}.csv`;

        const prefixStr = `attachment; filename*=UTF-8''`;
        const fileName: string = decodeURIComponent(contentDisposition?.substring(prefixStr.length, contentDisposition.length));
        return fileName;
    }
}
