<cbms-filter [config]="filterConfigObject" [tabName]="tabName"></cbms-filter>

<div class="cbms-title-wrapper-baseline">
    <mat-icon class="cbms-title-icon" svgIcon="devices"></mat-icon>
    <span class="cbms-title-text">Devices</span>
    <span *ngIf="devices && !selectedDeviceIdList.length" class="cbms-margin-left-auto">{{totalRecords}}
        Devices</span>
    <span *ngIf="devices && selectedDeviceIdList.length" class="cbms-margin-left-auto">{{selectedDeviceIdList.length}}
        of {{totalRecords}}
        Devices selected</span>
</div>
<div class="cbms-title-wrapper-flex-end">
    <p-checkbox *ngIf="devices && devices.length" class="right-margin" binary="true" [(ngModel)]="allDevicesSelected"
        label="Select All" (onChange)="toggleSelectAllDevices()"></p-checkbox>

    <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left-auto icon-button"  [disabled]="!selectedDeviceIdList.length"
        (click)="unassignByDevice.show($event)">
        Unassign Site
    </button>

    <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left icon-button" [disabled]="!selectedDeviceIdList.length"
        (click)="assignByDevice.show($event)">
        Assign Site
    </button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left icon-button" [disabled]="!selectedDeviceIdList.length"
        (click)="deleteSelectedDevicesMenu.show($event)" data-cy="delete-all">
        <mat-icon svgIcon="delete"></mat-icon>
        Delete All
    </button>
</div>

<div class="cbms-spinner-wrapper" *ngIf="devices === null || isLoading">
    <mat-spinner data-cy="devices-spinner" [diameter]="60" class="cbms-spinner"></mat-spinner>
</div>

<div class="cbms-no-devices-wrapper" *ngIf="!hasDevices() && !isLoading">
    <span>
        No devices found
    </span>
</div>

<div class="devices-wrapper" *ngIf="hasDevices() && !isLoading">
    <mat-accordion data-cy="devices">
        <mat-expansion-panel *ngFor="let device of devices" data-id="id{{device.id}}" (opened)="openDevice(device)"
            (closed)="closeDevice()" [expanded]="device.isExpanded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p-checkbox class="select-checkbox" binary="true" [(ngModel)]="device.selected"
                        (click)="$event.stopPropagation();updateSelectedDevice()">
                    </p-checkbox>
                    <mat-icon class="header-icon" svgIcon="devices"></mat-icon>
                    <mat-icon *ngIf="device.online" class="header-icon cbms-green-color" svgIcon="wifi_on"></mat-icon>
                    <mat-icon *ngIf="device.online !== undefined && device.online === false"
                        class="header-icon cbms-red-color" svgIcon="wifi_off"></mat-icon>
                    <mat-icon *ngIf="device.online === undefined" class="header-icon cbms-grey-color"
                        svgIcon="wifi_off"></mat-icon>
                    <span class="panel-title">{{device.objectName}}</span>
                </mat-panel-title>
                <mat-panel-description class="description">
                    <span class="device-description" *ngIf="device.description">( {{device.description}} ) <small>ID
                            {{device.deviceId}}</small></span>
                    <span>{{device.deviceAddress}}</span>
                    <span *ngIf="device.online !== undefined">Last contact:
                        {{device.lastContact | date:'dd.MM.yyy, HH:mm:ss'}}</span>
                    <span class="gateway-info" (click)="goToGateway($event, device)">Gateway ID: {{device.gatewayHardwareId}}</span>
                    <button pButton class="p-button-raised p-button-text cbms-button icon-button auto-margin" (click)="$event.stopPropagation(); deleteMenu.toggle($event);">
                        <mat-icon svgIcon="delete"></mat-icon>
                        Delete
                    </button>
                    <p-overlayPanel #deleteMenu appendTo="body">
                        <p>
                            <strong>All data point configurations will be removed as well.</strong>
                        </p>
                        <br>
                        <p>
                            Please confirm you want to continue with the removal<br>of device
                            <strong>{{device.deviceId}}</strong>.
                        </p>
                        <hr>
                        <div class="delete-action-buttons">
                            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                                (click)="deleteDevice(device); deleteMenu.hide();">Confirm</button>
                            <button pButton class="p-button-raised p-button-text cbms-button" (click)="deleteMenu.hide()">Cancel</button>
                        </div>
                    </p-overlayPanel>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="cbms-spinner-wrapper" *ngIf="isLoadingDataPoints">
                <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
            </div>

            <div style="padding-top: 30px" *ngIf="device.isOpened">
                <div class="data-points-nr">
                    <span class="cbms-margin-left-auto"
                        *ngIf="device.dataPoints !== null && !nrOfSelectedDataPoints">{{device.dataPoints?.length}} Data
                        Points</span>
                    <span class="cbms-margin-left-auto"
                        *ngIf="device.dataPoints !== null && nrOfSelectedDataPoints">{{nrOfSelectedDataPoints}}
                        of {{device.dataPoints.length}} Data Points Selected</span>
                </div>

                <div class="filter-component">
                    <div class="filter-wrapper">
                        <cbms-quick-filter [styleClass]="'filter-item-margin-left0'" [quickFilterText]="quickFilter"
                            (valueChange)="onQuickFilterChange($event)">
                        </cbms-quick-filter>

                        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto filter-item"
                            (click)="onFilter()" data-cy="apply-filter">Apply
                            Filter</button>
                        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__red-bg filter-item"
                            (click)="clearFilters()" data-cy="clear-filter">
                            <span class="table-caption-text">Clear Filter</span>
                        </button>
                    </div>

                    <cbms-field-filter [selectedFieldFilter]="fieldFilter" [fieldFilterOptions]="fieldFilterOptions"
                        (valueChange)="onFieldFilterChange($event)"></cbms-field-filter>
                </div>

                <div class="device-datapoints-wrapper">
                    <button pButton class="p-button-raised p-button-text cbms-button hierarchy-toggle-button" (click)="toggleHierarchyVisibility()">
                        <ng-container *ngIf="isHierarchyVisible;else show">
                            <i class="pi pi-sitemap"></i>
                            <span class="table-caption-text">Hide Hierarchy</span>
                        </ng-container>
                        <ng-template #show>
                            <i class="pi pi-sitemap "></i>
                            <span class="table-caption-text">Show Hierarchy</span>
                        </ng-template>
                    </button>
                    <div class="hierarchy-wrapper" [ngClass]="{ 'visible': isHierarchyVisible }">
                        <div class="hierarchy-separator">
                            <span>Separator</span>
                            <input pInputText type="text" [(ngModel)]="hierarchySeparator">
                            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                                (click)="createHierarchy(device)">Apply</button>
                            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                                (click)="resetHierarchy(device)">Reset</button>

                        </div>
                        <p-tree #pTreeRef selectionMode="single" [(selection)]="selectedNode"
                            (onNodeSelect)="onNodeSelect($event, device)" [value]="hierarchyArray"></p-tree>
                    </div>
                    <div class="data-points-table-wrapper" [ngClass]="{ 'hierarchyVisible': isHierarchyVisible}">
                        <cbms-device-data-points-table [deviceSource]="device.source" class="data-points-table"
                            (nrOfSelectedDataPoints)="updateNrOfDataPoints($event)"
                            (resetHierarchy)="handleResetHierarchy($event)" [isHierarchyVisible]="isHierarchyVisible"
                            [device]="device" [dataPoints]="device.dataPoints" [showCaption]="true">
                        </cbms-device-data-points-table>
                    </div>
                </div>
            </div>


        </mat-expansion-panel>
    </mat-accordion>
</div>

<p-paginator [rows]="rows" [totalRecords]="totalRecords" (onPageChange)="paginateDevices($event)"></p-paginator>


<p-overlayPanel #deleteSelectedDevicesMenu appendTo="body">
    <p>
        Please confirm you want to continue with the removal<br>of
        <strong>{{selectedDeviceIdList.length}}</strong> devices.
    </p>
    <hr>
    <div class="delete-action-buttons">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="deleteSelectedDevices(); deleteSelectedDevicesMenu.hide();">Confirm
        </button>
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="deleteSelectedDevicesMenu.hide()">Cancel</button>
    </div>
</p-overlayPanel>

<p-overlayPanel #unassignByDevice appendTo="body" styleClass="cbms-mid-size">
    <p>
        You are about to remove the site assignment to these data points. <br />
        Do you wish to proceed?
    </p>
    <hr>
    <div class="cbms-overlay-confirmation-buttons">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="unassignSite(); unassignByDevice.hide();">Confirm
        </button>
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="unassignByDevice.hide()">Cancel</button>
    </div>
</p-overlayPanel>

<p-overlayPanel #assignByDevice appendTo="body" styleClass="cbms-mid-size">
    <p>
        Data points already have a site assignment. <br />
        Do you wish to proceed?
    </p>
    <hr>
    <div class="cbms-overlay-confirmation-buttons">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="showAssignDialog(); assignByDevice.hide();">Confirm
        </button>
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="assignByDevice.hide()">Cancel</button>
    </div>
</p-overlayPanel>
