import { Component, Input } from '@angular/core';
import { MappedDataPoint } from '../../../models/data-point';
import { DialogConfigService } from '../../../services/dialog-config/dialog-config.service';
import { TOTAL_NR_OF_DATAPOINTS_TO_ANALYZE } from '../../../config/constants';

@Component({
    selector: 'cbms-analysis-action',
    templateUrl: './analysis-action.component.html',
    styleUrls: ['./analysis-action.component.scss'],
})
export class AnalysisActionComponent {
    @Input()
    public selectedDataPoints: MappedDataPoint[];
    public totalNrOfDPToAnalyze: number = TOTAL_NR_OF_DATAPOINTS_TO_ANALYZE;

    @Input()
    isGlobalAction: boolean;

    constructor(
        private dialogConfigService: DialogConfigService
    ) {}

    public showTelemetryDialog(mappedDataPoints: MappedDataPoint[]) {
        this.dialogConfigService.showTelemetryDialog(mappedDataPoints);
    }
}
