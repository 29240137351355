import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {BackendService} from '../../services/backend/backend.service';
import {ConfirmationService} from 'primeng/api';
import {NotificationService} from '../../services/notification/notification.service';
import {createLabelValuePair, LabelValuePair, LabelValuePairGroup, OcSite} from '../../models/core/CoreModels';

const CORE_GRANULARITY_15MIN = "15MIN"
const CORE_GRANULARITY_1MIN = "1MIN"
const CORE_GRANULARITY_5MIN = "5MIN"
const CORE_GRANULARITY_10MIN = "10MIN"
const CORE_GRANULARITY_30MIN = "30MIN"
const CORE_GRANULARITY_HOUR = "HOUR"
const CORE_GRANULARITY_DAY = "DAY"

const FALSE_STR = "FALSE";

const TRUE_STR = "TRUE";


@Component({
    selector: 'cbms-add-oc-tenant-dialog',
    templateUrl: './add-oc-tenant-dialog.component.html',
    styleUrls: ['./add-oc-tenant-dialog.component.scss']
})
/**
 * @description
 * Assign Optimum Commercial properties to selected dataPoints.
 * e.g. of OC properties: OC tenant, OC site, Granularity...
 */
export class AddOcTenantDialogComponent implements OnInit {
    readonly CC_DATASOURCE = "CC_DATASOURCE";
    readonly ESP_IOT_DATASOURCE = "ESP_IOT_DATASOURCE";
    customerGroupList: LabelValuePairGroup[];
    selectedSiteId: string;
    tenantList: LabelValuePair[];
    selectedTenantId: string;
    private dataPoints: any;
    private selectedOcSite: OcSite;
    granularityList: LabelValuePair[];
    selectedGranularity: string;
    selectedIsAccumulator: string;
    isAccumulatorList: LabelValuePair[];

    selectedDataSource: string;
    dataSourceList: LabelValuePair[];

    selectedChannel: string;
    selectedClassification: string;
    classificationList: LabelValuePair[];
    channelList: LabelValuePair[];

    constructor(public ref: DynamicDialogRef,
                public config: DynamicDialogConfig,
                private backendService: BackendService,
                private confirmationService: ConfirmationService,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.dataPoints = this.config.data;
        this.initTenants();
        this.initGranularity();
        this.initClassification();

        this.isAccumulatorList = [
            createLabelValuePair("Yes", TRUE_STR),
            createLabelValuePair("No", FALSE_STR),
        ]
        this.selectedIsAccumulator = FALSE_STR

        //init External source Optimum CC or E.IoT
        this.dataSourceList = [
            createLabelValuePair("Optimum CC", this.CC_DATASOURCE, true),
            createLabelValuePair("E.IoT", this.ESP_IOT_DATASOURCE),
        ]
        this.selectedDataSource = this.ESP_IOT_DATASOURCE
    }

    onClassificationSelected() {
        this.initChannels();
    }

    private initCustomers() {
        // display while loading data from API...
        this.customerGroupList = [{
            label: "Loading...",
            value: "",
            items: []
        }];
        this.backendService.getOcCustomers(this.selectedTenantId).subscribe(responseList => {
            let customerGroupList: LabelValuePairGroup[] = [];
            for (let customer of responseList) {
                let customerGroup = new LabelValuePairGroup();
                customerGroup.label = customer.name;
                customerGroup.value = customer.id;
                customerGroup.items = customer.siteList
                    .map(site => <LabelValuePair>{
                        label: site.name,
                        value: site.id
                    });
                customerGroupList.push(customerGroup);
            }
            this.customerGroupList = [...customerGroupList];

        });
    }

    private initClassification() {
        this.backendService.getOcClassifications().subscribe(response => {
            this.classificationList = response.map(item => <LabelValuePair>{
                label: item,
                value: item
            });
        })
    }

    private initChannels() {
        this.backendService.getOcChannels(this.selectedClassification).subscribe(response => {
            this.channelList = response.map(item => <LabelValuePair>{
                label: item,
                value: item
            });
        })
    }

    public confirm() {
        this.doSendData();

    }

    private doSendData() {
        const customerToSend = this.customerGroupList.find(customer => customer.items.findIndex(site => this.selectedSiteId == site.value) != -1);
        const site = customerToSend.items.find(site => site.value == this.selectedSiteId);
        const tenant = this.tenantList.find(tenant => this.selectedTenantId == tenant.value);

        let ocSite: OcSite = {
            id: site.value,
            name: site.label,
            customer: {
                id: customerToSend.value,
                name: customerToSend.label
            },
            tenant: {
                id: tenant.value,
                name: tenant.label
            },
            granularity: this.selectedGranularity,
            isAccumulator: TRUE_STR === this.selectedIsAccumulator,
            dataSource: this.selectedDataSource,
            classification: this.selectedClassification,
            channel: this.selectedChannel
        }
        this.selectedOcSite = ocSite;
        let dataPointIdList = this.dataPoints.map(dp => dp.id);

        this.backendService.updateDataPointsOcSite(dataPointIdList, ocSite).subscribe(value => {
            this.dataPoints = this.dataPoints.map(value => {
                let newDatapoint = value;
                newDatapoint.ocSite = this.selectedOcSite;
                return newDatapoint;
            });
            this.notificationService.addSuccessMessage('Add OC Tenant', 'OC Tenant successfully added');
            this.close();
        });

    }

    isDisabled() {
        return !(this.selectedChannel && this.selectedClassification && this.selectedSiteId && this.selectedTenantId);
    }

    close() {
        this.ref.close(this.dataPoints);
    }

    private initTenants() {
        this.backendService.getOcTenants().subscribe(tenantListresponse => {
            this.tenantList = tenantListresponse.map(tenant => createLabelValuePair(tenant.name, tenant.id));
        });
    }

    selectedTenantChanged($event: any) {
        if ($event == null) return;

        this.initCustomers();
    }

    private initGranularity() {
        this.selectedGranularity = CORE_GRANULARITY_15MIN
        this.granularityList = [
            createLabelValuePair("1 Min", CORE_GRANULARITY_1MIN),
            createLabelValuePair("5 Min", CORE_GRANULARITY_5MIN),
            createLabelValuePair("10 Min", CORE_GRANULARITY_10MIN),
            createLabelValuePair("15 Min", CORE_GRANULARITY_15MIN),
            createLabelValuePair("30 Min", CORE_GRANULARITY_30MIN),
            createLabelValuePair("1 Hour", CORE_GRANULARITY_HOUR),
            createLabelValuePair("1 Day", CORE_GRANULARITY_DAY),

        ]
    }
}
