import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/components/filter/filter.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { saveAs } from 'file-saver';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { DataService } from 'src/app/services/data/data.service';
import { UtilsService } from 'src/app/services/utils/util.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';

const REPORT_RUNNING_STATUS_CODE = 'RUNNING';

@Component({
    selector: 'cbms-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
    private readonly zeroPercentageText = '0';

    shouldDisableRunReportButton = false;

    //datapoint override report
    public overideReportDownloadDate = '';
    public overideReportRunningStatus = '';
    public overrideReportRequestInProgress = false;
    public overrideReportProgressPercentage: string = this.zeroPercentageText;

    //datapoint site report
    public siteConnectivityDownloadDate = '';
    public siteConnectivityStatus = '';
    public siteConnectivityRequestInProgress = false;
    public siteConnectivityProgressPercentage: string = this.zeroPercentageText;

    //datapoint device report
    public deviceConnectivityDownloadDate = '';
    public deviceConnectivityStatus = '';
    public deviceConnectivityRequestInProgress = false;
    public deviceConnectivityReportProgressPercentage = this.zeroPercentageText;

    private customerId = '';
    private customerName = '';

    //datapoint integrity report
    public datapointIntegrityReportStatus = '';
    public datapointIntegrityRequestInProgress = false;
    public datapointIntegrityReportDownloadDate = '';
    public datapointIntegrityReportProgressPercentage = this.zeroPercentageText;

    private reportrunningnotificationmessage =
        'Report is running. Status will be changed to "Completed" when finish.';
    private runReportCompleteMessage =
    'Report was run successfully with status “Completed”. Report is ready to be downloaded.';



    private readonly IN_PROGRESS_LABEL = 'In Progress';
    private readonly COMPLETED_LABEL = 'Completed';

    //datapoint faulty report
    public datapointFaultyReportStatus = '';
    public datapointFaultyRequestInProgress = false;
    public datapointFaultyReportDownloadDate = '';
    public datapointFaultyReportProgressPercentage = this.zeroPercentageText;
    public showFaultySensorReportProperties = false;

    outOfRangeLowCelsius = -20;
    outOfRangeHighCelsius = 120;
    outOfRangeLowRelativeHumidity = 0;
    outOfRangeHighRelativeHumidity = 100;
    selectedDayRange: { name: string; value: number } = { name: '3', value: 3 };

    dayRangeOptions: any[] = [
        { name: '1', value: 1 },
        { name: '2', value: 2 },
        { name: '3', value: 3 },
        { name: '4', value: 4 },
        { name: '5', value: 5 },
        { name: '6', value: 6 },
        { name: '7', value: 7 },
        { name: '8', value: 8 },
        { name: '9', value: 9 },
        { name: '10', value: 10 },
        { name: '11', value: 11 },
        { name: '12', value: 12 },
        { name: '13', value: 13 },
        { name: '14', value: 14 },
    ];

    showDatapointFaultyReportError = false;
    private readonly refreshReportStatusIntervalPeriod = 3000;
    runningIntervalIdArr = new Set<number>([]);
    private subscription: Subscription;

    constructor(
        private backendService: BackendService,
        private filterService: FilterService,
        private notificationService: NotificationService,
        private dataService: DataService,
        private utilsService: UtilsService
    ) {}

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('reports');
        let isInitialPageLoad = true;
        this.subscription = this.filterService.customerFilterChanges$.subscribe((data) => {
            this.customerId = data.selectedCustomer?.id;
            this.customerName = data.selectedCustomer?.name;
            this.getOverridesReportStatus(isInitialPageLoad);
            this.getSiteConnectivityReportStatus(isInitialPageLoad);
            this.getDeviceConnectivityReportStatus(isInitialPageLoad);
            this.getDatapointIntegrityReportStatus(isInitialPageLoad);
            this.getDatapointFaultyReportStatus(isInitialPageLoad);
        });
        if (_.isEmpty(this.customerId)) {
            this.getCurrentCustomer();
        }
        this.shouldDisableRunReportButton = !sessionStorage.getItem('userRolePermissionList').includes("RUN_REPORT_BUTTON.CORE_APP");

        this.getOverridesReportStatus(isInitialPageLoad);
        this.getDeviceConnectivityReportStatus(isInitialPageLoad);
        this.getSiteConnectivityReportStatus(isInitialPageLoad);
        this.getDatapointIntegrityReportStatus(isInitialPageLoad);
        this.getDatapointFaultyReportStatus(isInitialPageLoad);
    }

    getCurrentCustomer() {
        this.backendService.getCustomers().subscribe(customerList => {
            let selectedCustomer: {id, name};
			let customerArr = this.utilsService.orderListByFieldName(customerList.map(customer => { return { id: customer.id, name: customer.name }; }), 'name');
			this.backendService.getLastCustomer().subscribe(response => {
				if (_.isEmpty(response)) {
					selectedCustomer = customerArr[0];
				} else {
					selectedCustomer = response;
				}
                this.customerId = selectedCustomer.id;
                this.customerName = selectedCustomer?.name;
			})
		});
    }

    ngOnDestroy(): void {
        this.runningIntervalIdArr.forEach((intervalId: number) => clearInterval(intervalId));
        this.subscription.unsubscribe();
    }

    public runOverrideReport() {
        let isInitialPageLoad = false;
        this.backendService
            .generateOverridesReport(this.customerId)
            .subscribe((response) => {
                this.overideReportRunningStatus = this.IN_PROGRESS_LABEL;
                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );
                this.overrideReportRequestInProgress = true;
                this.overrideReportProgressPercentage = this.zeroPercentageText;
                this.getOverridesReportStatus(isInitialPageLoad);
            });
    }


    public exportOverridesReport() {
        this.overrideReportRequestInProgress = true;
        this.backendService.exportOverridesReport(this.customerId).subscribe(
            (response) => {
                saveAs(
                    response,
                    `${
                        this.customerName
                    }_Overrides_Report_${this.getDateNowAsText()}.csv`
                );
                this.overrideReportRequestInProgress = false;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    private getOverridesReportStatus(isInitialPageLoad: boolean) {
        let intervalId = window.setInterval(() => {
            this.backendService
            .getOverridesReportStatus(this.customerId)
            .subscribe((response) => {
                this.overideReportDownloadDate = response.status.lastSuccessfulReportDate;
                this.overrideReportRequestInProgress = this.isReportRunning(response);
                this.overrideReportProgressPercentage = response?.status?.progressPercentage;
                this.overideReportRunningStatus = this.remapStatusDescription(response?.status?.statusDescription);
                this.handleAfterGetingReportStatus(this.overideReportRunningStatus, intervalId, isInitialPageLoad);
            });
        }, this.refreshReportStatusIntervalPeriod);
    }

    private isReportRunning(response) {
        return response.status.status === REPORT_RUNNING_STATUS_CODE;
    }

    public runSiteConnectivityReport() {
        let isInitialPageLoad = false;
        this.backendService
            .generateSiteConnectivityReport(this.customerId)
            .subscribe((response) => {
                this.siteConnectivityStatus = this.IN_PROGRESS_LABEL
                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );
                this.siteConnectivityRequestInProgress = true;
                this.siteConnectivityProgressPercentage = this.zeroPercentageText;
                this.getSiteConnectivityReportStatus(isInitialPageLoad);
            });
    }

    public exportSiteConnectivityReport() {
        this.siteConnectivityRequestInProgress = true;
        this.backendService
            .exportSiteConnectivityReport(this.customerId)
            .subscribe(
                (response) => {
                    saveAs(
                        response,
                        `${
                            this.customerName
                        }_Connectivity_Report_${this.getDateNowAsText()}.csv`
                    );
                    this.siteConnectivityRequestInProgress = false;
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    private getSiteConnectivityReportStatus(isInitialPageLoad: boolean) {
        let intervalId = window.setInterval(() => {
            this.backendService
            .getSiteConnectivityReportStatus(this.customerId)
            .subscribe((response) => {
                this.siteConnectivityDownloadDate =
                    response.status.lastSuccessfulReportDate;
                this.siteConnectivityStatus = this.remapStatusDescription(response?.status?.statusDescription);
                this.siteConnectivityProgressPercentage = response?.status?.progressPercentage === null ? '0' : response?.status?.progressPercentage;
                this.siteConnectivityRequestInProgress = this.isReportRunning(response);
                this.handleAfterGetingReportStatus(this.siteConnectivityStatus, intervalId, isInitialPageLoad);
                });
        }, this.refreshReportStatusIntervalPeriod);

    }

    public runDeviceConnectivityReport() {
        let isInitialPageLoad = false;
        this.backendService
            .generateDeviceConnectivityReport(this.customerId)
            .subscribe((response) => {
                this.deviceConnectivityStatus = this.IN_PROGRESS_LABEL;
                this.deviceConnectivityRequestInProgress = true;
                this.deviceConnectivityReportProgressPercentage =
                    this.zeroPercentageText;
                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );
                this.getDeviceConnectivityReportStatus(isInitialPageLoad);
            });
    }

    public exportDeviceConnectivityReport() {
        this.deviceConnectivityRequestInProgress = true;
        this.backendService
            .exportDeviceConnectivityReport(this.customerId)
            .subscribe(
                (response) => {
                    saveAs(
                        response,
                        `${
                            this.customerName
                        }_Device_Connectivity_Report_${this.getDateNowAsText()}.csv`
                    );
                    this.deviceConnectivityRequestInProgress = false;
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    private getDeviceConnectivityReportStatus(isInitialPageLoad: boolean) {
        let intervalId = window.setInterval(() => {
            this.backendService
            .getDeviceConnectivityReportStatus(this.customerId)
            .subscribe((response) => {
                this.deviceConnectivityDownloadDate =
                    response.status.lastSuccessfulReportDate;
                this.deviceConnectivityStatus =
                    this.remapStatusDescription(response?.status?.statusDescription);
                this.deviceConnectivityRequestInProgress =
                    this.isReportRunning(response);
                this.deviceConnectivityReportProgressPercentage = response?.status?.progressPercentage;
                this.handleAfterGetingReportStatus(this.deviceConnectivityStatus, intervalId, isInitialPageLoad);
            });
        }, this.refreshReportStatusIntervalPeriod);
    }

    runDatapointIntegrityReport() {
        let isInitialPageLoad = false;
        this.backendService
            .runDatapointIntegrityReport(this.customerId)
            .subscribe((response) => {
                this.datapointIntegrityReportStatus = this.IN_PROGRESS_LABEL;
                this.datapointIntegrityRequestInProgress = true;
                this.datapointIntegrityReportProgressPercentage =
                    this.zeroPercentageText;

                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );
                this.getDatapointIntegrityReportStatus(isInitialPageLoad);
            });
    }

    runDatapointFaultyReport() {
        let isInitialPageLoad = false;
        this.showDatapointFaultyReportError = false;
        if (
            this.outOfRangeLowCelsius > this.outOfRangeHighCelsius ||
            this.outOfRangeLowRelativeHumidity >
                this.outOfRangeHighRelativeHumidity
        ) {
            this.showDatapointFaultyReportError = true;
            return;
        }

        this.backendService
            .runDatapointFaultyReport(this.customerId, {
                outOfRangeLowCelsius: this.outOfRangeLowCelsius,
                outOfRangeHighCelsius: this.outOfRangeHighCelsius,
                outOfRangeLowRelativeHumidity:
                    this.outOfRangeLowRelativeHumidity,
                outOfRangeHighRelativeHumidity:
                    this.outOfRangeHighRelativeHumidity,
                dayRange: this.selectedDayRange.value,
            })
            .subscribe((response) => {
                this.datapointFaultyReportStatus = this.IN_PROGRESS_LABEL;
                this.datapointFaultyRequestInProgress = true;
                this.datapointFaultyReportProgressPercentage =
                    this.zeroPercentageText;

                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );
                this.hideFaultySensorReportDialog();
                this.getDatapointFaultyReportStatus(isInitialPageLoad);
            });
    }

    exportDatapointIntegrityReport() {
        this.datapointIntegrityRequestInProgress = true;
        this.backendService
            .exportDatapointIntegrityReport(this.customerId)
            .subscribe(
                (response) => {
                    saveAs(
                        response,
                        `${
                            this.customerName
                        }_Datapoint_Integrity_Report_${this.getDateNowAsText()}.csv`
                    );
                    this.datapointIntegrityRequestInProgress = false;
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    exportDatapointFaultyReport() {
        this.datapointFaultyRequestInProgress = true;
        this.backendService
            .exportDatapointFaultyReport(this.customerId)
            .subscribe({
                next: (response) => {
                    saveAs(
                        response,
                        `${
                            this.customerName
                        }_Datapoint_Faulty_Report_${this.getDateNowAsText()}.csv`
                    );
                    this.datapointFaultyRequestInProgress = false;
                },
                error: (err) => {
                    console.log(err);
                },
            });
    }

    setDefaultProperties() {
        this.outOfRangeLowCelsius = -20;
        this.outOfRangeHighCelsius = 120;
        this.outOfRangeLowRelativeHumidity = 0;
        this.outOfRangeHighRelativeHumidity = 100;
        this.selectedDayRange = { name: '3', value: 3 };
    }

    runWithDefaultProperties() {
        this.setDefaultProperties();
        this.runDatapointFaultyReport();
    }

    hideFaultySensorReportDialog() {
        this.showFaultySensorReportProperties = false;
        this.showDatapointFaultyReportError = false;
        this.setDefaultProperties();
    }

    showFaultySensorReportDialog() {
        this.showFaultySensorReportProperties = true;
    }

    private getDateNowAsText() {
        let date = new Date();
        let time = `${date.getFullYear()}${
            date.getMonth() + 1
        }${date.getDate()}_${date.getHours()}${date.getMinutes()}`;
        return time;
    }

    private getDatapointIntegrityReportStatus(isInitialPageLoad: boolean) {
        let intervalId = window.setInterval(() => {
            this.backendService
                .getDatapointIntegrityReportStatus(this.customerId)
                .subscribe((response) => {
                    this.datapointIntegrityReportDownloadDate =
                        response.status.lastSuccessfulReportDate;
                    this.datapointIntegrityReportStatus = this.remapStatusDescription(response?.status?.statusDescription);
                    this.datapointIntegrityRequestInProgress =
                        this.isReportRunning(response);

                    this.datapointIntegrityReportProgressPercentage =
                        response?.status?.progressPercentage;
                    this.handleAfterGetingReportStatus(this.datapointIntegrityReportStatus, intervalId, isInitialPageLoad);
                });
        }, this.refreshReportStatusIntervalPeriod);
    }

    private getDatapointFaultyReportStatus(isInitialPageLoad: boolean) {
        let intervalId = window.setInterval(() => {
        this.backendService
            .getDatapointFaultyReportStatus(this.customerId)
            .subscribe((response) => {
                this.datapointFaultyReportDownloadDate =
                    response.status.lastSuccessfulReportDate;

                this.datapointFaultyReportStatus = this.remapStatusDescription(response?.status?.statusDescription);

                this.datapointFaultyRequestInProgress =
                    this.isReportRunning(response);

                this.datapointFaultyReportProgressPercentage =
                    response?.status?.progressPercentage;
                this.handleAfterGetingReportStatus(this.datapointFaultyReportStatus, intervalId, isInitialPageLoad);
            });
        }, this.refreshReportStatusIntervalPeriod);
    }

    remapStatusDescription(statusDescription: string) {
        if (statusDescription?.toLocaleLowerCase().includes('complete')) return this.COMPLETED_LABEL;
        return statusDescription;
    }

    handleAfterGetingReportStatus(status: string, intervalId: number, isInitialPageLoad: boolean) {
        if (status === this.COMPLETED_LABEL) {
            if (!isInitialPageLoad) {
                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.runReportCompleteMessage
                );
            }
            clearInterval(intervalId);
        } else {
            this.runningIntervalIdArr.add(intervalId);
        }
    }
}
