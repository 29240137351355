import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { LOGIN_IDP } from '../../config/constants';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    constructor(
        private authService: OAuthService,
        private cookieService: CookieService
    ) {}

    logout(): void {
        sessionStorage.removeItem('userRolePermissionList');
        this.clearLoginProviderCookie();
        this.authService.logOut();
    }

    clearLoginProviderCookie(): void {
        this.cookieService.delete(LOGIN_IDP, '/', `.${window.location.hostname}`);
    }
}
