<div class="content">
    <div class="cbms-spinner-wrapper" *ngIf="isLoadingDataPoints">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>

    <p-table
        *ngIf="!isLoadingDataPoints"
        class="cbms-generic-table-style"
        [value]="dataPoints"
        [columns]="cols"
        [resizableColumns]="true"
        [scrollable]="true"
        columnResizeMode="expand"
        [scrollHeight]="'180px'"
    >
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th
                    class="cbms-text-align-left"
                    *ngFor="let col of columns"
                    [pSortableColumn]="col.field"
                    [pSortableColumnDisabled]="!col.sortable"
                    pResizableColumn
                >
                    {{ col.header }}
                    <p-sortIcon
                        *ngIf="col.sortable"
                        [field]="col.field"
                        ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"
                    ></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dataPoint>
            <tr>
                <td class="ui-resizable-column">{{ dataPoint.siteName }}</td>
                <td class="ui-resizable-column">
                    {{ dataPoint.deviceName }} ({{ dataPoint.device.deviceId }})
                </td>
                <td class="ui-resizable-column">{{ dataPoint.objectName }}</td>
                <td class="ui-resizable-column">{{ dataPoint.description }}</td>
                <td class="ui-resizable-column">{{ dataPoint.customName }}</td>
                <td class="ui-resizable-column">
                    <div
                        class="cbms-api-response-feedback"
                        *ngIf="requestSent && !isLoading"
                    >
                        <div class="cbms-api-response-feedback__message">
                            <div class="cbms-api-response-feedback__icon">
                                <mat-icon
                                    class="cbms-yellow-color small"
                                    svgIcon="warning"
                                    *ngIf="!dataPoint.success"
                                ></mat-icon>
                                <mat-icon
                                    class="cbms-green-color small"
                                    svgIcon="success"
                                    *ngIf="dataPoint.success"
                                ></mat-icon>
                            </div>
                            <span>{{ dataPoint.result.message }}</span>
                        </div>
                    </div>
                    <mat-spinner
                        *ngIf="isLoading"
                        [diameter]="20"
                        class="cbms-spinner"
                    ></mat-spinner>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="footer">
    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
        pTooltip="Delete value from twin"
        showDelay="1000"
        hideDelay="500"
        (click)="deleteTwin()"
        [disabled]="requestSent || isLoadingDataPoints"
    >
        Delete Twin
    </button>
    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
        (click)="close()"
    >
        Close
    </button>
</div>
