import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataPoint } from 'src/app/models/data-point';
import { BackendService } from 'src/app/services/backend/backend.service';
import _ from 'lodash';
import { NotificationService } from '../../services/notification/notification.service';
import { Subscription } from 'rxjs';

enum ResultStatus {
    MATCHING = 1,
    NOT_MATCHING = 0,
    CANT_COMPARE_NO_TWIN = -1,
    GATEWAY_ERROR = -2
}

interface ScheduleDataPoint extends DataPoint {
    gatewayError: {
        code: string;
        message: string;
    }
    isMatching: boolean;
    result: ResultStatus;
}

@Component({
    selector: 'cbms-compare-datapoint-to-twin-dialog',
    templateUrl: './compare-datapoint-to-twin-dialog.component.html',
    styleUrls: ['./compare-datapoint-to-twin-dialog.component.scss']
})
export class CompareDatapointToTwinDialogComponent implements OnInit {

    public dataPoints: ScheduleDataPoint[] = [];
    public compareScheduleToTwinSubscription: Subscription;
    public isLoading = false;
    public isLoadingDataPoints = false;
    public requestSent = false;
    public hasError = false;

    public cols: any[] = [
        { field: 'siteName', header: 'Site', sortable: true, width: '' },
        { field: 'deviceName', header: 'Device', sortable: true, width: '' },
        { field: 'objectName', header: 'Name', sortable: true, width: '' },
        { field: 'description', header: 'Source Description', sortable: true, width: '' },
        { field: 'customName', header: 'Custom Name', sortable: true, width: '' },
        { field: 'result', header: 'Result', sortable: true, width: '' }
    ];

    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private backendService: BackendService,
        private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.isLoadingDataPoints = true;
        const filterParams = {
            dataPointIdFilter: Object.keys(this.config.data).map((key) => this.config.data[key])
        }
        const pageSize = filterParams.dataPointIdFilter.length;

        this.backendService.getDataPointsDetails(filterParams, 0, pageSize).subscribe(response => {
            this.dataPoints = response.content.map((dataPoint: ScheduleDataPoint) => {
                return Object.assign({}, dataPoint, { siteName: dataPoint.site.name, deviceName: dataPoint.deviceName, result: {} });
            });
            this.isLoadingDataPoints = false;
        });
    }

    public compare() {
        this.isLoading = true;
        this.requestSent = true;
        this.compareScheduleToTwinSubscription = this.backendService.compareDataPointToTwin(
                this.dataPoints.map(dataPoint => dataPoint.id)).subscribe(response => {
                    response.forEach(item => {
                        this.dataPoints.forEach(dataPoint => {
                            if (dataPoint.id === item.dataPointId) {
                                if (item.gatewayError) {
                                    dataPoint.gatewayError = item.gatewayError;
                                    dataPoint.result = item.gatewayError.code === 'FAIL' ? ResultStatus.GATEWAY_ERROR : ResultStatus.CANT_COMPARE_NO_TWIN;
                                } else {
                                    dataPoint.isMatching = item.isMatching;
                                    dataPoint.result = item.isMatching ? ResultStatus.MATCHING : ResultStatus.NOT_MATCHING;
                                }
                            }
                        });
                    });
                    this.isLoading = false;

                }, (err) => {
                    this.hasError = true;
                    this.isLoading = false;
                    this.notificationService.addErrorMessage('Compare Schedule To Twin', err);
                });
    }

    public close() {
        if (this.compareScheduleToTwinSubscription) {
            this.compareScheduleToTwinSubscription.unsubscribe();
        }
        this.ref.close();
    }

}
