<div class="content exception">
    <div class="cbms-spinner-wrapper" *ngIf="!isRequestDone">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>

    <div class="grid fixed-height-600" *ngIf="isRequestDone">
        <div [class]="{'col-8':!isReadonly,
                       'col-12':isReadonly }">
            <div class="grid cbms-align-center">
                <div class="col-8 template-heading">
                    <h3><span>Template Name:</span> <input [disabled]="isReadonly" class="cbms-margin-left" pInputText minlength="2" maxlength="255" [(ngModel)]="template.name" #templateNameRef="ngModel"></h3>
                </div>
                <div class="col-4">
                    <p-message severity="error" text="Template name is required!"
                        *ngIf="!templateNameRef.value || templateNameRef.invalid && (templateNameRef.dirty || templateNameRef.touched)">
                    </p-message>
                </div>
            </div>
            <button *ngIf="!isReadonly" pButton class="p-button-raised p-button-text button cbms-button margin-bottom" (click)="addEvent()"
                [disabled]="isEditingEvent">
                <mat-icon class="button-icon" svgIcon="plus"></mat-icon>
                <span class="cbms-small-margin-left">Add Event</span>
            </button>
            <p-table class="cbms-nested-tables-style" #eventsTable [columns]="mainTableCols"
                [value]="template.exceptionScheduleObject" dataKey="id" sortField="priority" sortOrder="1"
                [scrollable]="true" [scrollHeight]="'450px'" selectionMode="single" [(selection)]="selectedEvent"
                (onRowSelect)="onEventSelect($event)" (onRowUnselect)="onEventUnselect()">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th class="button-column"></th>
                        <th *ngFor="let col of columns" style="flex: {{col.header === 'Actions' ? '0 0 80px !important': ''}}">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                    <tr [pSelectableRow]="rowData" pTooltip="To select or unselect a period or event, click on it">
                        <td class="button-column">
                            <a href="#" [pRowToggler]="rowData">
                                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                            </a>
                        </td>
                        <td *ngFor="let col of columns" style="flex: {{col.header === 'Actions' ? '0 0 80px !important': ''}}">
                            <ng-container *ngIf="col.header === 'Actions'">
                                <button [disabled]="isReadonly" pButton class="p-button-raised p-button-text cbms-row-button center_aligned" 
                                    (click)="deleteEvent($event,rowData.id)" pTooltip="Delete event" showDelay="1000"
                                    hideDelay="500">
                                    <mat-icon svgIcon="delete"></mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="col.header !== 'Actions';">
                                {{rowData[col.field]}}
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                    <tr>
                        <td [attr.colspan]="columns.length + 1" style="padding:20px 39px; background-color: white;">
                            <div class="flex-item">
                                <p><strong>Period:</strong></p>
                                <button *ngIf="!isReadonly" pButton class="p-button-raised p-button-text cbms-button button margin-bottom-10 cbms-margin-left-auto"
                                     (click)="addPeriod(rowData)" [disabled]="isEditingPeriod">
                                    <mat-icon class="button-icon" svgIcon="plus"></mat-icon>
                                    <span class="cbms-small-margin-left">Add Period</span>
                                </button>
                            </div>
                            <p-table [columns]="subTableCols" [value]="rowData.timeValues" selectionMode="single"
                                [(selection)]="selectedPeriod" (onRowSelect)="onPeriodSelect($event, rowData)"
                                (onRowUnselect)="onPeriodUnselect()">
                                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="text-align:left" *ngFor="let col of columns"
                        style="flex: {{col.header === 'Actions' ? '0 0 80px !important': ''}}">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-columns="columns">
                    <tr [pSelectableRow]="data">
                        <td *ngFor="let col of columns" style="flex: {{col.header === 'Actions' ? '0 0 80px !important': ''}}">
                            <ng-container *ngIf="col.header === 'Actions'">
                                <button *ngIf="!isReadonly" pButton class="p-button-raised p-button-text cbms-row-button center_aligned" 
                                    (click)="deletePeriod($event, data.id,rowData.id)" pTooltip="Delete event"
                                    showDelay="1000" hideDelay="500">
                                    <mat-icon svgIcon="delete"></mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="col.header !== 'Actions';">
                                {{data[col.field]}}
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            </td>
            </tr>
            </ng-template>
            </p-table>
            <div class="cbms-spinner-wrapper">
                <p *ngIf="!template.exceptionScheduleObject">No events defined</p>
            </div>
        </div>
        <div class="col-4">
            <form *ngIf="!isReadonly" [hidden]="!showEventForm" class="form-wrapper"
                [formGroup]="saveExceptionEventForm" (ngSubmit)="saveEvent()">
                <h3>Add/edit event</h3>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>Event name</span>
                    </div>
                    <div class="col-7">
                        <input class="full-width period-value" pInputText type="text" formControlName="eventName">
                    </div>
                </div>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>Event type</span>
                    </div>
                    <div class="col-7">
                        <p-dropdown class="full-width" [options]="eventTypeList" formControlName="selectedEventType">
                        </p-dropdown>
                    </div>
                </div>
                <ng-container [ngSwitch]="selectedEventType.value">
                    <ng-container *ngSwitchCase="'date'">
                        <div class="grid cbms-align-center">
                            <div class="col-3">
                                <span>Date</span>
                            </div>
                            <div class="col-7">
                                <p-dropdown class="one-third" [ngClass]="{'error': showInvalidDateError.singleDay}" [options]="dayList" [(ngModel)]="singleDay.selectedDay" [ngModelOptions]="{standalone: true}">
                                </p-dropdown>
                                <p-dropdown class="one-third" [ngClass]="{'error': showInvalidDateError.singleDay}" [options]="monthList" [(ngModel)]="singleDay.selectedMonth" [ngModelOptions]="{standalone: true}">
                                </p-dropdown>
                                <p-dropdown class="one-third no-margin" [ngClass]="{'error': showInvalidDateError.singleDay}" [options]="yearList" [(ngModel)]="singleDay.selectedYear" [ngModelOptions]="{standalone: true}">
                                </p-dropdown>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dateRange'">
                        <div class="grid cbms-align-center">
                            <div class="col-3">
                                <span>Start Date</span>
                            </div>
                            <div class="col-7">
                                <p-dropdown class="one-third" [ngClass]="{'error': showInvalidDateError.rangeStartDate || showInvalidRangeError}" [options]="dayList" [(ngModel)]="dateRangeStartDate.selectedDay" [ngModelOptions]="{standalone: true}">
                                </p-dropdown>
                                <p-dropdown class="one-third" [ngClass]="{'error': showInvalidDateError.rangeStartDate || showInvalidRangeError}" [options]="monthList" [(ngModel)]="dateRangeStartDate.selectedMonth" [ngModelOptions]="{standalone: true}">
                                </p-dropdown>
                                <p-dropdown class="one-third no-margin" [ngClass]="{'error': showInvalidDateError.rangeStartDate || showInvalidRangeError}" [options]="yearList" [(ngModel)]="dateRangeStartDate.selectedYear" [ngModelOptions]="{standalone: true}">
                                </p-dropdown>                              
                            </div>
                        </div>
                        <div class="grid cbms-align-center">
                            <div class="col-3">
                                <span>End Date</span>
                            </div>
                            <div class="col-7">
                                <p-dropdown class="one-third" [ngClass]="{'error': showInvalidDateError.rangeEndDate || showInvalidRangeError}" [options]="dayList" [(ngModel)]="dateRangeEndDate.selectedDay" [ngModelOptions]="{standalone: true}">
                                </p-dropdown>
                                <p-dropdown class="one-third" [ngClass]="{'error': showInvalidDateError.rangeEndDate || showInvalidRangeError}" [options]="monthList" [(ngModel)]="dateRangeEndDate.selectedMonth" [ngModelOptions]="{standalone: true}">
                                </p-dropdown>
                                <p-dropdown class="one-third no-margin" [ngClass]="{'error': showInvalidDateError.rangeEndDate || showInvalidRangeError}" [options]="yearList" [(ngModel)]="dateRangeEndDate.selectedYear" [ngModelOptions]="{standalone: true}">
                                </p-dropdown>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'calendarReference'">
                        <div class="grid cbms-align-center">
                            <div class="col-3">
                                <span>Calendar</span>
                            </div>
                            <div class="col-7">
                                <input *ngIf="saveExceptionEventForm.controls['eventCalendarRef']"
                                    class="full-width period-value" pInputText type="text"
                                    formControlName="eventCalendarRef">
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'weekNDay'">
                        <div class="grid cbms-align-center"
                            *ngIf="saveExceptionEventForm.controls['selectedEventDay']">
                            <div class="col-3">
                                <span>Week day</span>
                            </div>
                            <div class="col-7">
                                <p-dropdown class="full-width" [options]="weekDayList"
                                    [formControl]="saveExceptionEventForm.controls['selectedEventDay']">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="grid cbms-align-center"
                            *ngIf="saveExceptionEventForm.controls['selectedEventWeek']">
                            <div class="col-3">
                                <span>Week</span>
                            </div>
                            <div class="col-7">
                                <p-dropdown class="full-width" [options]="weekList"
                                    [formControl]="saveExceptionEventForm.controls['selectedEventWeek']">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="grid cbms-align-center"
                            *ngIf="saveExceptionEventForm.controls['selectedEventMonth']">
                            <div class="col-3">
                                <span>Month</span>
                            </div>
                            <div class="col-7">
                                <p-dropdown class="full-width" [options]="monthList"
                                    [formControl]="saveExceptionEventForm.controls['selectedEventMonth']">
                                </p-dropdown>
                            </div>
                        </div>
                    </ng-container>

                </ng-container>

                <div class="grid cbms-align-center margin-bottom">
                    <div class="col-3">
                        <span>Event priority</span>
                    </div>
                    <div class="col-7">
                        <input class="full-width period-value" pInputText type="number" step="1"
                            formControlName="eventPriority">
                    </div>
                </div>
                <div class="grid cbms-align-center">
                    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" type="submit"
                        [disabled]="!saveExceptionEventForm.valid || isLoading">Apply</button>
                    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" type="button"
                        (click)="resetSaveExceptionEventForm()">Reset</button>
                </div>
            </form>

            <div>
                <p *ngIf="showInvalidDateError.singleDay || showInvalidDateError.rangeStartDate || showInvalidDateError.rangeEndDate" class="error">Date is not valid!</p>
                <p *ngIf="showInvalidRangeError"  class="error">Date range is not valid. Start date must be before end date.</p>
            </div>

            <form *ngIf="!isReadonly" [hidden]="!showPeriodForm" class="form-wrapper" [formGroup]="saveEventPeriodForm"
                (ngSubmit)="savePeriod()">
                <h3>Schedule Options</h3>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>Signal type *</span>
                    </div>
                    <div class="col-7">
                        <p-dropdown data-cy="signal-type-dd" class="full-width" [options]="signalTypeList" formControlName="selectedSignalType">
                        </p-dropdown>
                    </div>
                </div>

                <h3>Add/edit period</h3>
                <p>Click on a period to edit it.<br />Overlapping periods not allowed.</p>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>All day</span>
                    </div>
                    <div class="col-7">
                        <p-checkbox [binary]="true" [formControl]="allDayPeriod">
                        </p-checkbox>
                    </div>
                </div>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>Start Time *</span>
                    </div>
                    <div class="col-7">
                        <p-calendar class="full-width"
                            [ngClass]="{'cbms-form-input-error': saveEventPeriodForm.errors && saveEventPeriodForm.errors.startTime}"
                            [timeOnly]="true" hourFormat="24" formControlName="startTime">
                        </p-calendar>
                    </div>
                </div>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>Stop Time *</span>
                    </div>
                    <div class="col-7">
                        <p-calendar class="full-width"
                            [ngClass]="{'cbms-form-input-error': saveEventPeriodForm.errors && saveEventPeriodForm.errors.stopTime}"
                            [timeOnly]="true" hourFormat="24" formControlName="stopTime">
                        </p-calendar>
                    </div>
                </div>
                <div class="grid cbms-align-center margin-bottom">
                    <div class="col-3">
                        <span>Value *</span>
                    </div>
                    <div class="col-7" [ngClass]="{'cbms-form-input-error': saveEventPeriodForm.errors && saveEventPeriodForm.errors.periodValue}">
                        <input data-cy="period-input" *ngIf="saveEventPeriodForm.controls['selectedSignalType'].value !== 'Binary'" class="full-width period-value" pInputText type="text" formControlName="periodValue">
                        <p-dropdown data-cy="period-dd" *ngIf="saveEventPeriodForm.controls['selectedSignalType'].value === 'Binary'" class="full-width period-value" [options]="binaryTypeList" formControlName="periodValue"></p-dropdown>
                    </div>
                </div>

                <div class="grid cbms-align-center" *ngIf="saveEventPeriodForm.errors && saveEventPeriodForm.errors.periodValue">
                    <div class="col-12 cbms-form-input-error">
                        <p>{{saveEventPeriodForm.errors.periodValue}}</p>
                    </div>
                </div>

                <div class="grid cbms-align-center">
                    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" class="cbms-button" type="submit" label="Apply"
                        [disabled]="!saveEventPeriodForm.valid || isLoading"></button>
                    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" type="button" label="Reset"
                        (click)="resetSaveEventPeriodForm()"></button>
                </div>
            </form>

        </div>
    </div>
</div>

<div class="footer">
    <button *ngIf="!isReadonly" pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" label="Save" [disabled]="!template.name || template.name?.length < 2 || template.name?.length > 255" data-cy="save-exception-schedule-btn"
        (click)="saveExceptionScheduleChanges()"></button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" label="Close" (click)="close()"></button>
</div>