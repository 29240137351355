import { SelectItem } from 'primeng/api';

export const PAGINATION = {
    SIZE: 100,
    DEFAULT_PAGE_NR: 0
};
export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_1000 = 1000;
export const PAGE_SIZE_10k = 10000;
export const PAGE_SIZE_100k = 100000;

export class DatapointsScan {
    static readonly DATAPOINTS_SCAN_STATUS_MILISECOND_INTERVAL = 5000;
}

export const TOTAL_NR_OF_DATAPOINTS_TO_ANALYZE: number = 20;
export const NO_OF_TAGS_TO_FETCH = 100000;

export const SORT_ORDER_DESC = -1;
export const SORT_ORDER_ASC = 1;

export enum FieldList {
    POLLED = 'Polled',
    SITE_ASSIGNED = 'Site Assigned',
    NAME = 'Name',
    TYPE = 'IO-Type',
    SIGNAL_TYPE = 'Signal Type',
    SOURCE = 'Source',
    SOURCE_DESCRIPTION = 'Source Description',
    CUSTOM_NAME = 'Custom Name',
    UNITS = 'Units',
    LAST_RECORDED_VALUE = 'Last recorded value',
    DATA_POINT_INTERNAL_ID = 'Datapoint ID',
    WISEMETER_ID = 'ID',
    INSTANCE = 'Instance',
    CLASSIFICATION = 'Classification',
    TWIN_VALUE = 'Twin Value',
}

export enum TimeFramesValues {
    TODAY = 0,
    YESTERDAY = 1,
    LAST_7_DAYS = 7,
    LAST_30_DAYS = 30,
    LAST_365_DAYS = 365
}

export const UNDERSCORE_SEPARATOR = '_';

// export const COLOR_LIST = [
//     '#EA1C0A', //eon-red
//     '#008000', //eon-green
//     '#1EA2B1', //eon-turquoise
//     '#B00402', //eon-bordeaux
//     '#000000', //eon-black
//     '#E3E000', //eon-limeyellow
//     '#D51607', //eon-red-active
//     '#2B91A3', //eon-turqoise-active
//     '#A00311', //eon-bordeaux-active
//     '#DAD600', //eon-limeyellow-active
//     '#f05548', //eon-red-75
//     '#76020C', //eon-bordeaux-dark
//     '#0F738A', //eon-turquoise-dark
//     '#8ed0d8', //$eon-turquoise-50
//     '#eae840', //eon-limeyellow-75
//     '#d78180', //eon-bordeaux-50,
//     '#f48D84', //eon-red-50
//     '#56b9c5', //eon-turquoise-75
//     '#f1ef7f', //eon-limeyellow-50
//     '#404040', //$eon-darkgrey-active;
// ];

export const COLOR_LIST = [
    "#1EA2B1", "#EA1C0A", "#E3E000", "#B00402", "#0F738A", "#76020C", "#450096", "#1797E8", "#9B9B92", "#FB7D10", "#31B029",
    "#B5ECF2", "#FAA49D", "#FEFECC", "#FD9B9A", "#B1E9F6", "#FD9AA3", "#E3CCFE", "#B1DCF7", "#E6E6E4", "#FCAD68", "#D7F5D5",
    "#8BE1EB", "#F8796E", "#FFFD99", "#FD6B69", "#85DDF1", "#FC6A77", "#C799FF", "#84C8F2", "#CECEC9", "#FB9A45", "#B0EBAC",
    "#62D7E4", "#F64E3F", "#FFFC66", "#FC3B39", "#59D1ED", "#FB3A4B", "#AC66FF", "#57B4EE", "#B5B5AF", "#FB8622", "#88E282",
    "#38CCDD", "#F42210", "#FFFC33", "#FC0B08", "#2DC5E8", "#FA0A1F", "#9033FF", "#2BA0EA", "#9D9D94", "#F57404", "#61D859",
    "#21B2C3", "#CB1808", "#FFFB00", "#D10402", "#16ABCE", "#D00315", "#7500FF", "#1487CF", "#84847A", "#D26303", "#39CE30",
    "#1A8C99", "#9C1206", "#CCC900", "#A10301", "#1186A1", "#A00210", "#5D00CC", "#106AA3", "#6A6A61", "#AF5302", "#2EA526",
    "#12666F", "#6D0D04", "#999600", "#700201", "#0C6275", "#70010B", "#460099", "#0B4D76", "#4F4F49", "#8C4202", "#227C1C",
    "#0B3F45", "#3E0702", "#666400", "#400100", "#083D49", "#400106", "#2E0066", "#07304A", "#353530", "#693101", "#175213",
];

export const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    RED: '#EA1C0A',
    TURQOISE: '#1EA2B1',
    LIME_YELLOW: '#E3E000'
};

export const ANY = 'Any';

export enum Months {
    Any = 0,
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12
}

export enum DayOfWeek {
    Any = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}

export const MONTH_LIST: SelectItem[] = [
    { label: 'Month', value: '' },
    { label: Months[0], value: Months.Any },
    { label: Months[1], value: Months.January },
    { label: Months[2], value: Months.February },
    { label: Months[3], value: Months.March },
    { label: Months[4], value: Months.April },
    { label: Months[5], value: Months.May },
    { label: Months[6], value: Months.June },
    { label: Months[7], value: Months.July },
    { label: Months[8], value: Months.August },
    { label: Months[9], value: Months.September },
    { label: Months[10], value: Months.October },
    { label: Months[11], value: Months.November },
    { label: Months[12], value: Months.December },
];

export const WEEK_OF_MONTH_TEXT = {
    0: 'Any',
    1: 'Week1',
    2: 'Week2',
    3: 'Week3',
    4: 'Week4',
    5: 'Week5',
    6: 'Last week of month',
    7: '2nd last week of the month',
    8: '3rd last week of the month',
    9: '4th last week of the month'
};

export const SUCCESS_CREATED_OPERATION = ' has been successfully created.';
export const SUCCESS_UPDATED_OPERATION = ' has been successfully updated.';
export const SUCCESS = 'Success';
export const WARNING = 'Warning';

export enum ViewMode {
    EDIT_MODE = 'EDIT_MODE',
    READ_ONLY_MODE = 'READ_ONLY_MODE'
}

export const ExternalSource = {
    BACKNET: 'CC',
    WISEMETER: 'WiseMeter'

};

export const TRUE = 'true';
export const ANALOG = 'Analog';
export const BINARY = 'Binary';
export const MULTISTATE = 'MultiState';
export const MANUAL_DATAPOINTS_TABLE_SIZE = 'cbms.datapoints.page.size';

export const KPI = {
    'Boiler': ['Hot_Water_Supply_Temperature_Sensor', 'Hot_Water_Return_Temperature_Sensor', 'On_Off_Command', 'On_Off_Status', 'Maintenance_Required_Alarm', 'Supply_Hot_Water_Temperature_Setpoint'],
    'Chiller': ['Chilled_Water_Supply_Temperature_Sensor', 'Chilled_Water_Return_Temperature_Sensor', 'On_Off_Command', 'On_Off_Status', 'Maintenance_Required_Alarm', 'Supply_Chilled_Water_Temperature_Setpoint'],
    'Luminaire': ['On_Off_Command'],
    'Lighting': ['On_Off_Command'],
    'Air_Handling_Unit': ['Supply_Air_Temperature_Sensor', 'Return_Air_Temperature_Sensor', 'Supply_Air_Humidity_Sensor', 'Return_Air_Humidity_Sensor', 'Zone_Air_Temperature_Setpoint', 'CO2_Setpoint', 'Setpoint'],
    'Exhaust_Fan': ['On_Off_Command', 'Fan_On_Off_Status', 'Overload_Alarm', 'Setpoint'],
    'Supply_Fan': ['On_Off_Command', 'Fan_On_Off_Status', 'Overload_Alarm'],
    'Outside_Damper': ['Damper_Command', 'Damper_Position_Sensor'],
    'Exhaust_Damper': ['Damper_Command', 'Damper_Position_Sensor'],
    'Mixed_Damper': ['Damper_Command', 'Damper_Position_Sensor'],
    'Pre_Filter': ['Pre_Filter_Status'],
    'Hot_Water_Valve': ['Valve_Command', 'Valve_Position_Sensor'],
    'Chilled_Water_Valve': ['Valve_Command', 'Valve_Position_Sensor']
};

export enum ENV_NAME {
    PROD = 'PROD',
    DEV = 'DEV',
    LOCAL = 'LOCAL'
}

export const AAD_B2C = {
    'DEV': {
        clientId: '093651c6-fb00-4c22-999e-ab38f8244419',
        aadAuthority: 'https://eonemsb2c.b2clogin.com/login.dev-qa.eon-optimum.com/B2C_1_connect',
        resourceScope: 'https://login.dev-qa.eon-optimum.com/candc/connect.control',
        knownAuthorities: 'eonemsb2c.b2clogin.com',
        postLogoutRedirectUri: 'https://dev.cbms.eon-optimum.com'
    },
    'PROD': {
        clientId: '0bb0d8f7-d6b2-4f87-b079-4ce15f030538',
        aadAuthority: 'https://optimumprodb2c.b2clogin.com/optimumprodb2c.onmicrosoft.com/B2C_1_Connect',
        resourceScope: 'https://optimumprodb2c.onmicrosoft.com/candc/connect.control',
        knownAuthorities: 'optimumprodb2c.b2clogin.com',
        postLogoutRedirectUri: 'https://cbms.eon-optimum.com'
    }
};

export const AUTH0_CONFIG = {
    'DEV': {
        issuer: 'https://login.dev.eon-optimum.com',
        redirectUri: window.location.origin,
        clientId: 'LqyW08BevUCrSvNHs8hwCGwK6hwD9qhU',
        scope: 'openid name email offline_access',
        responseType: 'code',
        requestAccessToken: false,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
    },
    'PROD': {
        issuer: 'https://login.eon-optimum.com',
        redirectUri: window.location.origin,
        clientId: 'lOmEO7dGVfVON7ZuFSzHedcENqmsgIMw',
        scope: 'openid name email offline_access',
        responseType: 'code',
        requestAccessToken: false,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
    }
}

export const TESCO_CONFIG = {
    DEV: {
        issuer: 'https://loginppe.ourtesco.com/oidc/2',
        redirectUri: window.location.origin,
        clientId: '48f8a9b0-c11d-013d-6409-09e32d64717337548',
        scope: 'openid name profile groups email params',
        responseType: 'code',
        requestAccessToken: true,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
        dummyClientSecret: '3ee121c5d39221dcb944c1c52b2d0ef4896b7ca7f66e2ec5b3f542bf2d744c20',
        logoutUrl: 'https://loginppe.ourtesco.com/',
        postLogoutRedirectUri: window.location.origin,
    },
    PROD: {
        issuer: 'https://login.ourtesco.com/oidc/2',
        redirectUri: window.location.origin,
        clientId: '7827aa00-e56c-013d-5d7e-03bd7279b33337547',
        scope: 'openid name profile groups email params',
        responseType: 'code',
        requestAccessToken: true,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
        dummyClientSecret: '0b839dc88e0f8392003f8ebd58e20b80e0650b31efaee4ca42700eef65089ecd',
        logoutUrl: 'https://login.ourtesco.com/',
        postLogoutRedirectUri: window.location.origin,
    },
};

export const LOGIN_IDP = 'loginIdp';
export enum IDP_PROVIDER {
    AUTH0 = "auth0",
    TESCO_CC = "tesco-cc",
    TESCO_DASHBOARDS = "tesco-dashboards",
}

export const OPERATION_TYPE_CREATE = 'CREATE';
export const OPERATION_TYPE_UPDATE = 'UPDATE';

export const enum DATAPOINTS_OP {
    MANAGE_VALUES = 'manageValues',
    WRITE_TWIN_TO_DATAPOINT = 'writeTwinToDatapoint'
}

export const VPN_LIST = [
    {label: 'Test VPN_1', value: '1'},
    {label: 'Test VPN_2', value: '2'},
    {label: 'Test VPN_3', value: '3'},
    {label: 'Test VPN_4', value: '4'}
];

export const enum ASSIGN_BY {
    DEVICES = 'devices',
    DATA_POINTS = 'dataPoints'
}

export const NO_GLOBAL_FILTERS_TABS = ['gateways', 'devices', 'actionLog'];
export const NO_GLOBAL_SITE_FILTERS_TABS = ['userManagement', 'gateways', 'devices', 'reports', 'actionLog', 'alertConsole', 'smartAlert', 'dashboard', 'smartAlertTemplates'];

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const STATUS_FLAGS = ['In Alarm', 'Fault', 'Overridden', 'Out of Service'];

export const PRIORITY_ARRAY = [
    '1 (Manual Life Safety)',
    '2 (Automatic Life Safety)',
    '3 (Not Named)',
    '4( Not Named)',
    '5 (Critical Equipment Control)',
    '6 (Minimum On/Off)',
    '7 (Not Named)',
    '8 (Manual Operator)',
    '9 (Not Named)',
    '10 (Not Named)',
    '11 (Not Named)',
    '12 (Not Named)',
    '13 (Not Named)',
    '14 (Not Named)',
    '15 (Not Named)',
    '16 (Not Named)',
];

export const PROPERTIES_ORDER = {
    object_name: 1,
    description: 2,
    present_value: 3,
    inactive_text: 4,
    active_text: 5,
    units: 6,
    state_text: 7,
    priority_array: 8,
    relinquish_default: 9,
    weekly_schedule: 10,
    exception_schedule: 11,
    status_flags: 12,
    out_of_service: 13,
    reliability: 14,
    other: 15,
};

export const MINUTES = 'minutes';

export const DAYS = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
];
