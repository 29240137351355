<p-table
    data-cy="data-points-table"
    class="cbms-generic-table-style"
    [columns]="selectedColumns"
    [value]="mappedDataPoints"
    [resizableColumns]="true"
    (onSort)="onSort($event)"
    [lazy]="true"
    [loading]="isLoading"
    [lazyLoadOnInit]="false"
    [scrollable]="true"
    scrollHeight="flex"
    sortMode="multiple"
    [multiSortMeta]="multiSortMeta"
    (onLazyLoad)="loadDPLazy($event)"
    [paginator]="true"
    columnResizeMode="expand"
    [totalRecords]="totalRecords"
    [rows]="rows"
    (onPage)="onPage($event)"
    stateStorage="session"
    stateKey="{{ tabName }}-session"
>
    <ng-template pTemplate="caption">
        <div class="caption-wrapper">
            <div style="text-align: left">
                <p-multiSelect
                    [options]="uiCols"
                    [(ngModel)]="selectedColumns"
                    optionLabel="header"
                    (ngModelChange)="selectedColumnsHaveChanged($event)"
                    selectedItemsLabel="{0} columns selected"
                    [style]="{ minWidth: '200px' }"
                    placeholder="Choose Columns"
                >
                </p-multiSelect>
            </div>
            <div *ngIf="isSelectedDatapointMode" class="cbms-margin-left-auto">
                <button pButton
                    *ngIf="actionConfig.syncWithOC || actionConfig.assignOC"
                    (click)="op.toggle($event)"
                    label="OC Actions"
                    class="p-button-raised p-button-text cbms-button cbms-margin-left"
                    ></button>
                <p-overlayPanel #op styleClass="table-actions-overlay">
                    <div *ngIf="actionConfig.syncWithOC">
                        <cbms-sync-with-oc-action
                            [selectedDataPoints]="selectedDataPoints"
                        >
                        </cbms-sync-with-oc-action>
                    </div>
                    <div *ngIf="actionConfig.assignOC">
                        <cbms-assign-oc-tenant-action
                            [selectedDataPoints]="selectedDataPoints"
                            (updateOcSiteEvent)="updateDatapointOcSite($event)"
                        >
                        </cbms-assign-oc-tenant-action>
                    </div>
                </p-overlayPanel>

                <button pButton
                    (click)="op1.toggle($event)"
                    label="Twins Actions"
                    class="p-button-raised p-button-text cbms-button cbms-margin-left"
                ></button>
                <p-overlayPanel #op1 styleClass="table-actions-overlay">
                    <div *ngIf="actionConfig.copyGroup">
                        <cbms-copy-action
                            [selectedDataPoints]="selectedDataPoints"
                            [copySource]="selectedDataPoint"
                        >
                        </cbms-copy-action>
                    </div>
                    <div *ngIf="actionConfig.createTwinFromDatapoint">
                        <cbms-create-twin-from-datapoint-action
                            [selectedDataPoints]="selectedDataPoints"
                            (updateTwinEvent)="
                                updateDatapointTwinCreation($event)
                            "
                        >
                        </cbms-create-twin-from-datapoint-action>
                    </div>
                    <div *ngIf="actionConfig.compareDataPointToTwin">
                        <cbms-compare-datapoint-to-twin-action
                            [selectedDataPoints]="selectedDataPoints"
                        >
                        </cbms-compare-datapoint-to-twin-action>
                    </div>
                    <div *ngIf="actionConfig.writeTwinToDatapoint">
                        <cbms-write-twin-to-datapoint
                            [selectedDataPoints]="selectedDataPoints"
                        >
                        </cbms-write-twin-to-datapoint>
                    </div>
                    <div *ngIf="actionConfig.createTwinFromSchedule">
                        <cbms-create-twin-from-schedule-action
                            [selectedDataPoints]="selectedDataPoints"
                            (updateTwinEvent)="updateTwinCreation($event)"
                        ></cbms-create-twin-from-schedule-action>
                    </div>
                    <div *ngIf="actionConfig.compareScheduleToTwin">
                        <cbms-compare-schedule-to-twin-action
                            [selectedDataPoints]="selectedDataPoints"
                        >
                        </cbms-compare-schedule-to-twin-action>
                    </div>
                    <div *ngIf="actionConfig.writeTwinToSchedule">
                        <cbms-write-twin-to-schedule-action
                            [selectedDataPoints]="selectedDataPoints"
                        >
                        </cbms-write-twin-to-schedule-action>
                    </div>
                </p-overlayPanel>

                <button pButton
                    *ngIf="tabName === 'dataPoints'"
                    (click)="op2.toggle($event)"
                    label="Data Points Actions"
                    class="cbms-margin-left"
                    class="p-button-raised p-button-text cbms-button cbms-margin-left"
                ></button>
                <p-overlayPanel #op2 styleClass="table-actions-overlay" appendTo="body">
                    <div
                        *ngIf="actionConfig.watchGroup"
                    >
                        <cbms-watch-action
                            [selectedDataPoints]="selectedDataPoints"
                        ></cbms-watch-action>
                    </div>
                    <div
                        *ngIf="actionConfig.editTagsGroup"
                    >
                        <cbms-edit-tags-action
                            [selectedDataPoints]="selectedDataPoints"
                            (updatedDataPointsEvent)="
                                updateDataPointsTags($event)
                            "
                        ></cbms-edit-tags-action>
                    </div>
                    <div
                        *ngIf="actionConfig.setpointChangeGroup"
                    >
                        <cbms-setpoint-change-action
                            [selectedDataPoints]="selectedDataPoints"
                            isGlobalAction="true"
                        >
                        </cbms-setpoint-change-action>
                    </div>
                    <div
                        *ngIf="actionConfig.analysisGroup"
                    >
                        <cbms-analysis-action
                            [selectedDataPoints]="selectedDataPoints"
                            isGlobalAction="true"
                        >
                        </cbms-analysis-action>
                    </div>
                </p-overlayPanel>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="flex: 0 0 3rem !important; min-width: 3rem">
                <p-checkbox
                    class="right-margin"
                    [(ngModel)]="allDPSelected"
                    binary="true"
                    (click)="toggleSelectedDP($event)"
                ></p-checkbox>
            </th>
            <th style="flex: 0 0 190px !important; min-width: 190px">
                Actions
            </th>
            <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.field"
                [pSortableColumnDisabled]="!col.sortable"
                pResizableColumn
                style="flex-basis: {{ col.width }}; min-width: {{ col.width }}"
            >
                <div class="cbms-disply-flex-row-centered">
                    <span>{{ col.header }}</span>
                    <span>
                        <p-sortIcon
                            *ngIf="col.sortable"
                            [field]="col.field"
                            ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"
                        ></p-sortIcon>
                    </span>
                </div>
            </th>
            <th style="flex: 0 0 100px !important; min-width: 100px">Edit</th>
        </tr>
    </ng-template>
    <ng-template
        pTemplate="body"
        let-dataPoint
        let-columns="columns"
        let-ri="rowIndex"
    >
        <tr
            [ngClass]="{
                'p-highlight':
                    selectedDataPoint && dataPoint.id == selectedDataPoint.id,
            }"
        >
            <td style="flex: 0 0 3rem !important; min-width: 3rem">
                <div class="cbms-column-center">
                    <p-checkbox
                        [disabled]="
                            selectedDataPoint &&
                            dataPoint.id == selectedDataPoint.id
                        "
                        [(ngModel)]="dataPoint.selected"
                        binary="true"
                        (onChange)="updateSelectedDPList(dataPoint, ri, $event)"
                    >
                    </p-checkbox>
                </div>
            </td>
            <td style="flex: 0 0 190px !important; min-width: 190px">
                <div class="cbms-button-wrapper">
                    <ng-container *ngIf="actionConfig.selectRow">
                        <cbms-select-row
                            [dataPoint]="dataPoint"
                            (valueChange)="onRowSelected($event)"
                        >
                        </cbms-select-row>
                    </ng-container>
                    <ng-container *ngIf="actionConfig.analysisOneRecord">
                        <cbms-analysis-action
                            [selectedDataPoints]="[dataPoint]"
                        ></cbms-analysis-action>
                    </ng-container>
                    <ng-container *ngIf="actionConfig.setpointChangeOneRecord">
                        <cbms-setpoint-change-action
                            [selectedDataPoints]="[dataPoint]"
                        >
                        </cbms-setpoint-change-action>
                    </ng-container>
                    <ng-container *ngIf="actionConfig.viewDatapointOneRecord">
                        <cbms-view-data-point-props-action
                            [selectedDataPoint]="dataPoint"
                        >
                        </cbms-view-data-point-props-action>
                    </ng-container>
                    <ng-container *ngIf="actionConfig.showScheduleOneRecord">
                        <cbms-show-schedule-action
                            [selectedDataPoint]="dataPoint"
                            [isTwin]="false"
                        >
                        </cbms-show-schedule-action>
                    </ng-container>
                    <ng-container
                        *ngIf="actionConfig.showExceptionScheduleOneRecord"
                    >
                        <cbms-show-exception-schedule-action
                            [selectedDataPoint]="dataPoint"
                        >
                        </cbms-show-exception-schedule-action>
                    </ng-container>
                </div>
            </td>
            <td
                *ngFor="let col of columns"
                class="ui-resizable-column"
                style="flex-basis: {{ col.width }}; min-width: {{ col.width }}"
            >
                <ng-container [ngSwitch]="col.uiField">
                    <ng-container *ngSwitchCase="'tags'">
                        <div class="cbms-white-space-normal">
                            <ng-container *ngFor="let tag of dataPoint.tags">
                                <span class="cbms-chips">{{ tag.name }}</span>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'customName'">
                        <ng-container *ngIf="editing[dataPoint.id]">
                            <input
                                pInputText
                                type="text"
                                [(ngModel)]="dataPoint.customName"
                                (keyup.enter)="onCellEditSave(dataPoint)"
                            />
                        </ng-container>
                        <ng-container *ngIf="!editing[dataPoint.id]">
                            {{ dataPoint.customName }}
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'presentValue'">
                        <div class="cbms-text-align-center">
                            {{ dataPoint.presentValue }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'presentValueTimestamp'">
                        {{ dataPoint.presentValueTimestamp || 'N/A' }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'twinDatapointValue'">
                        <div class="cbms-text-align-center">
                            {{ dataPoint.twinDatapointValue }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'twinCreationDate'">
                        {{
                            dataPoint.twinCreationDate
                                ? (dataPoint.twinCreationDate
                                  | date: 'dd.MM.yyy, HH:mm:ss')
                                : 'N/A'
                        }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'twinDatapointCreationDate'">
                        {{
                            dataPoint.twinDatapointCreationDate
                                ? (dataPoint.twinDatapointCreationDate
                                  | date: 'dd.MM.yyy,HH:mm:ss')
                                : 'N/A'
                        }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'twinSchedules'">
                        <div
                            *ngIf="dataPoint.twinSchedules.length"
                            class="cbms-column-center"
                        >
                            <cbms-show-schedule-action
                                [selectedDataPoint]="dataPoint"
                                [isTwin]="true"
                            >
                            </cbms-show-schedule-action>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ocSite.tenant'">
                        <div *ngIf="dataPoint.ocSite" class="cbms-flex-left">
                            {{ dataPoint.ocSite.tenant.name }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ocSite.customer'">
                        <div *ngIf="dataPoint.ocSite" class="cbms-flex-left">
                            {{ dataPoint.ocSite.customer.name }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ocSite.name'">
                        <div *ngIf="dataPoint.ocSite" class="cbms-flex-left">
                            {{ dataPoint.ocSite.name }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ocSite.classification'">
                        <div *ngIf="dataPoint.ocSite" class="cbms-flex-left">
                            {{ dataPoint.ocSite.classification }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ocSite.channel'">
                        <div *ngIf="dataPoint.ocSite" class="cbms-flex-left">
                            {{ dataPoint.ocSite.channel }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ocSite.granularity'">
                        <div *ngIf="dataPoint.ocSite" class="cbms-flex-left">
                            {{ dataPoint.ocSite.granularity }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ocSite.isAccumulator'">
                        <div *ngIf="dataPoint.ocSite" class="cbms-flex-left">
                            {{ dataPoint.ocSite.isAccumulator }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ocSite.dataSource'">
                        <div *ngIf="dataPoint.ocSite" class="cbms-flex-left">
                            {{ dataPoint.ocSite.dataSource }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>{{
                        dataPoint[col.uiField]
                    }}</ng-container>
                </ng-container>
            </td>
            <td style="flex: 0 0 100px !important; min-width: 100px">
                <div class="cbms-column-center">
                    <button
                        *ngIf="!editing[dataPoint.id]"
                        pButton
                        type="button"
                        icon="pi pi-pencil"
                        class="ui-button-info"
                        (click)="onCellEditInit(dataPoint)"
                    ></button>
                    <button
                        *ngIf="editing[dataPoint.id]"
                        pButton
                        type="button"
                        icon="pi pi-check"
                        class="ui-button-success"
                        style="margin-right: 0.5em"
                        (click)="onCellEditSave(dataPoint)"
                    ></button>
                    <button
                        *ngIf="editing[dataPoint.id]"
                        pButton
                        type="button"
                        icon="pi pi-times"
                        class="ui-button-danger"
                        (click)="onCellEditCancel(dataPoint)"
                    ></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-confirmDialog
    header="Warning"
    icon="pi pi-exclamation-triangle"
    appendTo="body"
    width="500"
    styleClass="style-confirmation-message"
></p-confirmDialog>

<div
    class="cbms-spinner-wrapper"
    *ngIf="mappedDataPoints != null && mappedDataPoints.length == 0"
>
    <span class="no-datapoints-text">No Data Points found</span>
</div>
