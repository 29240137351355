import {IdNamePair} from '../data-point';

//Optimum Commercial Customer
export interface OCCustomer {
    id: string;
    name: string;
    siteList: IdNamePair[];
}


export class LabelValuePair {
    label: string;
    value: string;
    isDisabled?: boolean;
}

export function createLabelValuePair(label, value, isDisabled: boolean = false) {
    return <LabelValuePair>{
        label: label,
        value: value,
        isDisabled: isDisabled
    }

}


export class LabelValuePairGroup {
    label: string;
    value: string;
    items: LabelValuePair[];
}

export class OcSite {
    id: string;
    name: string;
    customer: IdNamePair;
    tenant: IdNamePair;
    granularity: string;
    isAccumulator: boolean;
    dataSource: string;
    classification: string;
    channel: string;
}
