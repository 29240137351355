import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '../../services/backend/backend.service';
import { DataPoint, ScheduleTemplate } from '../../models/data-point';
import { LazyLoadEvent } from 'primeng/api';
import { UtilsService } from '../../services/utils/util.service';
import { Subject, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { FilterParams } from '../../components/filter/interfaces/filter-params.interface';
import { ActionConfig } from '../../models/action-config';
import { FieldList } from 'src/app/config/constants';
import { v4 } from 'uuid';
import _ from 'lodash';
import { EventNotificationService } from '../../services/notification/event-notification.service';
import { StoreService } from '../../services/data/store.service';
import { TemplateType } from '../../models/core/template';
import { FilterService } from 'src/app/components/filter/filter.service';
import { DialogConfigService } from '../../services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-schedules',
    templateUrl: './schedules.component.html',
    styleUrls: ['./schedules.component.scss']
})
export class SchedulesComponent implements OnInit, OnDestroy {
    private createdScheduleIdSubject = new Subject<string>();
    createScheduleId$ = this.createdScheduleIdSubject.asObservable();

    public dataPoints: DataPoint[] = null;
    public nrOfSelectedDataPoints: number = null;
    public totalRecords: number = null;
    public isSelectedDatapointMode = false;
    public allDPSelected: boolean;
    public shouldHideFilters = false;
    public getObjectKeys = Object.keys;
    public selectedDataPointList: { [key: string]: DataPoint | { id: string } } = {};
    public cartUpdated: boolean = false;
    private pageSize = this.utilsService.getDataPointsPageSize();
    private filtersSubscription: Subscription;
    private customerSubscription: Subscription;
    private filterParams: FilterParams;
    public selectedWeeklyScheduleTemplate: ScheduleTemplate;
    public selectedExceptionScheduleTemplate: ScheduleTemplate;
    private subscriptionList: Subscription[] = [];
    public readonly EXCEPTION_SCHEDULE: TemplateType = TemplateType.EXCEPTION_SCHEDULE;
    public readonly WEEKLY_SCHEDULE: TemplateType = TemplateType.WEEKLY_SCHEDULE;


    filterConfigObject = {
        siteFilter: true,
        deviceFilter: true,
        tagFilter: true,
        tagLogicalOperator: true,
        quickFilterText: true,
        fieldFilter: true,
        gatewayFilter: true
    }
    tabName: string = 'schedules';
    public actionConfig: ActionConfig = {
        watchGroup: false,
        editTagsGroup: false,
        setpointChangeGroup: false,
        analysisGroup: false,
        analysisOneRecord: false,
        setpointChangeOneRecord: false,
        viewDatapointOneRecord: true,
        copyGroup: true,
        selectRow: true,
        showScheduleOneRecord: true,
        showExceptionScheduleOneRecord: true,
        createTwinFromSchedule: true,
        compareScheduleToTwin: true,
        compareDataPointToTwin: false,
        writeTwinToSchedule: true,
        createTwinFromDatapoint: false,
        writeTwinToDatapoint: false,
        deleteTwinFromDataPoint: false,
        syncWithOC: false,
        assignOC: false
    };

    public showSidebar: boolean = false;
    public showWeekly: boolean = false;
    public showException: boolean = false;

    public fieldFilterOptions: FieldList[] = [
        FieldList.NAME,
        FieldList.SIGNAL_TYPE,
        FieldList.SOURCE,
        FieldList.CUSTOM_NAME,
        FieldList.SOURCE_DESCRIPTION,
        FieldList.UNITS,
        FieldList.LAST_RECORDED_VALUE
    ];
    public columnsConfig: any = ['deviceSiteName', 'deviceName', 'objectName', 'description', 'twinSchedules', 'twinCreationDate'
        , 'tags', 'customName', 'id', 'signalType', 'source'];

    constructor(
        private backendService: BackendService,
        private utilsService: UtilsService,
        private dataService: DataService,
        private eventNotificationService: EventNotificationService,
        private storeService: StoreService,
        private filterService: FilterService,
        private dialogConfigService: DialogConfigService) {
    }

    ngOnInit() {
        if (!this.isSelectedDatapointMode) {
            sessionStorage.removeItem('excludeSitesFilter');
            this.dataService.shouldDisableFilter(null);
        }

        this.dataService.currentTabHasChanged('schedules');
        this.restoreSelectionFromSession();
        this.restoreSelectedTemplates();
        this.registerSubscribers();

        const scheduleFilter = {
            field: FieldList.TYPE,
            value: 'Schedule',
            operator: 'IS'
        };

        this.filtersSubscription = this.dataService.filtersChanged$.subscribe((filters: FilterParams) => {
            this.filterParams = _.cloneDeep({ ...filters, isPolled: true });
            this.filterParams.fieldFilter[v4()] = scheduleFilter;

            if (sessionStorage.getItem('multiSortOptions')) {
                const multiSortOptions = JSON.parse(sessionStorage.getItem('multiSortOptions'));
                const sortOptions = {
                    sortField: multiSortOptions.map(sortOption => sortOption.field).join(),
                    sortOrder: multiSortOptions[0].order === 1 ? 'asc' : 'desc'
                }

                this.loadData(0, this.pageSize, sortOptions);
            } else {
                this.backendService.getDataPointsDetails(this.filterParams).subscribe(response => {
                    this.dataPoints = response.content;
                    this.totalRecords = response.totalElements;
                });
            }
        });

        this.customerSubscription = this.filterService.customerFilterChanges$.subscribe(() => {
            this.clearDPSelection();
        });
    }

    ngOnDestroy() {
        this.filtersSubscription.unsubscribe();
        this.customerSubscription.unsubscribe();
        this.subscriptionList.forEach(value => value.unsubscribe());
        sessionStorage.removeItem('excludeSitesFilter');
        this.dataService.shouldDisableFilter(null);
    }

    public updateSelectedDataPoints(selectedDataPoints: DataPoint[]) {
        if (selectedDataPoints === null) {
            this.clearDPSelection();
            return;
        }

        this.cartUpdated = true;
        selectedDataPoints.forEach(selectedDataPoint => {
            if (selectedDataPoint.selected) {
                this.selectedDataPointList = Object.assign({}, this.selectedDataPointList, { [selectedDataPoint.id]: selectedDataPoint })
            } else {
                delete this.selectedDataPointList[selectedDataPoint.id];
                this.selectedDataPointList = { ...this.selectedDataPointList };
            }
        });

        this.updateAllDPSelectedValue();

        sessionStorage.setItem(`${this.tabName}-selectedDataPoints`, JSON.stringify(this.selectedDataPointList));
        this.nrOfSelectedDataPoints = Object.keys(this.selectedDataPointList).length;
    }

    public toggleAllDataPointsSelection(shouldSelectAll: boolean) {
        if (shouldSelectAll) {
            if (this.isSelectedDatapointMode) {
                this.selectedDataPointList = {};
                this.dataPoints.forEach(dataPoint => {
                    this.selectedDataPointList[dataPoint.id] = { id: dataPoint.id };
                    dataPoint.selected = true;
                });

                this.nrOfSelectedDataPoints = Object.keys(this.selectedDataPointList).length;
                sessionStorage.setItem(`${this.tabName}-selectedDataPoints`, JSON.stringify(this.selectedDataPointList));
                this.updateAllDPSelectedValue();

            } else {
                this.backendService.getAllDataPointsIdList(this.filterParams).subscribe((response) => {
                    this.selectedDataPointList = {};
                    response.forEach(item => {
                        this.selectedDataPointList[item] = { id: item };
                    });
                    this.nrOfSelectedDataPoints = Object.keys(this.selectedDataPointList).length;
                    sessionStorage.setItem(`${this.tabName}-selectedDataPoints`, JSON.stringify(this.selectedDataPointList));
                    this.markDataPointsAsSelected(response);
                    this.updateAllDPSelectedValue();
                });
            }
        } else {
            this.clearDPSelection();
            this.markDataPointsAsUnselected();
        }
        this.cartUpdated = true;
    }

    public lazyLoadEvent(event: LazyLoadEvent) {
        const pageNumber = event.first !== 0 ? (event.first / this.pageSize) : 0;
        let sortOptions = null;

        if (event.multiSortMeta && event.multiSortMeta.length) {
            sortOptions = {
                sortField: event.multiSortMeta.map(sortOption => sortOption.field).join(),
                sortOrder: event.multiSortMeta[0].order === 1 ? 'asc' : 'desc'
            }
        }

        this.loadData(pageNumber, this.pageSize, sortOptions);
    }

    public showWeeklyTemplates() {
        this.showSidebar = true;
        this.showWeekly = true;
        this.showException = false;
    }

    public showExceptionTemplates() {
        this.showSidebar = true;
        this.showException = true;
        this.showWeekly = false;
    }

    public hide() {
        this.showException = false;
        this.showWeekly = false;
    }

    public showSelectedDP() {
        sessionStorage.setItem('excludeSitesFilter', "true");
        this.dataService.shouldDisableFilter('sites');
        this.isSelectedDatapointMode = true;
        this.shouldHideFilters = true;
        this.loadData(0, this.pageSize, this.getSorting());
    }

    public showAllDP() {
        sessionStorage.removeItem('excludeSitesFilter');
        this.dataService.shouldDisableFilter(null);
        this.isSelectedDatapointMode = false;
        this.shouldHideFilters = false;
        delete this.filterParams.dataPointIdFilter;
        this.loadData(0, this.pageSize, this.getSorting());
    }

    public createScheduleDialog() {
        if (this.showWeekly) {
            this.dialogConfigService.showTemplateDialog({
                name: null,
                id: null,
                weeklyScheduleObject: {
                    weeklyScheduleWithInterval: [],
                    scheduleDefault: [],
                },
                isReadOnly: false,
            }).onClose.subscribe((templateId) => {
                if (templateId) {
                    this.createdScheduleIdSubject.next(templateId);
                }
            });
        }

        if (this.showException) {
            this.dialogConfigService.showExceptionScheduleTemplateDialog({
                exceptionScheduleObject: [],
                name: null,
                id: null,
                isReadonly: false
            }).onClose.subscribe((templateId) => {
                if (templateId) {
                    this.createdScheduleIdSubject.next(templateId);
                }
            });
        }


    }

    private updateAllDPSelectedValue() {
        this.allDPSelected = Object.keys(this.selectedDataPointList).length === this.totalRecords;
    }

    private markDataPointsAsSelected(selectedDataPointsIdList: string[]) {
        this.dataPoints.forEach((dataPoint => {
            if (selectedDataPointsIdList.indexOf(dataPoint.id) !== -1) {
                dataPoint.selected = true;
            }
        }));
    }

    private markDataPointsAsUnselected() {
        this.dataPoints.forEach((dataPoint => {
            dataPoint.selected = false;
        }));
    }

    private loadData(pageNumber: number, pageSize: number, sortOptions) {
        let filterParams;
        if (this.isSelectedDatapointMode) {
            filterParams = {
                dataPointIdFilter: Object.keys(this.selectedDataPointList).map((key) => this.selectedDataPointList[key])
            }
        } else {
            filterParams = this.filterParams;
        }

        let withGlobalFilters = true;//Default value of this param
        this.backendService.getDataPointsDetails(filterParams, pageNumber, pageSize, withGlobalFilters, sortOptions).subscribe(response => {
            this.dataPoints = [...response.content];
            let selectedIdsList = Object.keys(this.selectedDataPointList);
            if (selectedIdsList.length) {
                this.dataPoints = response.content.map(dataPoint => {
                    if (selectedIdsList.indexOf(dataPoint.id) !== -1) {
                        dataPoint.selected = true;
                    }
                    return dataPoint;
                });
            }
            this.totalRecords = response.totalElements;
            this.updateAllDPSelectedValue();
        });
    }

    private clearDPSelection() {
        this.selectedDataPointList = {};
        this.nrOfSelectedDataPoints = null;
        this.allDPSelected = false;
        sessionStorage.removeItem(`${this.tabName}-selectedDataPoints`);
    }

    private restoreSelectionFromSession() {
        this.selectedDataPointList = JSON.parse(sessionStorage.getItem(`${this.tabName}-selectedDataPoints`)) || {};
        this.nrOfSelectedDataPoints = Object.keys(this.selectedDataPointList).length;
    }

    private getSorting() {
        if (sessionStorage.getItem('multiSortOptions')) {
            const multiSortOptions = JSON.parse(sessionStorage.getItem('multiSortOptions'));
            const sortOptions = {
                sortField: multiSortOptions.map(sortOption => sortOption.field).join(),
                sortOrder: multiSortOptions[0].order === 1 ? 'asc' : 'desc'
            };
            return sortOptions;
        }
        return null;
    }

    private restoreSelectedTemplates() {
        if (this.storeService.selectedWeeklyScheduleTemplate) {
            this.selectedWeeklyScheduleTemplate = {
                id: this.storeService.selectedWeeklyScheduleTemplate.id,
                name: this.storeService.selectedWeeklyScheduleTemplate.name
            };
        }
        if (this.storeService.selectedExceptionScheduleTemplate) {
            this.selectedExceptionScheduleTemplate = {
                id: this.storeService.selectedExceptionScheduleTemplate.id,
                name: this.storeService.selectedExceptionScheduleTemplate.name
            };
        }
    }

    private registerSubscribers() {
        this.subscriptionList.push(this.eventNotificationService.getWeeklyScheduleTemplateSelected$()
            .subscribe(templateSelectedEvent => {
                this.showSidebar = false;
                this.selectedWeeklyScheduleTemplate = {
                    id: templateSelectedEvent.payload.id,
                    name: templateSelectedEvent.payload.name
                };

            }));
        this.subscriptionList.push(this.eventNotificationService.getWeeklyScheduleTemplateUnSelected$()
            .subscribe(_ => {
                this.selectedWeeklyScheduleTemplate = null;
                this.storeService.selectedWeeklyScheduleTemplate = null;
            }));

        //Exception schedule
        this.subscriptionList.push(this.eventNotificationService.getExceptionScheduleTemplateSelected$()
            .subscribe(templateSelectedEvent => {
                this.showSidebar = false;
                this.selectedExceptionScheduleTemplate = {
                    id: templateSelectedEvent.payload.id,
                    name: templateSelectedEvent.payload.name
                };
            }));

        this.subscriptionList.push(this.eventNotificationService.getExceptionScheduleTemplateUnSelected$()
            .subscribe(_ => {
                this.selectedExceptionScheduleTemplate = null;
                this.storeService.selectedExceptionScheduleTemplate = null;
            }));

    }
}
