<cbms-filter *ngIf="!shouldHideFilters" [config]="filterConfigObject" [tabName]="tabName"
    [fieldFilterOptions]="fieldFilterOptions"></cbms-filter>


<div class="cbms-title-wrapper-center">
    <ng-container *ngIf="!isSelectedDatapointMode">
        <mat-icon class="cbms-title-icon" svgIcon="datapoints"></mat-icon>
        <span class="cbms-title-text">Data Points</span>
        <span *ngIf="!isSelectedDatapointMode && totalRecords" class="cbms-margin-left cbms-margin-top10">({{totalRecords}})</span>
    </ng-container>

    <ng-container *ngIf="isSelectedDatapointMode">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__red-bg" (click)="showAllDP()">
            <i class="pi pi-arrow-left"></i>&nbsp;&nbsp;
            <span class="table-caption-text">Show All</span>
        </button>
    </ng-container>
    

    <ng-container *ngIf="dataPoints !== null">
        <button class="no-bg-btn cbms-margin-left-auto" (click)="showSelectedDP()" data-cy="cart">
            <i class="pi pi-shopping-cart">
                <span [ngClass]="{'cbms-blink': cartUpdated}" (animationend)="cartUpdated=false">{{nrOfSelectedDataPoints || ''}}</span>
            </i>
        </button>
    </ng-container>
</div>

<div class="table-wrapper" [ngClass]="{'cartView': isSelectedDatapointMode}">
    <cbms-data-points-table *ngIf="dataPoints" [dataPoints]="dataPoints" [totalRecords]="totalRecords"
        [allDPSelected]="allDPSelected" (lazyLoadEvent)="lazyLoadEvent($event)" [isSelectedDatapointMode]="isSelectedDatapointMode"
        [selectedDataPointList]="selectedDataPointList" (selectedDataPointEmitter)="updateSelectedDataPoints($event)"
        (toggleAllDataPointsSelectionEmitter)='toggleAllDataPointsSelection($event)' [columnsConfig]="columnsConfig"
        [actionConfig]="actionConfig" [tabName]="'dataPoints'"></cbms-data-points-table>

    <div class="cbms-spinner-wrapper" *ngIf="dataPoints == null">
        <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
    </div>

</div>