import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MappedDataPoint, ScheduleTemplate } from 'src/app/models/data-point';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
	selector: 'cbms-schedule-calendar-dialog',
	templateUrl: './schedule-calendar.component.html',
	styleUrls: ['./schedule-calendar.component.scss']
})
export class ScheduleCalendarDialogComponent implements OnInit {

	public isTwin: boolean;
	public selectedDataPoint: MappedDataPoint;
	public weeklyScheduleObject : any;
	public isLoading: boolean = false;
	public isRequestDone: boolean = false;
	public saveTemplateForm: FormGroup;
	public responseMessage: string;
	public isSuccessResponse: boolean;
	private getDataPointsSubscription: Subscription;

	constructor(public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private backendService: BackendService,
		private formBuilder: FormBuilder,
		private notificationService: NotificationService) { }

	ngOnInit() {
		this.isTwin = this.config.data.isTwin;
		this.selectedDataPoint = this.config.data.dataPoint;

		if (this.isTwin) {
			this.weeklyScheduleObject = this.selectedDataPoint.twinSchedules[0].weeklyScheduleObject;
			this.isRequestDone = true;
			this.isSuccessResponse=true;
		} else {
			this.getDataPointsSubscription = this.backendService.getDataPointWeeklySchedule(this.selectedDataPoint.id).subscribe(response => {
				this.weeklyScheduleObject = response;
				this.isRequestDone = true;
				this.isSuccessResponse=true;
			},error => {
				this.responseMessage="";
				this.isRequestDone = true;
				this.isSuccessResponse = false;
			});
		}

		this.saveTemplateForm = this.formBuilder.group({
            templateName: new FormControl('', Validators.required),
        });
	}

	get templateName() {
        return this.saveTemplateForm.get('templateName') as FormControl;
	}

	public close() {
		if (this.getDataPointsSubscription) {
			this.getDataPointsSubscription.unsubscribe();
		}
		this.ref.close();
	}

	public saveTemplate() {
		let templateName = this.saveTemplateForm.value.templateName;
		if (!templateName) { return; }

		let template: ScheduleTemplate = {
			name: templateName,
			weeklyScheduleObject: this.weeklyScheduleObject
		};

		this.isLoading = true;

		this.backendService.saveScheduleAsTemplate(template).subscribe(response => {
			this.isLoading = false;
			this.templateName.setValue('');
			this.notificationService.addSuccessMessage('Weekly Schedule', 'Template successfully saved.', false);
		}, (error) => {
			this.isLoading = false;
			this.notificationService.addErrorMessage('Schedule', error);
		});
    }
}
