import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/util.service';
import { IDataPointProperty } from 'src/app/models/data-point';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
    selector: 'cbms-data-point-properties',
    templateUrl: './data-point-properties.component.html',
    styleUrls: ['./data-point-properties.component.scss']
})
export class DataPointPropertiesComponent implements OnInit {
    public isLoadingProperties: boolean = false;
    public properties: TreeNode[];

    statusFlags = [
        'In Alarm',
        'Fault',
        'Overridden',
        'Out of Service'
    ];

    priorityArray = [
        '1 (Manual Life Safety)',
        '2 (Automatic Life Safety)',
        '3 (Not Named)',
        '4( Not Named)',
        '5 (Critical Equipment Control)',
        '6 (Minimum On/Off)',
        '7 (Not Named)',
        '8 (Manual Operator)',
        '9 (Not Named)',
        '10 (Not Named)',
        '11 (Not Named)',
        '12 (Not Named)',
        '13 (Not Named)',
        '14 (Not Named)',
        '15 (Not Named)',
        '16 (Not Named)'
    ];

    propertiesOrder = {
        'object_name': 1,
        'description': 2,
        'present_value': 3,
        'inactive_text': 4,
        'active_text': 5,
        'units': 6,
        'state_text': 7,
        'priority_array': 8,
        'relinquish_default': 9,
        'weekly_schedule': 10,
        'exception_schedule': 11,
        'status_flags': 12,
        'out_of_service': 13,
        'reliability': 14,
        'other': 15
    };
    public editing: { [key: string]: boolean } = {};
    public backupData: {[key: string]: number} = {};
    dataPointId: string;
    isInterceptValueValid: boolean = false;

    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private backendService: BackendService,
        private notificationService: NotificationService,
        private utilsService: UtilsService) { }

    ngOnInit() {
        this.dataPointId = this.config.data.id;
        this.isLoadingProperties = true;
        this.backendService.getDataPointProperties(this.dataPointId).subscribe(dataPointProperties => {
            if (!dataPointProperties) {
                this.properties = null;
            } else {
                this.properties = this.utilsService.orderListByFieldName(this.transformProperties(dataPointProperties), 'position');
            }
            this.isLoadingProperties = false;
        })
    }

    transformProperties(dataPointProperties) {
        return Object.keys(dataPointProperties).map(key => {
            let item: any = {
                position: this.propertiesOrder[key] ? this.propertiesOrder[key] : this.propertiesOrder.other,
            };

            if (key === 'status_flags') {
                item.data = {
                    name: key,
                    value: dataPointProperties[key]
                };
                item.children = [
                    ...dataPointProperties[key].map((value, index: number) => {
                        return {
                            data: {
                                name: this.statusFlags[index],
                                value: value
                            }
                        }
                    })
                ];

                return item;
            };

            if (key === 'priority_array') {
                item.data = {
                    name: key,
                    value: dataPointProperties[key].map(value => this.parsePriorityArrayValue(value))
                };
                item.children = [
                    ...dataPointProperties[key].map((value, index: number) => {
                        return {
                            data: {
                                name: this.priorityArray[index],
                                value: this.parsePriorityArrayValue(value)
                            }
                        }
                    })
                ];

                return item;
            }

            item.data = {
                name: key,
                value: JSON.stringify(dataPointProperties[key])
            }

            return item;
        });
    }

    parsePriorityArrayValue(value) {
        if (value === 0) { return 0; }
        return value ? value : 'Null';
    }

    close() {
        this.ref.close();
    }

    /**
     * @description start edit value
     * @input interceptObj type IDataPointProperty
     * @output change field's value and save previous data
     */
    public onCellEditInit(interceptObj: IDataPointProperty) {
        this.isInterceptValueValid = false;
        this.editing[this.dataPointId] = true;
        this.backupData[this.dataPointId] = interceptObj.value;
    }

    /**
     * @description save new value
     * @input interceptObj type IDataPointProperty
     * @output void - popup notifies update status
     */
    public onCellEditSave(interceptObj: IDataPointProperty) {
        this.editing[this.dataPointId] = false;
        if (interceptObj.value === this.backupData[this.dataPointId]) return;
        const param: IDataPointProperty[] = [
            {
                "name": interceptObj.name,
                "value": interceptObj.value
            }
        ]
        this.backendService.updateInterceptValue(this.dataPointId, param).subscribe((res) => {
            if (res.data.write_acknowledgements.length > 0)
                this.notificationService.addSuccessMessage('Edit', 'Intercept successfully updated.', false);
            else {
                this.notificationService.addErrorMessage('Edit', 'Intercept write has failed, property has not been updated.', false);
                this.onCellEditCancel(interceptObj);
            }
        });
    }

    /**
     * @description validate intercept value, valid range is between -10 and 10
     * @input interceptValue type number
     * @output void display error message popup if out of range
     */
    validateInterceptValue(interceptValue: number): void {
        const errorMessage = (interceptValue === null || interceptValue < -10 || interceptValue > 10) ?
        'Intercept write was not actioned due to being out of range. Max = 10°C Min =-10°C' :
        '';
        if (errorMessage) {
            this.isInterceptValueValid = false;
            this.notificationService.addErrorMessage('Edit', errorMessage, false);
        } else {
            interceptValue == this.backupData[this.dataPointId] //if new value is equal to original value
                            ? this.isInterceptValueValid = false
                            : this.isInterceptValueValid = true;
        }
    }

    /**
     * @description reset field's value to default value
     * @input interceptObj type IDataPointProperty
     * @output void
     */
    public onCellEditCancel(interceptObj: IDataPointProperty) {
        this.isInterceptValueValid = false;
        this.editing[this.dataPointId] = false;
        interceptObj.value = this.backupData[this.dataPointId];
        delete this.backupData[this.dataPointId];
    }

}
