<div class="alert-config-wrapper">
    <div>
        <div class="q-button-group q-right-align">
            <button type="button" class="q-button" (click)="addRule()" [disabled]="isReadOnly">
                <i class="q-icon q-add-icon"></i>
                Rule</button
            ><button type="button" class="q-button" (click)="addRuleset()" [disabled]="isReadOnly">
                <i class="q-icon q-add-icon"></i>
                Ruleset
            </button>
            <ng-container *ngIf="!!parentValue">
                <button
                    type="button"
                    (click)="removeRuleSet()"
                    [disabled]="isReadOnly"
                    class="q-button q-remove-button"
                >
                    <i class="q-icon q-remove-icon"></i>
                </button>
            </ng-container>
            <span *ngIf="isReadOnly && !parentValue">Last recorded value</span>
        </div>

        <div class="q-switch-group q-transition">
            <div>
                <input
                    class="q-switch-radio"
                    type="radio"
                    [(ngModel)]="query.condition"
                    [disabled]="isReadOnly"
                    value="and"
                    #andOption
                />
                <label
                    class="q-switch-label"
                    (click)="changeCondition(andOption.value)"
                    >AND</label
                >
            </div>
            <div>
                <input
                    class="q-switch-radio"
                    type="radio"
                    [(ngModel)]="query.condition"
                    [disabled]="isReadOnly"
                    value="or"
                    #orOption
                />
                <label
                    class="q-switch-label"
                    (click)="changeCondition(orOption.value)"
                    >OR</label
                >
            </div>
        </div>
    </div>

    <div class="q-tree-container">
        <ul class="q-tree">
            <ng-container *ngFor="let rule of query.rules; let i = index">
                <ng-container *ngIf="{ isRuleset: !!rule.rules } as local">
                    <li class="q-row q-connector q-transition q-rule" [ngClass]="rule.isLastRecordedValueObsolete ? 'cbms-yellowBg' : rule.isMatching ? 'cbms-redBg' : ''">
                        <ng-container *ngIf="!local.isRuleset">
                            <div class="q-control-size">
                                <ng-container *ngIf="isReadOnly">
                                    {{ rule.datapointName }}
                                </ng-container>
                                <ng-container *ngIf="!isReadOnly">
                                    <p-dropdown
                                        *ngIf="dataPointList?.length"
                                        class="dd-min-width200 min-width300"
                                        [options]="dataPointList"
                                        [(ngModel)]="rule.selectedOption"
                                        placeholder="Datapoints"
                                        optionLabel="displayName"
                                        [filter]="true"
                                        filterBy="displayName"
                                        [showClear]="true"
                                        [virtualScroll]="true"
                                        virtualScrollItemSize="30"
                                        (onChange)="
                                            onDataPointSelected($event, rule)
                                        "
                                        #dropdown
                                    >
                                    </p-dropdown
                                    >
                                </ng-container>
                            </div>
                            <div class="q-control-size">
                                <ng-container *ngIf="isReadOnly">
                                    {{ rule.operator }}
                                </ng-container>
                                <ng-container *ngIf="!isReadOnly">
                                    <p-dropdown
                                        [options]="operatorList"
                                        [(ngModel)]="rule.operator"
                                    ></p-dropdown>
                                </ng-container>
                            </div>
                            <div class="q-control-size">
                                <ng-container *ngIf="isReadOnly">
                                    {{ rule.value }}
                                </ng-container>
                                <ng-container *ngIf="!isReadOnly">
                                    <input
                                        *ngIf="rule.isFreeText"
                                        class="q-input-control"
                                        pInputText
                                        placeholder="Value"
                                        [(ngModel)]="rule.value"
                                    />

                                    <p-dropdown
                                        *ngIf="!rule.isFreeText"
                                        [options]="binaryPointList"
                                        [(ngModel)]="rule.value"
                                    ></p-dropdown>
                                </ng-container>
                            </div>
                            <div class="cbms-margin-left-auto" *ngIf="isReadOnly">
                                <span>{{rule.matchWithValue}}</span>
                            </div>
                            <div class="q-right-align cbms-margin-left-auto" *ngIf="!isReadOnly">
                                <button
                                    type="button"
                                    class="q-button q-remove-button"
                                    [disabled]="isReadOnly"
                                    (click)="removeRule(rule)"
                                >
                                    <i class="q-icon q-remove-icon"></i>
                                </button>
                            </div>
                        </ng-container>
                        <cbms-query-builder
                            *ngIf="local.isRuleset"
                            [isReadOnly]="isReadOnly"
                            [selectedSite]="selectedSite"
                            [dataPointList]="dataPointList"
                            [operatorList]="operatorList"
                            [parentValue]="query"
                            [query]="rule"
                        ></cbms-query-builder>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</div>
