<div class="content">

    <div class="grid cbms-flex-align-item-center">
        <div class="col-4">OC Tenant:</div>
        <div class="col-8">
            <p-dropdown [styleClass]="'cbms-dropdown__mid-size'" [options]="tenantList" [(ngModel)]="selectedTenantId"
                        (ngModelChange)='selectedTenantChanged($event)'
                        placeholder="Select a Tenant"></p-dropdown>
        </div>
    </div>

    <div class="grid cbms-flex-align-item-center">
        <div class="col-4">OC Site:</div>
        <div class="col-8">
            <p-dropdown [styleClass]="'cbms-dropdown__mid-size'" [options]="customerGroupList"
                        [(ngModel)]="selectedSiteId" placeholder="Select a Site"
                        [filter]="true" filterBy="label"
                        [group]="true">
                <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                        <span>{{group.label}}</span>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="grid cbms-flex-align-item-center">
        <div class="col-4">Classification:</div>
        <div class="col-8">
            <p-dropdown [styleClass]="'cbms-dropdown__mid-size'" [options]="classificationList"
                        [(ngModel)]="selectedClassification" placeholder="Select a Classification"
                        [filter]="true" filterBy="label"
                        (onChange)="onClassificationSelected()"
                        >
                <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                        <span>{{group.label}}</span>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="grid cbms-flex-align-item-center">
        <div class="col-4">Channel:</div>
        <div class="col-8">
            <p-dropdown [styleClass]="'cbms-dropdown__mid-size'" [options]="channelList"
                        [(ngModel)]="selectedChannel" placeholder="Select a Channel"
                        [filter]="true" filterBy="label">
                <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                        <span>{{group.label}}</span>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>


    <div class="grid cbms-flex-align-item-center">
        <div class="col-4">OC Granularity:</div>
        <div class="col-8">
            <p-dropdown [styleClass]="'cbms-dropdown__mid-size'"
                        [options]="granularityList" [(ngModel)]="selectedGranularity"
                        placeholder="Select Granularity"></p-dropdown>
        </div>
    </div>

    <div class="grid cbms-flex-align-item-center">
        <div class="col-4">OC Is Accumulator:</div>
        <div class="col-8">
            <p-dropdown [styleClass]="'cbms-dropdown__mid-size'"
                        [options]="isAccumulatorList" [(ngModel)]="selectedIsAccumulator"
                        placeholder="Select is accumulator"></p-dropdown>
        </div>
    </div>


    <div class="grid cbms-flex-align-item-center">
        <div class="col-4">Data Source:</div>
        <div class="col-8">
            <p-dropdown [styleClass]="'cbms-dropdown__mid-size'"
                        [optionDisabled]="'isDisabled'"
                        [options]="dataSourceList" [(ngModel)]="selectedDataSource"
                        placeholder="Select data source"></p-dropdown>
        </div>
    </div>

</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            pTooltip="Add OC Tenant for selected schedules" showDelay="1000" hideDelay="500"
            (click)="confirm()" [disabled]="isDisabled()  ">Add OC Tenant
    </button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>
