import { Component, OnInit } from '@angular/core';
import { BackendService } from '../../../../services/backend/backend.service';
import { FilterService } from '../../../../components/filter/filter.service';
import { saveAs } from 'file-saver';
import { UtilsService } from 'src/app/services/utils/util.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cbms-auto-optimisation',
    templateUrl: './auto-optimisation.component.html',
    styleUrls: ['./auto-optimisation.component.scss'],
})
export class AutoOptimisationComponent implements OnInit {

    public twinDatapointEnabled: false;
    public twinScheduleEnabled: false;
    public twinDatapointReportStatus: string;
    public twinDatapointReportEndDate: string;
    private customerId: string;
    private customerName: string;

    public twinScheduleReportStatus: string;
    public twinScheduleReportEndDate: string;
    private subscription: Subscription;

    constructor(private backendService: BackendService,
                private filterService: FilterService,
                private utilsService: UtilsService) {

    }

    ngOnInit(): void {
        this.getEnabledFlags();
        this.subscription = this.filterService.customerFilterChanges$.subscribe((data) => {
            this.customerId = data.selectedCustomer?.id;
            this.customerName = data.selectedCustomer?.name;
            this.getTwinDatapointReportStatus();
            this.getTwinScheduleReportStatus();

            this.getEnabledFlags();

        });
        if (_.isEmpty(this.customerId)) {
            this.getCurrentCustomer();
        }

        this.getTwinDatapointReportStatus();
        this.getTwinScheduleReportStatus();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getCurrentCustomer() {
        this.backendService.getCustomers().subscribe(customerList => {
            let selectedCustomer: {id, name};
			let customerArr = this.utilsService.orderListByFieldName(customerList.map(customer => { return { id: customer.id, name: customer.name }; }), 'name');
			this.backendService.getLastCustomer().subscribe(response => {
				if (_.isEmpty(response)) {
					selectedCustomer = customerArr[0];
				} else {
					selectedCustomer = response;
				}
                this.customerId = selectedCustomer.id;
                this.customerName = selectedCustomer?.name;
			})
		});
    }

    private getEnabledFlags() {
        this.backendService.getCustomerAutoOptimisationConfig().subscribe(value => {
            this.twinDatapointEnabled = value.twinDatapointOptimisationEnabled;
            this.twinScheduleEnabled = value.twinScheduleOptimisationEnabled;
        });
    }

    private getTwinDatapointReportStatus() {
        this.backendService
            .getTwinDatapointReportStatus(this.customerId)
            .subscribe((response) => {
                this.twinDatapointReportEndDate =
                    response.status.lastSuccessfulReportDate;
                this.twinDatapointReportStatus = response.status.statusDescription;
            });
    }

    private getTwinScheduleReportStatus() {
        this.backendService
            .getTwinScheduleReportStatus(this.customerId)
            .subscribe((response) => {
                this.twinScheduleReportEndDate =
                    response.status.lastSuccessfulReportDate;
                this.twinScheduleReportStatus = response.status.statusDescription;
            });
    }

    handleTwinScheduleUpdate() {
        this.backendService.updateTwinScheduleOptimisationEnabled(this.twinScheduleEnabled).subscribe(_ => {
        });
    }

    handleTwinDatapointUpdate() {
        this.backendService.updateTwinDatapointOptimisationEnabled(this.twinDatapointEnabled).subscribe(_ => {
        });
    }

    downloadTwinDatapointReport() {
        this.backendService
            .getTwinDatapointReportCsv(this.customerId)
            .subscribe(
                {
                    next: (response) => {
                        saveAs(
                            response,
                            `${
                                this.customerName
                            }_Twin_Datapoint_Optimisation_Report_${this.getDateNowAsText()}.csv`,
                        );
                    },
                    error: console.log,
                },
            );
    }

    private getDateNowAsText() {
        let date = new Date();
        return `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}_${date.getHours()}${date.getMinutes()}`;
    }

    downloadTwinScheduleReport() {
        this.backendService
            .getTwinScheduleReportCsv(this.customerId)
            .subscribe(
                {
                    next: (response) => {
                        saveAs(
                            response,
                            `${
                                this.customerName
                            }_Twin_Schedule_Optimisation_Report_${this.getDateNowAsText()}.csv`,
                        );
                    },
                    error: console.log,
                },
            );
    }
}
