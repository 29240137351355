import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError, retry } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { IExportFileRes } from 'src/app/models/api/AuditTrail';

enum BackendException {
    GATEWAY_NOT_REACHABLE = 'GatewayNotReachable',
    DEVICE_NOT_REACHABLE = 'DeviceNotReachable',
}

@Injectable()
export class HttpBaseService {
    // TODO: thats the simple version now, later: store requests and finish if all are done
    protected cbmsAPIUrl = environment.cbmsAPIUrl;

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService,
        private authService: OAuthService
    ) {}

    public get<T>(
        path: string,
        params?: HttpParams,
        handleError: boolean = true
    ): Observable<T> {
        const httpHeaders = this.getHeaders()

        return this.http
            .get(this.cbmsAPIUrl + `/${path}`, {
                headers: httpHeaders,
                params: params != null ? params : new HttpParams(),
            })
            .pipe(
                retry({ count: 3, delay: 1000 }),
                catchError((err) => this.handleError(err, handleError))
            )
            .pipe(
                map((response: any) => {
                    return <T>(<unknown>response);
                })
            );
    }

    public getFile(path: string): Observable<Blob> {
        const httpHeaders = this.getHeaders();

        return this.http.get(this.cbmsAPIUrl + `/${path}`, {
            headers: httpHeaders,
            responseType: 'blob',
        });
    }

    public postFile(
        path: string,
        params?: HttpParams,
        body?: any,
        handleError: boolean = true
    ): Observable<IExportFileRes> {
        let httpHeaders = this.getHeaders();

        return this.http
            .post(this.cbmsAPIUrl + `/${path}`, body, {
                headers: httpHeaders,
                observe: 'response',
                params: params != null ? params : new HttpParams(),
                responseType: 'blob'
            })
            .pipe(
                catchError((err) => this.handleError(err, handleError, body)),
                map((response) => {
                    return {body: response?.body, type: response?.headers?.get('Content-Disposition')};
                })
            );
    }
    public getFileWithHeader(path: string) {
        const httpHeaders = this.getHeaders();

        return this.http.get(this.cbmsAPIUrl + `/${path}`, {
            headers: httpHeaders,
            responseType: 'blob',
            observe: 'response'
        });
    }

    public put<T>(
        path: string,
        params?: HttpParams,
        body?: any,
        handleError: boolean = true
    ): Observable<T> {
        const httpHeaders = this.getHeaders()

        return this.http
            .put(this.cbmsAPIUrl + `/${path}`, body, {
                headers: httpHeaders,
                params: params != null ? params : new HttpParams(),
            })
            .pipe(
                retry({ count: 3, delay: 1000 }),
                catchError((err) => this.handleError(err, handleError)),
                map((response: Response) => {
                    return <T>(<unknown>response);
                })
            );
    }

    public post<T>(
        path: string,
        params?: HttpParams,
        body?: any,
        handleError: boolean = true,
        needsContentType: boolean = true
    ): Observable<T> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set(
            'Authorization',
            'Bearer ' + this.authService.getIdToken()
        );

        if (needsContentType) {
            httpHeaders.set('Content-Type', 'application/json');
        }

        return this.http
            .post(this.cbmsAPIUrl + `/${path}`, body, {
                headers: httpHeaders,
                params: params != null ? params : new HttpParams(),
            })
            .pipe(
                retry({ count: 3, delay: 1000 }),
                catchError((err) => this.handleError(err, handleError, body)),
                map((response: Response) => {
                    return <T>(<unknown>response);
                })
            );
    }

    public sendDataToFusekiServer<T>(
        path: string,
        headers: any,
        params?: HttpParams,
        body?: any,
        handleError: boolean = true
    ): Observable<T> {
        let httpHeaders = new HttpHeaders(headers);
        httpHeaders.set(
            'Authorization',
            'Bearer ' + this.authService.getIdToken()
        );

        return this.http
            .post(this.cbmsAPIUrl + `/${path}`, body, {
                headers: httpHeaders,
                params: params != null ? params : new HttpParams(),
            })
            .pipe(
                retry({ count: 3, delay: 1000 }),
                catchError((err) => this.handleError(err, handleError, body)),
                map((response: Response) => {
                    return <T>(<unknown>response);
                })
            );
    }

    public patch<T>(
        path: string,
        params?: HttpParams,
        body?: any,
        handleError: boolean = true
    ): Observable<T> {
        const httpHeaders = this.getHeaders()

        return this.http
            .patch(this.cbmsAPIUrl + `/${path}`, body, {
                headers: httpHeaders,
                params: params != null ? params : new HttpParams(),
            })
            .pipe(
                retry({ count: 3, delay: 1000 }),
                catchError((err) => this.handleError(err, handleError)),
                map((response: Response) => {
                    return <T>(<unknown>response);
                })
            );
    }

    public delete<T>(
        path: string,
        params?: HttpParams,
        body?: any,
        handleError: boolean = true
    ): Observable<T> {
        const httpHeaders = this.getHeaders()

        return this.http
            .request('DELETE', this.cbmsAPIUrl + `/${path}`, {
                body: body,
                headers: httpHeaders,
                params: params != null ? params : new HttpParams(),
            })
            .pipe(
                retry({ count: 3, delay: 1000 }),
                catchError((err) => this.handleError(err, handleError)),
                map((response: Response) => {
                    return <T>(<unknown>response);
                })
            );
    }

    private handleError = (
        error: HttpErrorResponse,
        handleError: boolean,
        body?: any
    ) => {
        if (!handleError) {
            return throwError(() => error);
        }

        const response = error.error;

        if (error.status === 403 && response.error === 'NotAuthorized') {
            if (window.location.pathname !== '/error') {
                window.location.href = window.location.origin + '/error';
            }
            return throwError(() => error);
        }

        if (error.status === 0) {
            this.notificationService.addErrorMessage(
                'Request timed out',
                'Socket unexpectedly closed. Request most likely timed out.'
            );
        }

        if (BackendException.GATEWAY_NOT_REACHABLE === response?.error) {
            this.notificationService.addErrorMessage(
                'Gateway not reachable !',
                `${response?.message} in path ${response?.path}`
            );
        }
        if (BackendException.DEVICE_NOT_REACHABLE === response?.error) {
            this.notificationService.addErrorMessage(
                'Device not reachable !',
                `${response?.message} in path ${response?.path}`
            );
        } else {
            this.notificationService.addErrorMessage(
                `Response with Status: ${error?.status}`,
                `"${response?.error}" in path ${response?.path} : ${response?.message}`
            );
        }

        return throwError(() => error.message);
    };

    private getHeaders() {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');

        return httpHeaders.set(
            'Authorization',
            'Bearer ' + this.authService.getIdToken()
        );
    }
}
