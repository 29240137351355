import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '../../services/backend/backend.service';
import { ViewDevice } from '../../models/device';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation/navigation.service';
import { UtilsService } from '../../services/utils/util.service';
import { DataService } from '../../services/data/data.service';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { Subscription } from 'rxjs';
import { TreeNode, ConfirmationService } from 'primeng/api';
import { MappedDataPoint } from 'src/app/models/data-point';
import { FilterService } from 'src/app/components/filter/filter.service';
import { FieldFilter, FilterParams } from '../../components/filter/interfaces/filter-params.interface';
import { FieldList, PAGE_SIZE_100k, PAGINATION, ExternalSource, ASSIGN_BY } from '../../config/constants';
import { NotificationService } from '../../services/notification/notification.service';
import {Gateway} from "../../models/gateway";

@Component({
    selector: 'cbms-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnDestroy {
    public devices: ViewDevice[] = null;
    public isLoading: boolean = false;
    public isLoadingDataPoints: boolean = false;
    public isHierarchyVisible: boolean = false;
    public hierarchySeparator: string = '';
    public nrOfSelectedDataPoints: number = null;
    public rows: number = PAGINATION.SIZE;
    public totalRecords: number = 0;
    public openedDevice: ViewDevice = null;
    public selectedDeviceIdList: string[] = [];

    public fieldFilterOptions: FieldList[];

    filterConfigObject = {
        deviceFilter: true,
        fieldFilter: false,
        gatewayFilter: true
    }
    tabName: string = 'devices';

    hierarchyArray: TreeNode[] = [];
    selectedNode: TreeNode;
    nameToFilterFor: string;
    otherDataPoints: MappedDataPoint[] = [];
    allDevicesSelected: boolean;

    quickFilter: string = '';
    fieldFilter: FieldFilter = {};

    private expandByParameter: string = null;
    private page = 0;
    private filtersSubscription: Subscription;
    private siteSubscription: Subscription;
    private subs = new Subscription();

    constructor(private backendService: BackendService,
        private navigationService: NavigationService,
        private route: ActivatedRoute,
        private router: Router,
        private utilService: UtilsService,
        private dialogConfigService: DialogConfigService,
        private dataService: DataService,
        private filterService: FilterService,
        private notificationService: NotificationService,
        private confirmationService: ConfirmationService
    ) {


    }

    ngOnInit() {
        this.dataService.currentTabHasChanged('devices');

        this.route.queryParams.subscribe(parameter => {
            if (parameter['selected'] != null) {
                this.expandByParameter = parameter['selected'];
            }
        });

        this.filtersSubscription = this.dataService.filtersChanged$.subscribe((filters: FilterParams) => {
            this.reloadDevices(0, filters.deviceFilter, filters.gatewayFilter);
        });

        this.siteSubscription = this.filterService.siteFilterChanges$.subscribe(() => {
            this.reloadDevices();
        });
    }

    ngOnDestroy() {
        if (this.filtersSubscription) {
            this.filtersSubscription.unsubscribe();
        }
        if (this.siteSubscription) {
            this.siteSubscription.unsubscribe();
        }
        this.subs.unsubscribe();
    }

    public hasDevices() {
        return (this.devices == null || this.devices.length > 0);
    }

    public openDevice(device: ViewDevice) {
        this.generateFieldFilterOptions(device);

        this.openedDevice = device;
        this.resetHierarchy(device);
        this.isHierarchyVisible = false;

        const filterParams = {
            deviceFilter: [device]
        };

        setTimeout(() => {
            this.navigationService.setQueryParameter(this.route, { 'selected': device.id });
        });

        this.isLoadingDataPoints = true;
        this.backendService.getDataPointsDetails(filterParams, this.page, PAGE_SIZE_100k, false).subscribe(response => {
            device.dataPoints = response.content;
            device.dataPointsCopy = [...response.content];
            device.totalRecords = response.totalElements;
            this.devices.forEach(item => {
                item.isOpened = item.id === device.id;
            });

            this.isLoadingDataPoints = false;
        });
    }

    public generateFieldFilterOptions(device: ViewDevice) {
        if (device.source === ExternalSource.BACKNET) {
            this.fieldFilterOptions = [
                FieldList.POLLED,
                FieldList.SITE_ASSIGNED,
                FieldList.NAME,
                FieldList.TYPE,
                FieldList.SIGNAL_TYPE,
                //FieldList.INSTANCE, // needs to be checked on BE side
                FieldList.SOURCE_DESCRIPTION,
                FieldList.LAST_RECORDED_VALUE,
                FieldList.UNITS
            ];
        }

        if (device.source === ExternalSource.WISEMETER) {
            this.fieldFilterOptions = [
                FieldList.POLLED,
                FieldList.SITE_ASSIGNED,
                FieldList.NAME,
                FieldList.TYPE,
                FieldList.SIGNAL_TYPE,
                //FieldList.WISEMETER_ID,  // needs to be checked on BE side
                //FieldList.CLASSIFICATION, // needs to be checked on BE side
                FieldList.LAST_RECORDED_VALUE,
                FieldList.UNITS
            ];
        }
    }

    public closeDevice() {
        this.navigationService.setQueryParameter(this.route, { 'selected': '' });
    }

    public deleteDevice(device: ViewDevice) {
        this.backendService.deleteDeviceById(device.id).subscribe(() => {
            this.navigationService.setQueryParameter(this.route, { 'selected': '' });
            this.reloadDevices();
        });
    }

    public scrollToDevice(deviceId: string) {
        const el = document.getElementById(`id${deviceId}`);
        el.scrollIntoView({ behavior: 'smooth' });
    }

    public toggleHierarchyVisibility() {
        this.isHierarchyVisible = !this.isHierarchyVisible;
    }

    public createHierarchy(device: ViewDevice) {
        let splitResult: string[] = [];
        let partialHierarchy: Array<Array<string>> = [];
        this.otherDataPoints = [];
        device.dataPoints = [...device.dataPointsCopy];

        device.dataPoints.forEach((dataPoint: MappedDataPoint) => {
            splitResult = dataPoint.objectName.split(this.hierarchySeparator);
            if (splitResult.length === 1) {
                this.otherDataPoints.push(dataPoint);
            } else {
                splitResult = this.removeLastNodeOfHierarchy(splitResult);
                partialHierarchy.push(splitResult);
            }
        });

        this.hierarchyArray = this.transformHierarchy(this.createHierarchyStructure(partialHierarchy));
        if (this.otherDataPoints.length) {
            this.hierarchyArray.push({
                'label': 'Others',
                'data': 'Others',
                "expandedIcon": "pi pi-sitemap",
                "collapsedIcon": "pi pi-sitemap",
                'children': []
            })
        }
    }

    public resetHierarchy(device: ViewDevice) {
        if (this.nameToFilterFor && device !== undefined && device.dataPoints) {
            device.dataPoints = [...device.dataPointsCopy];
        }
        this.hierarchySeparator = '';
        this.nameToFilterFor = '';
        this.hierarchyArray = [];
        this.otherDataPoints = [];
    }

    public handleResetHierarchy($event) {
        if ($event) {
            this.resetHierarchy(undefined);
            this.isHierarchyVisible = false;
        }
    }

    public onNodeSelect(event: any, device: ViewDevice) {
        if (event.node.label === 'Others') {
            this.nameToFilterFor = '';
            device.dataPoints = [...this.otherDataPoints];
            return;
        }

        if (this.nameToFilterFor === this.createFullName(this.selectedNode)) {
            return;
        }
        this.nameToFilterFor = this.createFullName(this.selectedNode);

        device.dataPoints = [...device.dataPointsCopy.filter(dataPoint => {
            return dataPoint.objectName.startsWith(this.nameToFilterFor);
        })];
    }

    public updateNrOfDataPoints(nrOfSelectedDataPoints: number) {
        this.nrOfSelectedDataPoints = nrOfSelectedDataPoints;
    }

    public paginateDevices(event) {
        this.reloadDevices(event.page);
    }

    public onFieldFilterChange(fieldFilter: any) {
        this.fieldFilter = fieldFilter;
    }

    public onQuickFilterChange(newFilter: string) {
        this.quickFilter = newFilter;
    }

    public onFilter() {
        const filterParams = {
            deviceFilter: [this.openedDevice],
            quickFilterText: this.quickFilter,
            fieldFilter: this.fieldFilter
        };

        if (this.isHierarchyVisible && this.hierarchyArray.length) {
            this.confirmationService.confirm({
                message: `Please note that applying a new filter after creating the hierarchy will result in loosing your selection and recalculation of the hierarchy.
                            <br /> Are you sure you want to continue?`,
                accept: () => {
                    this.filterDataPoints(filterParams);
                }
            });
        } else {
            this.filterDataPoints(filterParams);
        }
    }

    public clearFilters() {
        if (!this.quickFilter && !Object.keys(this.fieldFilter).length) {
            return;
        }

        this.quickFilter = '';
        this.fieldFilter = {};

        const filterParams = {
            deviceFilter: [this.openedDevice]
        };

        this.filterDataPoints(filterParams);
    }

    public unassignSite() {
        this.backendService.unassignDevicesPointsFromSite(this.selectedDeviceIdList).subscribe(response => {
            this.notificationService.addSuccessMessage('Unassign Site', `Devices points successfully unassigned from site`);
        }, (err) => {
            this.notificationService.addErrorMessage('Unassign Site', err);
        });
    }

    public showAssignDialog() {
        this.dialogConfigService.showAssignDialog(this.selectedDeviceIdList, ASSIGN_BY.DEVICES);
    }

    public goToGateway(event: MouseEvent,  device: ViewDevice) {
        event.stopPropagation();
        sessionStorage.setItem('gateways-gatewayFilter', JSON.stringify([{id: device.gatewayId}]));

        this.router.navigate(['gateways'],
            {
                queryParams: {
                    gatewayId: device.gatewayId
                }
            });
    }

    private filterDataPoints(filterParams) {
        this.isLoadingDataPoints = true;
        this.backendService.getDataPointsDetails(filterParams, this.page, PAGE_SIZE_100k, false).subscribe(response => {
            this.openedDevice.dataPoints = response.content;
            this.openedDevice.dataPointsCopy = [...response.content];
            this.openedDevice.totalRecords = response.totalElements;

            this.isLoadingDataPoints = false;
            if (this.hierarchyArray.length) {
                this.createHierarchy(this.openedDevice);
            }
        });
    }

    private removeLastNodeOfHierarchy(dpSpittedName: string[]) {
        if (dpSpittedName.length >= 1) {
            return dpSpittedName.slice(0, dpSpittedName.length - 1);
        }
        return dpSpittedName;
    }

    private reloadDevices(pageNr: number = 0, deviceFilter = [], gatewayFilter:Gateway[]=[]) {
        this.isLoading = true;
        this.selectedDeviceIdList = [];

        this.backendService.getVisibleDevicesBySite(pageNr, this.rows, deviceFilter, gatewayFilter)?.subscribe((response: any) => {
            this.devices = response.content;
            this.devices = this.devices.map(device => {
                return Object.assign({}, device, { isExpanded: device.id === this.expandByParameter });
            });

            this.totalRecords = response.totalElements;
            this.isLoading = false;
        });
    }

    private createFullName(node: any) {
        while (node.parent !== undefined) {
            return (this.createFullName(node.parent)).concat(this.hierarchySeparator).concat(node.label)
        }
        return node.label;
    }

    private createHierarchyStructure(list: string[][]) {
        let hierarchy = {};
        let root;
        list.forEach(item => {
            root = hierarchy;
            item.forEach(text => {
                let next_root = root[text];
                if (!next_root) {
                    next_root = [];
                    root[text] = next_root;
                }
                root = next_root;
            });
        })
        return hierarchy;
    }

    private transformHierarchy(hierarchy) {
        let result = []
        Object.keys(hierarchy).forEach((item) => {
            result.push(
                {
                    'label': item,
                    'data': item,
                    "expandedIcon": "pi pi-sitemap",
                    "collapsedIcon": "pi pi-sitemap",
                    'children': this.transformHierarchy(hierarchy[item])
                })
        });
        return result;
    }

    public toggleSelectAllDevices() {
        this.devices = this.devices.map(device => {
            device.selected = this.allDevicesSelected;
            return device;
        });
        this.updateSelectedDeviceIdList();
    }


    public deleteSelectedDevices() {
        this.backendService.deleteDevicesByIdList(this.selectedDeviceIdList).subscribe(_ => {
            this.notificationService.addSuccessMessage('Delete Device', 'Device deleted successfully');
            this.selectedDeviceIdList = [];
            this.navigationService.setQueryParameter(this.route, { 'selected': '' });
            this.reloadDevices();
        }
        );
    }

    updateSelectedDevice() {
        this.allDevicesSelected = false;
        this.updateSelectedDeviceIdList();
    }

    updateSelectedDeviceIdList() {
        this.selectedDeviceIdList = this.devices.filter(value => value.selected).map(value => value.id);
    }
}
