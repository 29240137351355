<h2 class="cbms-h2">Reports</h2>

<div class="grid align-items-center">
    <div class="col-3">
        <h3 class="cbms-text-size25">Override Report</h3>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button cbms-button__turquoise-bg btn-info export-button cbms-margin-left"
            (click)="showTooltipOverridesReport.toggle($event)"
        >
            <i class="pi pi-info"></i>
        </button>
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button export-button cbms-margin-left"
            (click)="exportOverridesReport()"
            [disabled]="overrideReportRequestInProgress"
        >
            <i class="pi pi-download"></i>
        </button>

        <p-overlayPanel #showTooltipOverridesReport [style]="{ width: '550px' }">
            <ng-template pTemplate="content">
                <h3>Override Report</h3>
                <p class="cbms-margin-bottom05">
                    The Override Report examines each data point within a
                    customer's tenant, specifically searching for three
                    distinct statuses, as outlined below. If any of these
                    statuses are detected, the data point is included in the
                    report with details of the Customer, Site and overridden
                    status. Please note, data points can have more than one
                    override status and are treated as singular entities.
                </p>
                <p class="cbms-margin-bottom05">
                    <b>Priority 8 (P8)</b> - Standard software override. Can
                    be removed via Main Application or Dashboards.
                </p>
                <p class="cbms-margin-bottom05">
                    <b>Priority 1 (P1)</b> - An emergency override. Cannot
                    be removed via Main Application or Dashboards.
                </p>
                <p class="cbms-margin-bottom05">
                    <b>PHYSICAL</b> - The current value of the BACnet object
                    has been altered by a mechanism within the BACnet device
                    itself. Cannot be removed via Main Application or
                    Dashboards.
                </p>
            </ng-template>
        </p-overlayPanel>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="runOverrideReport()"
            [disabled]="overrideReportRequestInProgress || shouldDisableRunReportButton"
        >
            Run report
        </button>
    </div>
    <div class="col-2">
        <p class="cbms-vertical-align cbms-min_height_36">
            <span class="cbms-text-size18"
                ><b>Last run date:&nbsp;&nbsp;</b></span
            >
            {{ overideReportDownloadDate | date: 'dd.MM.yyy, HH:mm:ss' }}
        </p>
    </div>
    <div class="col-2">
        <p
            *ngIf="overrideReportRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18 in-progress-label"
                ><b>{{
                    overideReportRunningStatus + '... ' + overrideReportProgressPercentage + ' %'
                }}</b></span
            >
        </p>
        <p
            *ngIf="!overrideReportRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18"
                ><b>{{ overideReportRunningStatus }}</b></span
            >
        </p>
    </div>
    <div class="col-1">
        <div
            class="cbms-spinner-wrapper"
            *ngIf="overrideReportRequestInProgress"
        >
            <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
        </div>
    </div>
</div>

<div class="grid align-items-center">
    <div class="col-3">
        <h3 class="cbms-text-size25">Site Connectivity Report</h3>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button cbms-button__turquoise-bg btn-info export-button cbms-margin-left"
            (click)="showTooltipExportSiteReport.toggle($event)"
        >
            <i class="pi pi-info"></i>
        </button>
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button export-button cbms-margin-left"
            (click)="exportSiteConnectivityReport()"
            [disabled]="siteConnectivityRequestInProgress"
        >
            <i class="pi pi-download"></i>
        </button>

        <p-overlayPanel #showTooltipExportSiteReport [style]="{ width: '550px' }">
            <ng-template pTemplate="content">
                <h3>Site Connectivity Report</h3>
                <p class="cbms-margin-bottom05">
                    The Site Connectivity Report examines data collected
                    within a customer tenant at 15-minute intervals over a
                    7-day period (168 hours) and provides a percentage value
                    based on the samples gathered at Site level. If at least
                    one data sample is collected at Site level within the
                    specified timeframe, the Site is considered to be
                    online.
                </p>
            </ng-template>
        </p-overlayPanel>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="runSiteConnectivityReport()"
            [disabled]="siteConnectivityRequestInProgress || shouldDisableRunReportButton"
        >
            Run report
        </button>
    </div>
    <div class="col-2">
        <p class="cbms-vertical-align cbms-min_height_36">
            <span class="cbms-text-size18"
                ><b>Last run date:&nbsp;&nbsp;</b></span
            >
            {{ siteConnectivityDownloadDate | date: 'dd.MM.yyy, HH:mm:ss' }}
        </p>
    </div>
    <div class="col-2">
        <p
            *ngIf="siteConnectivityRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18 in-progress-label"
                ><b>{{
                    siteConnectivityStatus +
                        '... ' +
                        siteConnectivityProgressPercentage +
                        ' %'
                }}</b></span
            >
        </p>
        <p
            *ngIf="!siteConnectivityRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18"
                ><b>{{ siteConnectivityStatus }}</b></span
            >
        </p>
    </div>
    <div class="col-1">
        <div
            class="cbms-spinner-wrapper"
            *ngIf="siteConnectivityRequestInProgress"
        >
            <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
        </div>
    </div>
</div>

<div class="grid align-items-center">
    <div class="col-3">
        <h3 class="cbms-text-size25">Device Connectivity Report</h3>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button cbms-button__turquoise-bg btn-info export-button cbms-margin-left"
            (click)="showTooltipExportDeviceReport.toggle($event)"
        >
            <i class="pi pi-info"></i>
        </button>
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button export-button cbms-margin-left"
            (click)="exportDeviceConnectivityReport()"
            [disabled]="deviceConnectivityRequestInProgress"
        >
            <i class="pi pi-download"></i>
        </button>

        <p-overlayPanel #showTooltipExportDeviceReport [style]="{ width: '550px' }">
            <ng-template pTemplate="content">
                <h3>Device Connectivity Report</h3>
                <p class="cbms-margin-bottom05">
                    The Device Connectivity Report examines data collected
                    within a customer tenant at 15-minute intervals over a
                    7-day period (168 hours) and provides a percentage value
                    based on the samples gathered at Device level. If at
                    least one data sample is collected at Device level
                    within the specified timeframe, the Device is considered
                    to be online.
                </p>
            </ng-template>
        </p-overlayPanel>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="runDeviceConnectivityReport()"
            [disabled]="deviceConnectivityRequestInProgress || shouldDisableRunReportButton"
        >
            Run report
        </button>
    </div>
    <div class="col-2">
        <p class="cbms-vertical-align cbms-min_height_36">
            <span class="cbms-text-size18"
                ><b>Last run date:&nbsp;&nbsp;</b></span
            >
            {{ deviceConnectivityDownloadDate | date: 'dd.MM.yyy, HH:mm:ss' }}
        </p>
    </div>
    <div class="col-2">
        <p
            *ngIf="deviceConnectivityRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18 in-progress-label"
                ><b>{{
                    deviceConnectivityStatus +
                        '... ' +
                        deviceConnectivityReportProgressPercentage +
                        ' %'
                }}</b></span
            >
        </p>
        <p
            *ngIf="!deviceConnectivityRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18"
                ><b>{{ deviceConnectivityStatus }}</b></span
            >
        </p>
    </div>
    <div class="col-1">
        <div
            class="cbms-spinner-wrapper"
            *ngIf="deviceConnectivityRequestInProgress"
        >
            <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
        </div>
    </div>
</div>

<div class="grid align-items-center">
    <div class="col-3">
        <h3 class="cbms-text-size25">Data Point Integrity Report</h3>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button cbms-button__turquoise-bg btn-info export-button cbms-margin-left"
            (click)="showTooltipExportDataIntegrityReport.toggle($event)"
        >
            <i class="pi pi-info"></i>
        </button>
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button export-button cbms-margin-left"
            (click)="exportDatapointIntegrityReport()"
            [disabled]="datapointIntegrityRequestInProgress"
        >
            <i class="pi pi-download"></i>
        </button>

        <p-overlayPanel #showTooltipExportDataIntegrityReport [style]="{ width: '550px' }">
            <ng-template pTemplate="content">
                <h3>Data Point Integrity Report</h3>
                <p class="cbms-margin-bottom05">
                    The Data Point Integrity Report scans every data point
                    under a customer tenant and performs a comparison on the
                    data point name and the units of measurements between
                    the database and the live properties of the data point.
                </p>
                <p class="cbms-margin-bottom05">
                    If an anomaly occurs, the data point will be added to
                    the report with details of the customer, site, fault
                    code; MISMATCH or NOT_FOUND and a true or false outcome
                    for each comparable term.
                </p>
                <p class="cbms-margin-bottom05">
                    <b>MISMATCH</b> - Data point name or units of
                    measurements differ from the Optimum CC database.
                </p>
                <p class="cbms-margin-bottom05">
                    <b>NOT_FOUND</b> - Data point no longer exists on the
                    controller.
                </p>
            </ng-template>
        </p-overlayPanel>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="runDatapointIntegrityReport()"
            [disabled]="datapointIntegrityRequestInProgress || shouldDisableRunReportButton"
        >
            Run report
        </button>
    </div>
    <div class="col-2">
        <p class="cbms-vertical-align cbms-min_height_36">
            <span class="cbms-text-size18"
                ><b>Last run date:&nbsp;&nbsp;</b></span
            >
            {{
                datapointIntegrityReportDownloadDate
                    | date: 'dd.MM.yyy, HH:mm:ss'
            }}
        </p>
    </div>
    <div class="col-2">
        <p
            *ngIf="datapointIntegrityRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18 in-progress-label"
                ><b>{{
                    datapointIntegrityReportStatus +
                        '... ' +
                        datapointIntegrityReportProgressPercentage +
                        ' %'
                }}</b></span
            >
        </p>
        <p
            *ngIf="!datapointIntegrityRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18"
                ><b>{{ datapointIntegrityReportStatus }}</b></span
            >
        </p>
    </div>
    <div class="col-1">
        <div
            class="cbms-spinner-wrapper"
            *ngIf="datapointIntegrityRequestInProgress"
        >
            <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
        </div>
    </div>
</div>

<div class="grid align-items-center">
    <div class="col-3">
        <h3 class="cbms-text-size25">Faulty Sensor Report</h3>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button cbms-button__turquoise-bg btn-info export-button cbms-margin-left"
            (click)="faultyReportInfo.toggle($event)"
        >
            <i class="pi pi-info"></i>
        </button>
        <button
            pButton
            class="p-button-raised p-button-text cbms-row-button export-button cbms-margin-left"
            (click)="exportDatapointFaultyReport()"
            [disabled]="datapointFaultyRequestInProgress"
        >
            <i class="pi pi-download"></i>
        </button>

        <p-overlayPanel #faultyReportInfo [style]="{ width: '550px' }">
            <ng-template pTemplate="content">
                <h3>Datapoint Faulty Report</h3>
                <p class="cbms-margin-bottom05">The Faulty Sensor Report examines all analogue input data points with units either degrees celsius (°C) or relative humidity (%RH) and compares them to the statuses below. If any of these statuses are detected, the data point is included in the report with details of the Customer, Site and it will be populated True in the corresponding column on the report.</p>
                <p class="cbms-margin-bottom05"><b>Intermittent</b> - indicates that over 25% of data samples are either missing and/or Null over the selected day range.</p>
                <p class="cbms-margin-bottom05"><b>Zero</b> - indicates the data point has been reading 0 (zero) for the selected day range.</p>
                <p class="cbms-margin-bottom05"><b>Static</b> - indicates the data point has flatlined i.e. the reading has not changed for the selected day range.</p>
                <p class="cbms-margin-bottom05"><b>Out of Range</b> - indicates the data point has either been above or below threshold for the selected date range, see default thresholds below;</p>

                <h3>Default Properties</h3>
                <p class="cbms-margin-bottom05"><b>Temperature Threshold:</b> High = 120°C / Low = -20°C</p>
                <p class="cbms-margin-bottom05"><b>Relative Humidity Threshold:</b> High = 100%RH / Low = 0%RH</p>
                <p class="cbms-margin-bottom05"><b>Day Range</b> = 3 days</p>
                <p>The user also has the ability to amend these default properties via a pop up when they first click the Run Report button</p>
            </ng-template>
        </p-overlayPanel>
    </div>
    <div class="col-2">
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="showFaultySensorReportDialog()"
            [disabled]="datapointFaultyRequestInProgress || shouldDisableRunReportButton"
        >
            Run report
        </button>
    </div>
    <div class="col-2">
        <p class="cbms-vertical-align cbms-min_height_36">
            <span class="cbms-text-size18"
                ><b>Last run date:&nbsp;&nbsp;</b></span
            >
            {{
                datapointFaultyReportDownloadDate | date: 'dd.MM.yyy, HH:mm:ss'
            }}
        </p>
    </div>
    <div class="col-2">
        <p
            *ngIf="datapointFaultyRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18 in-progress-label"
                ><b>{{
                    datapointFaultyReportStatus +
                        '... ' +
                        datapointFaultyReportProgressPercentage +
                        ' %'
                }}</b></span
            >
        </p>
        <p
            *ngIf="!datapointFaultyRequestInProgress"
            class="cbms-vertical-align cbms-min_height_36"
        >
            <span class="cbms-text-size18"
                ><b>{{ datapointFaultyReportStatus }}</b></span
            >
        </p>
    </div>
    <div class="col-1">
        <div
            class="cbms-spinner-wrapper"
            *ngIf="datapointFaultyRequestInProgress"
        >
            <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
        </div>
    </div>
</div>

<p-dialog
    *ngIf="showFaultySensorReportProperties"
    [(visible)]="showFaultySensorReportProperties"
    [style]="{ width: '960px', height: '70%' }"
    header="Faulty Sensor Report Properties"
    [modal]="true"
    styleClass="p-fluid"
>
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-4 flex align-items-center">
                <span>Temperature Threshold (&deg; C)</span>
            </div>
            <div class="col-2 flex align-items-center justify-content-evenly">
                <span>High</span>
                <p-inputNumber
                    mode="decimal"
                    [useGrouping]="false"
                    class="cbms-input__small-size"
                    id="outOfRangeHighCelsius"
                    name="outOfRangeHighCelsius"
                    [min]="-99"
                    [max]="999"
                    [(ngModel)]="outOfRangeHighCelsius"
                />
            </div>
            <div class="col-2 flex align-items-center justify-content-evenly">
                <span>Low</span>
                <p-inputNumber
                    mode="decimal"
                    [useGrouping]="false"
                    class="cbms-input__small-size"
                    id="outOfRangeLowCelsius"
                    name="outOfRangeLowCelsius"
                    [min]="-100"
                    [max]="998"
                    [(ngModel)]="outOfRangeLowCelsius"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col-4 flex align-items-center">
                <span>Relative Humidity Threshold (%RH)</span>
            </div>
            <div class="col-2 flex align-items-center justify-content-evenly">
                <span>High</span>
                <p-inputNumber
                    mode="decimal"
                    [useGrouping]="false"
                    class="cbms-input__small-size"
                    id="outOfRangeHighRelativeHumidity"
                    name="outOfRangeHighRelativeHumidity"
                    [min]="0"
                    [max]="100"
                    [(ngModel)]="outOfRangeHighRelativeHumidity"
                />
            </div>
            <div class="col-2 flex align-items-center justify-content-evenly">
                <span>Low</span>
                <p-inputNumber
                    mode="decimal"
                    [useGrouping]="false"
                    class="cbms-input__small-size"
                    id="outOfRangeLowRelativeHumidity"
                    name="outOfRangeLowRelativeHumidity"
                    [min]="0"
                    [max]="100"
                    [(ngModel)]="outOfRangeLowRelativeHumidity"
                />
            </div>
        </div>
        <div class="grid">
            <div class="col flex align-items-center">
                <span>Day range</span>
                <p-dropdown class="cbms-margin-left" name="dayRange" [options]="dayRangeOptions"
                        [(ngModel)]="selectedDayRange" placeholder="Select day range" optionLabel="name">
                    </p-dropdown>
            </div>
        </div>
        <div class="grid">
            <div class="col">
                <h3>Default properties</h3>
                <p><b>Temperature Threshold:</b> High = 120°C / Low = -20°C</p>
                <p>
                    <b>Relative Humidity Threshold:</b> High = 100%RH / Low =
                    0%RH
                </p>
                <p><b>Day Range</b> = 3 days</p>
            </div>
        </div>
        <div class="grid">
            <div class="col">
                <p class="error" *ngIf="showDatapointFaultyReportError">Low threshold value must be smaller than high threshold value!</p>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            type="submit"
            data-cy="send-btn"
            label="Use Default Properties"
            (click)="runWithDefaultProperties()"
        ></button>
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
            type="submit"
            data-cy="send-btn"
            label="Confirm"
            (click)="runDatapointFaultyReport()"
        ></button>
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
            label="Close"
            data-cy="close-btn"
            (click)="hideFaultySensorReportDialog()"
        ></button>
    </ng-template>
</p-dialog>
