import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { Customer } from 'src/app/models/customer';
import { Site } from 'src/app/models/site';
import { Role, RoleResource, User } from 'src/app/models/user';
import { BackendService } from 'src/app/services/backend/backend.service';
import { UtilsService } from 'src/app/services/utils/util.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { DataService } from 'src/app/services/data/data.service';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { FilterService } from 'src/app/components/filter/filter.service';

const ROLES_MAPPER = {
    CUSTOMER_USER: 'Customer User',
    CUSTOMER_OPERATOR: 'Customer Operator',
    GLOBAL_ADMIN: 'System Admin',
    CUSTOMER_ADMIN: 'Customer Admin',
};

//User roles
const GLOBAL_ADMIN_ROLE = 'GLOBAL_ADMIN';
const CUSTOMER_ADMIN_ROLE = 'CUSTOMER_ADMIN';
const CUSTOMER_USER_ROLE = 'CUSTOMER_USER';
const CUSTOMER_OPERATOR_ROLE = 'CUSTOMER_OPERATOR';
const FULL_ESTATE = 'Full Estate';
@Component({
    selector: 'cbms-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {

    public userList: User[] = [];
    public emptyUser = {
        id: null,
        username: '',
        resourcePermissionList: [],
        customerList: [],
        role: '',
        expiryDate: '',
        firstName: '',
        lastName: '',
        status: 'ACTIVE',
        isSendEmailInvite: true,
        fullEstate: false,
    };
    public totalRecords: number = null;
    public selectedUserList: User[] = [];
    public user: User;
    public userDialog: boolean = false;
    public submitted: boolean = false;
    public userRoles: Role[] = [
        { name: ROLES_MAPPER[CUSTOMER_USER_ROLE], code: CUSTOMER_USER_ROLE },
        {
            name: ROLES_MAPPER[CUSTOMER_OPERATOR_ROLE],
            code: CUSTOMER_OPERATOR_ROLE,
        },
        { name: ROLES_MAPPER[CUSTOMER_ADMIN_ROLE], code: CUSTOMER_ADMIN_ROLE },
    ];
    public userRoleList: Role[];
    public selectedUserRole: Role;
    public pageSize = this.utilsService.getDataPointsPageSize();

    public selectedResources: RoleResource[] = [];
    public elementShouldDisplayFlagMap: {};
    public customerList: Customer[];
    public siteList: Site[];
    public selectedCustomer: Customer;
    public selectedSiteList: Site[] = [];
    public selectedSiteIdList: string[] = [];
    public isLoading: boolean = false;
    
    private customerId: string;
    private customerName: string;
    
    private userListSubscription: Subscription;
    private customersSubscription: Subscription;
    private sitesSubscription: Subscription;

    public cols: any[] = [
        { field: 'id', header: 'ID', position: 1, width: '150px' },
        {
            field: 'firstName',
            header: 'First Name',
            position: 2,
            width: '100px',
        },
        { field: 'lastName', header: 'Last Name', position: 3, width: '100px' },
        { field: 'username', header: 'Email', position: 4, width: '150px' },
        { field: 'role', header: 'User Role', position: 5, width: '100px' },
        {
            field: 'lastLoginTimestamp',
            header: 'Last Seen',
            position: 6,
            width: '100px',
        },
        { field: 'status', header: 'Status', position: 6, width: '100px' },
    ];
    public spinnerOn: boolean = true;

    constructor(
        private backendService: BackendService,
        private utilsService: UtilsService,
        private notificationService: NotificationService,
        private dataService: DataService,
        private confirmationService: ConfirmationService,
        private filterService: FilterService
    ) {}

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('userManagement');
        this.filterService.customerFilterChanges$.subscribe((data) => {
            this.customerId = data.selectedCustomer.id;
            this.customerName = data.selectedCustomer?.name;
            this.getUserList(0, this.pageSize);
            this.getCustomerList();
        });
        this.getUserList(0, this.pageSize);
        this.getCustomerList();
    }

    ngOnDestroy(): void {
        if (this.userListSubscription) {
            this.userListSubscription.unsubscribe();
        }
    }

    getUserList(pageNumber: number, pageSize: number, sortOption?) {
        this.spinnerOn = true;
        const userRole = sessionStorage.getItem('userRole');
        if (this.userListSubscription) {
            this.userListSubscription.unsubscribe();
        }
        if (userRole === 'ROLE_' + CUSTOMER_ADMIN_ROLE) {
            this.userListSubscription = this.backendService
            .getUsersByCustomer(pageNumber, pageSize, this.customerId, sortOption)
            .subscribe({
                next: (response) => {
                    this.userList = response.content;
                    this.totalRecords = response.totalElements;
                    this.spinnerOn = false;
                },
                error: (err) => console.log('err: ', err),
            });
        } else {
            this.userListSubscription = this.backendService
            .getUsers(pageNumber, pageSize, sortOption)
            .subscribe({
                next: (response) => {
                    this.userList = response.content;
                    this.totalRecords = response.totalElements;
                    this.spinnerOn = false;
                },
                error: (err) => console.log('err: ', err),
            });
        }
    }

    getCustomerList() {
        if (this.customersSubscription) {
            this.customersSubscription.unsubscribe();
        }
        this.customersSubscription = this.backendService
            .getCustomers()
            .subscribe((customerList) => {
                this.customerList = customerList.map((customer) => {
                    return { id: customer.id, name: customer.name };
                });
            });
    }

    fetchCustomerList($event) {
        if (this.customerList) {
            return;
        }
        this.getCustomerList();
    }

    getSiteList(customerId: string) {
        if (this.sitesSubscription) {
            this.sitesSubscription.unsubscribe();
        }
        this.sitesSubscription = this.backendService
            .getSitesByCustomer(customerId)
            .subscribe((response) => {
                this.siteList = this.utilsService.orderListByFieldName(
                    response.content,
                    'name'
                );
            });
    }
    
    updateUserRoleList() {
        this.userRoleList = [...this.userRoles];
        if (sessionStorage.getItem('userRole') === 'ROLE_ADMIN') {
            this.userRoleList.push({ name: ROLES_MAPPER[GLOBAL_ADMIN_ROLE], code: GLOBAL_ADMIN_ROLE });
        }
    }

    getUserCustomerList() {
        let customerList = [];

        this.selectedResources.map((resource: any) => {
            let index = customerList.findIndex(
                (customer) => customer.id === resource.customer.id
            );
            if (index !== -1) {
                customerList[index].siteList = [
                    ...customerList[index].siteList,
                    resource.site,
                ];
            } else {
                customerList.push({
                    id: resource.customer.id,
                    name: resource.customer.name,
                    siteList: [resource.site],
                });
            }
        });

        return customerList;
    }

    openNew() {
        this.submitted = false;
        this.user = { ...this.emptyUser };
        this.selectedResources = [];
        this.selectedSiteIdList = [];
        this.selectedUserRole = null;
        this.userDialog = true;
        this.computeelementShouldDisplayFlagMap(this.user.role);
    }

    editUser(user: User) {
        this.submitted = false;
        this.user = { ...user };
        this.updateUserRoleList();
        this.populateResourceTableFromUserCustomerList(this.user);
        this.populateUserRoleDD(this.user.role);
        this.populateCustomerIfNeeded(this.user);
        this.computeelementShouldDisplayFlagMap(this.user.role);
        this.userDialog = true;
    }

    populateResourceTableFromUserCustomerList(user: User) {
        this.selectedResources = [];
        this.selectedSiteIdList = [];

        if (this.user.fullEstate) {
            this.selectedResources.push({
                customer: { id: user.customerList[0].id, name: user.customerList[0].name },
                site: { name: FULL_ESTATE, id: 'full_estate' },
            });

            return;
        }

        user.customerList.forEach((customer) => {
            customer.siteList.forEach((site) => {
                this.selectedResources.push({
                    customer: { id: customer.id, name: customer.name },
                    site: { id: site.id, name: site.name },
                });
                this.selectedSiteIdList.push(site.id);
            });
        });
    }

    populateUserRoleDD(role: string) {
        this.selectedUserRole = {
            name: ROLES_MAPPER[role],
            code: role,
        };
    }

    private computeelementShouldDisplayFlagMap(role: string) {
        if (role === GLOBAL_ADMIN_ROLE) {
            this.elementShouldDisplayFlagMap = {};
        } else if (role === CUSTOMER_ADMIN_ROLE) {
            this.elementShouldDisplayFlagMap = {
                customerDropdown: true,
            };
        } else {
            this.elementShouldDisplayFlagMap = {
                customerDropdown: true,
                siteDropdown: true,
                applyButton: true,
                resourcesTable: true,
                fullEstateCheckbox: true,
            };
        }
    }

    deleteSelectedUsers() {
        this.backendService
            .deleteSelectedUsers(this.selectedUserList.map((user) => user.id))
            .subscribe(
                (response) => {
                    this.notificationService.addSuccessMessage(
                        'Success',
                        'Users successfully deleted.'
                    );
                    this.selectedUserList = [];
                    this.getUserList(0, this.pageSize);
                },
                (err) => {
                    this.notificationService.addErrorMessage(
                        'Error',
                        'Users could not be deleted. Please try again.'
                    );
                }
            );
    }

    deleteUser(user: User) {
        // todo: implemnet api call to delete user
        this.backendService.deleteUser(user.id).subscribe(
            (response) => {
                this.notificationService.addSuccessMessage(
                    'Success',
                    'User successfully deleted.'
                );
            },
            (err) => {
                this.notificationService.addErrorMessage(
                    'Error',
                    'User could not be deleted. Please try again.'
                );
            }
        );
    }

    hideDialog() {
        this.userDialog = false;
    }

    createUser() {
        const userModel = {
            id: null,
            username: this.user.username,
            role: this.selectedUserRole?.code,
            expiryDate: null,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            status: 'ACTIVE',
            customerList: this.getCustomerListByUserRole(),
            isSendEmailInvite: this.user.isSendEmailInvite,
            fullEstate: this.user.fullEstate,
        };

        this.backendService.createUserWithPermissions(userModel).subscribe(
            (response) => {
                this.notificationService.addSuccessMessage(
                    'Success',
                    'User successfully created.'
                );
                this.userDialog = false;
                this.getUserList(0, this.pageSize);
            },
            (err) => {
                this.notificationService.addErrorMessage(
                    'Error',
                    'User could not be created. Please try again.'
                );
            }
        );
    }

    updateUser() {
        const userModel = {
            id: this.user.id,
            username: this.user.username,
            role: this.selectedUserRole?.code,
            expiryDate: this.user.expiryDate,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            status: 'ACTIVE',
            customerList: this.getCustomerListByUserRole(),
            isSendEmailInvite: this.user.isSendEmailInvite,
            fullEstate: this.user.fullEstate
        };

        this.backendService.updateUserWithPermissions(userModel).subscribe(
            (response) => {
                this.notificationService.addSuccessMessage(
                    'Success',
                    'User successfully updated.'
                );
                this.userDialog = false;
                this.getUserList(0, this.pageSize);
            },
            (err) => {
                this.notificationService.addErrorMessage(
                    'Error',
                    'User could not be updated. Please try again.'
                );
            }
        );
    }

    private getCustomerListByUserRole() {
        if (this.selectedUserRole?.code === GLOBAL_ADMIN_ROLE) {
            return [];
        } else if (this.selectedUserRole?.code === CUSTOMER_ADMIN_ROLE || this.user.fullEstate) {
            return [
                {
                    id: this.selectedCustomer.id,
                    name: this.selectedCustomer.name,
                    siteList: [],
                },
            ];
        } else {
            return this.getUserCustomerList();
        }
    }

    exportUserData() {
        this.isLoading = true;
        this.backendService.exportUserDataFile(this.customerId).subscribe(
            (response) => {
                const contentDisposition = response.headers.get('Content-Disposition');
                let filename = `${ this.customerName } Users Data ${this.getDateNowAsText()}.xlsx`

                if (contentDisposition) {
                    const matches = contentDisposition.match(/filename="(.+?)"/);
                    if (matches && matches[1]) {
                    filename = matches[1];
                    }
                }
                // Save the file
                const blob = response.body as Blob;
                saveAs(blob, filename);
                this.isLoading = false;
            },
            (err) => {
                console.log(err);
                this.isLoading = false;
            }
        );

    }

    saveChanges() {
        this.submitted = true;

        if (
            !this.user.firstName ||
            !this.user.lastName ||
            !this.user.username ||
            !this.selectedUserRole
        ) {
            return;
        }

        if (
            this.selectedResources.length == 0 &&
            [CUSTOMER_OPERATOR_ROLE, CUSTOMER_USER_ROLE].includes(
                this.selectedUserRole.code
            )
        ) {
            return;
        }

        if (this.selectedUserRole?.code === GLOBAL_ADMIN_ROLE) {
            this.confirmationService.confirm({
                message: `You are assigning the role of system admin, this role is not intended for customers.<br /> <br />
						  Assigning this role to a customer is a violation of GDPR.<br />  <br />
			 	 		  Are you sure you want to proceed?`,
                accept: () => {
                    //Actual logic to perform a confirmation
                    this.sendChanges();
                },
            });
            return;
        }

        this.sendChanges();
    }

    sendChanges() {
        if (this.user.id) {
            this.updateUser();
            return;
        }
        this.createUser();
    }

    onRoleChosen() {
        this.selectedCustomer = null;
        this.selectedSiteList = [];
        this.selectedSiteIdList = [];
        this.selectedResources = [];
        this.submitted = false;
        this.computeelementShouldDisplayFlagMap(this.selectedUserRole.code);
    }

    onCustomerChosen() {
        this.getSiteList(this.selectedCustomer.id);
    }

    applyRole() {
        if (!this.user.fullEstate) {
            this.selectedSiteList.map((selectedSite) => {
                if (this.selectedSiteIdList.includes(selectedSite.id)) {
                    return;
                }
                this.selectedSiteIdList.push(selectedSite.id);
                this.selectedResources.push({
                    customer: {
                        name: this.selectedCustomer.name,
                        id: this.selectedCustomer.id,
                    },
                    site: { name: selectedSite.name, id: selectedSite.id },
                });
            });
    
            this.selectedSiteList = [];
        } else {
            this.selectedResources = [];
            this.selectedResources.push({
                customer: {
                    name: this.selectedCustomer.name,
                    id: this.selectedCustomer.id,
                },
                site: { name: FULL_ESTATE, id: 'full_estate' },
            });
        }
    }

    removeResource(resource: RoleResource) {
        this.selectedSiteIdList = this.selectedSiteIdList.filter(
            (siteId) => siteId !== resource.site.id
        );
        this.selectedResources = this.selectedResources.filter(
            (selectedResource) => selectedResource.site.id !== resource.site.id
        );
    }

    loadDPLazy(event: LazyLoadEvent) {
        const pageNumber = event.first !== 0 ? (event.first / this.pageSize) : 0;
        let sortOptions = null;

        if (event.sortField) {
            sortOptions = {
                sortField: event.sortField,
                sortOrder: event.sortOrder === 1 ? 'asc' : 'desc',
            };
        }
        if (event.globalFilter) {
            sortOptions = Object.assign({}, {...sortOptions, email: event.globalFilter});
        }

        this.getUserList(pageNumber, this.pageSize, sortOptions)
    }

    private populateCustomerIfNeeded(user: User) {
        if (user.role == CUSTOMER_ADMIN_ROLE || user.fullEstate) {
            let customer = user.customerList[0];
            this.selectedCustomer = {
                id: customer.id,
                name: customer.name,
            };
        }
    }

    private getDateNowAsText() {
        let date = new Date();
        let time = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()} ${date.getHours()}${date.getMinutes()}`;
        return time;
    }

    protected readonly GLOBAL_ADMIN_ROLE = GLOBAL_ADMIN_ROLE;
    protected readonly CUSTOMER_ADMIN_ROLE = CUSTOMER_ADMIN_ROLE;
}
