<ng-container>
    <div [pTooltip]="'No data points selected.'"
         showDelay="1000"
         hideDelay="500"
         [tooltipDisabled]="selectedDataPoints.length">
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="showDeleteTwinDialog()"
                [disabled]="!selectedDataPoints.length">
            <mat-icon svgIcon="delete"></mat-icon>
            Delete twin from data point
        </button>
    </div>
</ng-container>
