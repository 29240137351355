import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { AuthorizationService } from '../../services/utils/authorization.service';

@Component({
    selector: 'cbms-no-access',
    standalone: true,
    imports: [],
    templateUrl: './no-access.component.html',
    styleUrl: './no-access.component.scss',
})
export class NoAccessComponent implements OnInit {
    constructor(
        private dataService: DataService,
        private authorizationService: AuthorizationService
    ) {}

    currentYear = new Date().getFullYear();

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('error');
    }

    logout() {
        this.authorizationService.logout();
    }
}
