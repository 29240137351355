import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    Router,
} from '@angular/router';
import { ROLE_PERMISSIONS } from '../models/user';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private authService: OAuthService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> | boolean {
        const userRolePermissionList = sessionStorage.getItem(
            'userRolePermissionList'
        );

        if (!userRolePermissionList) {
            return true;
        }

        if (this.authService.hasValidIdToken() && !userRolePermissionList.includes(ROLE_PERMISSIONS.VIEW_DATAPOINTS_TAB)) {
            this.router.navigateByUrl('/no-access');
            return false;
        }

        if (state.url.includes('/sites')) {
            return userRolePermissionList.includes(
                ROLE_PERMISSIONS.VIEW_SITES_TAB
            )
                ? true
                : this.router.navigateByUrl('/datapoints');
        }
        if (state.url.includes('/gateways')) {
            return userRolePermissionList.includes(
                ROLE_PERMISSIONS.VIEW_GATEWAYS_TAB
            )
                ? true
                : this.router.navigateByUrl('/datapoints');
        }
        if (state.url.includes('/datapoints')) {
            return userRolePermissionList.includes(
                ROLE_PERMISSIONS.VIEW_DATAPOINTS_TAB
            )
                ? true
                : this.router.navigateByUrl('/no-access');
        }
        if (state.url.includes('/devices')) {
            return userRolePermissionList.includes(
                ROLE_PERMISSIONS.VIEW_DEVICES_TAB
            )
                ? true
                : this.router.navigateByUrl('/datapoints');
        }
        if (state.url.includes('/audit')) {
            return userRolePermissionList.includes(
                ROLE_PERMISSIONS.VIEW_AUDIT_TAB
            )
                ? true
                : this.router.navigateByUrl('/datapoints');
        }
        if (state.url.includes('/settings/dashboard')) {
            return userRolePermissionList.includes(
                ROLE_PERMISSIONS.VIEW_SETTINGS_DASHBOARD_TAB
            )
                ? true
                : this.router.navigateByUrl('/settings/alert-console');
        }
        if (state.url.includes('/settings/user-management')) {
            return userRolePermissionList.includes(
                ROLE_PERMISSIONS.VIEW_SETTINGS_USER_MANAGEMENT_TAB
            )
                ? true
                : this.router.navigateByUrl('/settings/alert-console');
        }
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }
}
