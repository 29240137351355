import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataPointsComponent } from './views/data-points/data-points.component';
import { GatewaysComponent } from './views/gateways/gateways.component';
import { DevicesComponent } from './views/devices/devices.component';
import { SchedulesComponent } from './views/schedules/schedules.component';
import { SitesComponent } from './views/sites/sites.component';
import { AuditComponent } from './views/audit/audit.component';
import { ErrorComponent } from './views/error/error.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DashboardComponent as DashboardSettingsComponent } from './views/settings/dashboard/dashboard.component';
import { SettingsComponent } from './views/settings/settings.component';
import { DashboardPocComponent } from './views/dashboard-poc/dashboard-poc.component';
import { EquipmentWidgetComponent } from './views/dashboard-poc/equipment-widget/equipment-widget.component';
import { PolicyMakerComponent } from './views/settings/policy-maker/policy-maker.component';
import { UserManagementComponent } from './views/settings/user-management/user-management.component';
import { ReportsComponent } from './views/settings/reports/reports.component';
import { SmartAlertComponent } from './views/settings/smart-alert/smart-alert.component';
import { SmartAlertConsoleComponent } from './views/settings/smart-alert-console/smart-alert-console.component';
import { AuthGuard } from './guards/auth.guard';
import { isUserAuthenticatedGuard } from './guards/is-user-authenticated.guard';
import { AutoOptimisationComponent } from './views/settings/auto-optimisation/auto-optimisation/auto-optimisation.component';
import { NoAccessComponent } from './views/no-access/no-access.component';
import { SmartAlertTemplatesComponent } from './views/settings/smart-alert-templates/smart-alert-templates.component';
import { AddSmartAlertTemplateComponent } from './views/settings/smart-alert-templates/add-smart-alert-template/add-smart-alert-template.component';
import { CorporateSsoComponent } from './views/corporate-sso/corporate-sso.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'datapoints',
        pathMatch: 'full',
    },
    {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'gateways',
                component: GatewaysComponent,
            },
            {
                path: 'devices',
                component: DevicesComponent,
            },
            {
                path: 'datapoints',
                component: DataPointsComponent,
            },
            {
                path: 'schedules',
                component: SchedulesComponent,
            },
            {
                path: 'sites',
                component: SitesComponent,
            },
            {
                path: 'audit',
                component: AuditComponent,
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            {
                path: 'dashboard-poc',
                component: DashboardPocComponent,
            },
            {
                path: 'dashboard-poc/equipment',
                component: EquipmentWidgetComponent,
            },
            {
                path: 'settings',
                component: SettingsComponent,
                children: [
                    {
                        path: 'dashboard',
                        component: DashboardSettingsComponent,
                    },
                    {
                        path: 'user-management',
                        component: UserManagementComponent,
                    },
                    {
                        path: 'reports',
                        component: ReportsComponent,
                    },
                    {
                        path: 'smart-alert-templates',
                        component: SmartAlertTemplatesComponent
                    },
                    {
                        path: 'smart-alert-templates/add-smart-alert',
                        component: AddSmartAlertTemplateComponent,
                    },
                    {
                        path: 'smart-alert-templates/edit-smart-alert',
                        component: AddSmartAlertTemplateComponent,
                    },
                    {
                        path: 'smart-alert',
                        component: SmartAlertComponent,
                    },
                    {
                        path: 'alert-console',
                        component: SmartAlertConsoleComponent,
                    },
                    {
                        path: 'auto-optimisation',
                        component: AutoOptimisationComponent,
                    },

                    {
                        path: 'policy-maker',
                        component: PolicyMakerComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: 'no-access',
        component: NoAccessComponent,
    },
    {
        path: 'corporate-sso',
        component: CorporateSsoComponent,
    },
    {
        path: '**',
        redirectTo: 'datapoints',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
