import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DAYS } from 'src/app/config/constants';

@Component({
    selector: 'cbms-building-opening-time-dialog',
    templateUrl: './building-opening-time-dialog.component.html',
    styleUrl: './building-opening-time-dialog.component.scss',
})
export class BuildingOpeningTimeDialogComponent implements OnInit {
    siteSchedule: any;
    days = DAYS;
    siteName: string;
    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig
    ) {}

    ngOnInit(): void {
        this.siteSchedule = this.config.data.siteSchedule;
        this.siteName = this.config.data.siteName;
    }

    onCancel() {
        this.ref.close();
    }
}
