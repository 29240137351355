import { Component, Input } from '@angular/core';
import { MappedDataPoint } from 'src/app/models/data-point';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-delete-twin-from-data-point',
    templateUrl: './delete-twin-from-data-point.component.html',
    styleUrl: './delete-twin-from-data-point.component.scss',
})
export class DeleteTwinFromDataPointComponent {
    @Input()
    public selectedDataPoints: MappedDataPoint[];

    constructor(private dialogConfigService: DialogConfigService) {}

    public showDeleteTwinDialog() {
        this.dialogConfigService.showDeleteTwinFromDatapointDialog(
            this.selectedDataPoints
        );
    }
}
