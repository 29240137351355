import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Site, SiteTag } from '../../../models/site';
import { FilterService } from '../filter.service';
import _ from 'lodash';
import { UtilsService } from 'src/app/services/utils/util.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { merge } from 'rxjs/internal/observable/merge';
import { NO_GLOBAL_SITE_FILTERS_TABS } from 'src/app/config/constants';
import { Listbox } from 'primeng/listbox';

@Component({
    selector: 'cbms-site-filter',
    templateUrl: './site-filter.component.html',
    styleUrls: ['../filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SiteFilterComponent implements OnInit {
    siteList: Site[];
    siteListBkp: Site[];
    selectedSiteList: Site[] = [];

    siteTagList: SiteTag[];
    selectedSiteTagList: SiteTag[];

    shouldDisableFilter: boolean = false;
    siteSearchLogicalOperator: string = 'OR';
    siteFilterLabel: string = 'Sites';
    hideSiteFilter = false;

    private getSitesTagsSubscription: Subscription;
    private customerSubscription: Subscription;
    private getSitesSubscription: Subscription;
    private getLastSiteListSubscription: Subscription;
    private customerChanged: boolean = false;

    constructor(
        private utilsService: UtilsService,
        private backendService: BackendService,
        private dataService: DataService,
        private filterService: FilterService,
        private cd: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.dataService.shouldDisableFilter$.subscribe((filterName) => {
            this.shouldDisableFilter = filterName === 'sites';
        });

        this.dataService.currentTabChanges$.
            subscribe((tabName) => {
                if (!tabName) { return; }
                this.hideSiteFilter = NO_GLOBAL_SITE_FILTERS_TABS.indexOf(tabName) !== -1;
                this.cd.detectChanges();
            });

        this.customerSubscription = this.filterService.customerFilterChanges$.subscribe((data) => {
            if (data.isInitLoad) {
                this.getSitesSubscription = this.backendService.getSites().subscribe(response => {
                    this.siteList = this.utilsService.orderListByFieldName(response.content, 'name');
                    this.siteListBkp = _.cloneDeep(this.siteList);

                    this.getLastSiteListSubscription = this.backendService.getLastSiteList()?.subscribe(response => {
                        let selectedSiteListIds = response.map((site) => site.id);
                        let tempSelectedSiteList = [];

                        this.siteList.forEach((item) => {
                            if (selectedSiteListIds.indexOf(item.id) !== -1) {
                                tempSelectedSiteList.push(item);
                            }
                        });
                        this.selectedSiteList = tempSelectedSiteList;
                        this.updateSiteFilterLabel();

                        if (!_.isEqual(this.selectedSiteList, JSON.parse(sessionStorage.getItem('selectedSiteList')))) {
                            sessionStorage.setItem('selectedSiteList', JSON.stringify(this.selectedSiteList));
                            this.filterService.siteChanged(this.selectedSiteList);
                        }
                    });
                });

                this.getSitesTagsSubscription = this.backendService.getSitesTags().subscribe(tags => {
                    if (tags && tags.length) {
                        this.siteTagList = this.utilsService.orderListByFieldName(tags, 'name');
                    }
                });
            } else {
                this.resetSiteTagList();
                this.resetSiteList();
            }
        });

        this.dataService.newTagsCreatedStatus.subscribe(tagsStatus => {
            if (!tagsStatus || !tagsStatus.sitesTagsUpdated) {
                return;
            }

            this.resetSiteTagList();
            this.resetSiteList();
        });
    }

    public ngOnDestroy() {
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }

        if (this.getSitesTagsSubscription) {
            this.getSitesTagsSubscription.unsubscribe();
        }

        if (this.getSitesSubscription) {
            this.getSitesSubscription.unsubscribe();
        }

        if (this.getLastSiteListSubscription) {
            this.getLastSiteListSubscription.unsubscribe();
        }
    }

    public onFilterShow() {
        if (this.customerChanged) {
            this.siteList = [];
            this.backendService.getSites().subscribe(response => {
                this.siteList = this.utilsService.orderListByFieldName(response.content, 'name');
                this.customerChanged = false;
            });

            this.siteTagList = [];
            this.getSitesTagsSubscription = this.backendService.getSitesTags().subscribe(tags => {
                if (tags && tags.length) {
                    this.siteTagList = this.utilsService.orderListByFieldName(tags, 'name');
                }
            });
        }
    }

    public onSiteChosen() {
        sessionStorage.setItem('selectedSiteList', JSON.stringify(this.selectedSiteList));

        const siteIdListToSave = this.siteList.length === this.selectedSiteList.length ? [] : this.selectedSiteList.map((site) => site.id);
        this.backendService.updateLastSiteList(siteIdListToSave).subscribe();
        this.filterService.siteChanged(this.selectedSiteList);
    }

    public clearSelection() {
        this.selectedSiteList = [];
        this.updateSiteFilterLabel();

        sessionStorage.setItem('selectedSiteList', JSON.stringify(this.selectedSiteList));
        this.backendService.updateLastSiteList([]).subscribe();
        this.filterService.siteChanged(this.selectedSiteList);
    }

    public tagLogicalOperatorChanged() {
        this.filterSitesList();
    }

    public siteTagListChanged(event: any) {
        this.filterSitesList();
    }

    public siteListChanged(event: any) {
        this.updateSiteFilterLabel();
    }

    private filterSitesList() {
        if (this.selectedSiteTagList.length === 0) {
            this.siteList = [...this.siteListBkp];
            return;
        }

        if (this.siteSearchLogicalOperator === 'OR') {
            this.siteList = this.orFilter();
            return;
        }

        if (this.siteSearchLogicalOperator === 'AND') {
            this.siteList = this.andFilter();
            return;
        }

        if (this.siteSearchLogicalOperator === 'NOT') {
            this.siteList = this.notFilter();
            return;
        }
    }

    private orFilter() {
        return [...this.siteListBkp.filter(site => this.selectedSiteTagList.some(tag => site.tags.map(tag => tag.id).includes(tag.id)))];
    }

    private andFilter() {
        return [...this.siteListBkp.filter(site => this.selectedSiteTagList.every(tag => site.tags.map(tag => tag.id).includes(tag.id)))];
    }

    private notFilter() {
        return [...this.siteListBkp.filter(site => !this.selectedSiteTagList.some(tag => site.tags.map(tag => tag.id).includes(tag.id)))];
    }

    private resetSiteList() {
        this.siteList = [];
        this.selectedSiteList = [];
        this.customerChanged = true;

        this.updateSiteFilterLabel();

        sessionStorage.setItem('selectedSiteList', JSON.stringify(this.selectedSiteList));
        this.backendService.updateLastSiteList([]).subscribe();
    }

    private resetSiteTagList() {
        this.siteTagList = [];
        this.selectedSiteTagList = [];
    }

    private updateSiteFilterLabel() {
        if (this.selectedSiteList.length === 0) {
            this.siteFilterLabel = 'Sites';
        }
        if (this.selectedSiteList.length === 1) {
            this.siteFilterLabel = `${this.selectedSiteList[0].name}`;
        }
        if (this.selectedSiteList.length > 1) {
            this.siteFilterLabel = `${this.selectedSiteList.length} Sites selected`;
        }
    }
}
