<ng-container *ngIf="viewRules">
    <h2 class="cbms-h2">SMART Alert Rules</h2>
    <div class="table-wrapper">
        <p-table
            *ngIf="alertRules$ | async as dataSource; else loading"
            class="cbms-generic-table-style"
            #dt
            [value]="dataSource"
            [columns]="cols"
            [rows]="50"
            [paginator]="true"
            responsiveLayout="scroll"
            [scrollable]="true"
            scrollHeight="flex"
            [rowHover]="true"
            dataKey="id"
        >
            <ng-template pTemplate="caption">
                <div class="flex align-items-center">
                    <div class="cbms-margin-left-auto">
                        <p-dropdown
                            *ngIf="siteList?.length"
                            class="dd-min-width200 min-width300"
                            name="selectedSite"
                            [options]="siteList"
                            [(ngModel)]="selectedSite"
                            placeholder="Select Site"
                            optionLabel="name"
                            [filter]="true"
                            filterBy="name"
                            [showClear]="true"
                            (onChange)="onSiteChosen()"
                            [disabled]="shouldDisableResources"
                        >
                        </p-dropdown>
                    </div>
                    <div [pTooltip]="'To add a rule you have to select a site first.'" [tooltipDisabled]="selectedSite">
                        <button
                            pButton
                            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left"
                            (click)="addNewRule()"
                            [disabled]="!selectedSite"
                        >
                            Add Rule
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="flex: 0 0 3rem !important; min-width: 3rem">
                        <div class="cbms-disply-flex-row-centered">
                            <span>Actions</span>
                        </div>
                    </th>
                    <th
                        *ngFor="let col of columns"
                        [pSortableColumn]="col.field"
                        pResizableColumn
                        style="flex-basis: {{ col.width }}; min-width: {{
                            col.width
                        }}"
                    >
                        <div class="cbms-disply-flex-row-centered">
                            <span>{{ col.header }}</span>
                            <p-sortIcon
                                [field]="col.field"
                                ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"
                            ></p-sortIcon>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rule let-columns="columns">
                <tr>
                    <td style="flex: 0 0 3rem !important; min-width: 3rem">
                        <button
                            pButton
                            class="p-button-raised p-button-text cbms-row-button"
                            pTooltip="Delete Rule"
                            showDelay="1000"
                            hideDelay="500"
                            (click)="deleteMenu.show($event)"
                        >
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                        <button
                            pButton
                            type="button"
                            icon="pi pi-pencil"
                            class="ui-button-info cbms-margin-left"
                            (click)="editRule(rule)"
                        ></button>

                        <p-overlayPanel #deleteMenu appendTo="body">
                            <p *ngIf="!rule.alarmTemplateId">
                                Please confirm you want to continue with the removal of this SMART Alert rule
                            </p>
                            <p *ngIf="rule.alarmTemplateId">
                                Please confirm that you want to delete this rule. If this is the last rule generated from a Template for a specific Site, deleting this will remove this Site from SMART Alert Template and delete all its alert instances.
                            </p>
                            <hr>
                            <div class="delete-action-buttons">
                                <button pButton
                                        class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                                        label="Confirm"
                                        (click)="deleteSmartAlertRule(rule.id); deleteMenu.hide();"></button>
                                <button pButton class="p-button-raised p-button-text cbms-button"
                                        (click)="deleteMenu.hide()">Cancel
                                </button>
                            </div>
                        </p-overlayPanel>
                    </td>
                    <td
                        *ngFor="let col of columns"
                        style="flex-basis: {{ col.width }}; min-width: {{
                            col.width
                        }}"
                    >
                        <ng-container [ngSwitch]="col.field">
                            <ng-container *ngSwitchCase="'isEnabled'">
                                <p-inputSwitch [(ngModel)]="rule[col.field]"
                                               (onChange)="changeAlertStatus($event, rule)"></p-inputSwitch>
                            </ng-container>
                            <ng-container *ngSwitchCase="'alarmTemplateId'">
                                <mat-icon *ngIf="rule[col.field]" svgIcon="done"></mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchDefault>{{
                                    rule[col.field]
                                }}
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <ng-template #loading>
        <div class="cbms-spinner-wrapper">
            <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
        </div>
    </ng-template>
</ng-container>

<cbms-add-smart-alert-rule
    *ngIf="addEditRule"
    (close)="onClose($event)"
    [selectedSite]="selectedSite"
    [alertData]="selectedRuleData"
></cbms-add-smart-alert-rule>
