import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewEncapsulation,
    OnDestroy,
} from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { SelectItem } from 'primeng/api';
import { FilterParams } from 'src/app/components/filter/interfaces/filter-params.interface';
import { DataPoint } from 'src/app/models/data-point';
import { Site } from 'src/app/models/site';
import { BackendService } from 'src/app/services/backend/backend.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import {
    AlarmFilter,
    AlarmTemplateRule,
    AlertData,
    Option,
    PointSelector,
    SmartAlertTemplate,
} from '../../../../models/alert-rule.model';
import { DayOfWeek, MINUTES, MONTH_LIST } from '../../../../config/constants';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services/utils/util.service';
import { FilterService } from 'src/app/components/filter/filter.service';
import _ from 'lodash';
const DATE = 'date';
const RANGE = 'dateRange';
@Component({
    selector: 'cbms-add-smart-alert-template',
    templateUrl: './add-smart-alert-template.component.html',
    styleUrls: ['./add-smart-alert-template.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddSmartAlertTemplateComponent implements OnInit, OnDestroy {
    // @Input() alertData: AlertData;
    selectedSiteList: Site[] = [];

    templateName = '';
    dataPointList: DataPoint[];
    selectedDataPoint: DataPoint;

    templateVariableList: Option[] = [];
    templateType: string;
    templateTypeList: Option[] = [
        { label: 'Maintenance', value: 'Maintenance' },
        { label: 'Energy', value: 'Energy' },
    ];
    submitted = false;
    limitWeekdays = false;
    limitTimeRange = false;

    startTime = moment().set('minute', 0).toDate();
    endTime = moment(this.startTime).add(1, 'hours').toDate();

    weekDays: any[] = [
        {
            name: 'Mon',
            isSelected: false,
        },
        {
            name: 'Tue',
            isSelected: false,
        },
        {
            name: 'Wed',
            isSelected: false,
        },
        {
            name: 'Thu',
            isSelected: false,
        },
        {
            name: 'Fri',
            isSelected: false,
        },
        {
            name: 'Sat',
            isSelected: false,
        },
        {
            name: 'Sun',
            isSelected: false,
        },
    ];

    timeOptionList: SelectItem[] = [
        { label: 'No time delay', value: 'minutes' },
        { label: 'Hours', value: 'hours' },
        { label: 'Days', value: 'Days' },
    ];
    timeDelayValue: number;
    timeDelayOption: string;

    alertExpiryOption: string;
    alertExpiryValue: number;

    alertPriorityList: SelectItem[] = [
        { label: 'High', value: 'High' },
        { label: 'Medium', value: 'Medium' },
        { label: 'Low', value: 'Low' },
    ];
    alertPriority: string;
    alertDescription: string = '';

    query: AlarmTemplateRule;

    activeFromForm: FormGroup;

    eventPeriodType = {
        'Single Date': 'date',
        'Date Range': 'dateRange',
    };

    periodTypeName = {
        date: 'Single Date',
        dateRange: 'Date Range',
    };

    showInvalidDateError = {
        singleDay: false,
        rangeStartDate: false,
        rangeEndDate: false,
    };
    showInvalidRangeError = false;

    showInvalidExceptionDateError = {
        singleDay: false,
        rangeStartDate: false,
        rangeEndDate: false,
    };
    showInvalidExceptionRangeError = false;

    singleDay = {
        selectedDay: new Date().getDate(),
        selectedMonth: new Date().getMonth() + 1,
        selectedYear: new Date().getFullYear(),
    };

    dateRangeStartDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    dateRangeEndDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    exceptionSingleDay = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    exceptionDateRangeStartDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    exceptionDateRangeEndDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    eventTypeList: SelectItem[] = [
        { label: 'Single Date', value: 'date', disabled: false },
        { label: 'Date Range', value: 'dateRange', disabled: false },
    ];

    dayList: SelectItem[] = [
        { label: 'Day', value: null },
        { label: 'Any', value: DayOfWeek.Any },
    ];

    dayListWithoutAnyOption: SelectItem[] = [{ label: 'Day', value: null }];

    yearList: SelectItem[] = [
        { label: 'Year', value: '' },
        { label: 'Any', value: DayOfWeek.Any },
    ];

    yearListWithoutAnyOption: SelectItem[] = [{ label: 'Year', value: '' }];

    monthList = MONTH_LIST;
    monthListWithoutAnyOption = [...MONTH_LIST];

    selectedEventType: string;
    selectedExceptionEventType: string;
    selectedExceptionDates: string[] = [];

    exceptionDatesApiFormat: any[] = [];

    emailRecipient: string;
    emailRecipientList: string[] = [];
    maxNoOfEmailsExceeded = false;

    private filterParams: FilterParams;
    isEditableMode = false;
    private sitesSubscription: Subscription;
    public siteList: Site[];
    public pointSelectorList: PointSelector[] = [];
    pointSelector: string;
    customerSubscription = new Subscription();
    customerId: string;
    templateId: string;
    isEditing = false;
    editedTemplate: SmartAlertTemplate;
    isLoading: boolean;
    readonly MINUTES = MINUTES;

    constructor(
        private backendService: BackendService,
        private notificationService: NotificationService,
        private dialogConfigService: DialogConfigService,
        private utilsService: UtilsService,
        private router: Router,
        private filterService: FilterService,
        private location: Location
    ) {}

    ngOnInit(): void {
        this.customerId = this.backendService.getSelectedCustomer()?.id;
        this.isLoading = true;
        this.alertPriority = this.alertPriorityList[2].value;
        this.generateDayList();
        this.generateYearList();
        this.monthListWithoutAnyOption.splice(1, 1);

        this.customerSubscription =
            this.filterService.customerFilterChanges$.subscribe((data) => {
                this.router.navigateByUrl('settings/smart-alert-templates');
            });

        const state = this.location.getState() as { templateId: string };
        this.templateId = state?.templateId;
        if (_.isEmpty(this.customerId)) {
            this.getCurrentCustomer();
        } else {
            this.getSiteList();
        }
        this.getPointSelectorList();
        this.initializeRuleSet();
    }

    initializeRuleSet() {
        this.query = {
            condition: 'and',
            rules: [
                {
                    condition: null,
                    rules: null,
                    field: 'datapoint.lastRecordedValue',
                    value: '',
                    operator: '',
                    templateVariable: '',
                },
            ],
        };
    }

    getSmartAlertTemplateById() {
        this.isLoading = true;
        this.backendService
            .getAlertTemplateById(this.templateId, this.customerId)
            .subscribe((res: SmartAlertTemplate) => {
                this.editedTemplate = res;
                this.displayDataOnScreen();
                this.isLoading = false;
            });
    }

    processRules(rules: AlarmTemplateRule[]) {
        if (!rules || !Array.isArray(rules)) {
            return;
        }

        for (const rule of rules) {
            // Process the current rule here
            rule.isFreeText = !['true', 'false'].includes(rule.value);
            // Recursively process nested rules
            if (this.ruleHasChildren(rule)) {
                this.processRules(rule.rules);
            }
        }
    }

    ruleHasChildren(rule) {
        return rule.rules && Array.isArray(rule.rules);
    }

    displayDataOnScreen() {
        this.templateName = this.editedTemplate.name;
        //Map seleced sites to site list
        this.selectedSiteList = this.siteList.filter((item) =>
            this.editedTemplate.siteIds?.includes(item.id)
        );

        this.pointSelector = this.editedTemplate.pointSelector;
        this.getTemplateVariables();
        this.processRules(this.editedTemplate.alarmFilter.rules);
        this.query = this.editedTemplate.alarmFilter;
        this.templateType = this.editedTemplate.templateType;

        this.setLimitWeekdays();
        if (this.editedTemplate.activeDateInterval.activeTimeRange?.fromTime) {
            this.limitTimeRange = true;
            this.startTime = moment(
                this.editedTemplate.activeDateInterval.activeTimeRange.fromTime,
                'HH:mm'
            ).toDate();
            this.endTime = moment(
                this.editedTemplate.activeDateInterval.activeTimeRange.toTime,
                'HH:mm'
            ).toDate();
        }

        this.setRuleActiveFrom();
        this.timeDelayOption = this.editedTemplate.timeDelay.unit;
        this.timeDelayValue = this.editedTemplate.timeDelay.amount;

        this.alertExpiryOption = this.editedTemplate.timeExpiry.unit;
        this.alertExpiryValue = this.editedTemplate.timeExpiry.amount;
        this.alertPriority = <string>this.editedTemplate.priority;
        this.setExceptionDates();
        this.alertDescription = this.editedTemplate.description;
        this.emailRecipientList = [...this.editedTemplate.emailRecipients];
    }

    getCurrentCustomer() {
        this.isLoading = true;
        this.backendService.getCustomers().subscribe((customerList) => {
            let selectedCustomer: { id; name };
            let customerArr = this.utilsService.orderListByFieldName(
                customerList.map((customer) => {
                    return { id: customer.id, name: customer.name };
                }),
                'name'
            );
            this.backendService.getLastCustomer().subscribe((response) => {
                if (_.isEmpty(response)) {
                    selectedCustomer = customerArr[0];
                } else {
                    selectedCustomer = response;
                }
                this.customerId = selectedCustomer.id;
                this.getSiteList();
                this.isLoading = false;
            });
        });
    }

    getAlertTemplateByIdForEditing() {
        if (this.templateId) {
            this.isEditing = true;
            this.getSmartAlertTemplateById();
        }
    }

    getPointSelectorList() {
        this.isLoading = true;
        this.backendService.getPointSelectors().subscribe((response) => {
            this.pointSelectorList = response;
            this.isLoading = false;
        });
    }

    getSiteList() {
        this.isLoading = true;
        if (this.sitesSubscription) {
            this.sitesSubscription.unsubscribe();
        }
        this.sitesSubscription = this.backendService
            .getSitesByCustomer()
            .subscribe((response) => {
                this.siteList = this.utilsService.orderListByFieldName(
                    response.content,
                    'name'
                );
                this.getAlertTemplateByIdForEditing();
                this.isLoading = false;
            });
    }

    ngOnDestroy(): void {
        this.isEditableMode = false;
        this.sitesSubscription.unsubscribe();
        this.customerSubscription.unsubscribe();
    }
    setRuleActiveFrom() {
        let activeDateString =
            this.editedTemplate.activeDateInterval.activeDate;
        if (activeDateString) {
            this.selectedEventType = DATE;
            this.singleDay = { ...this.setDate(activeDateString) };
        }

        let activeRangeDateString =
            this.editedTemplate.activeDateInterval.activeDateRange;
        if (activeRangeDateString) {
            this.selectedEventType = RANGE;
            this.dateRangeStartDate = {
                ...this.setDate(activeRangeDateString.fromDate),
            };
            this.dateRangeEndDate = {
                ...this.setDate(activeRangeDateString.toDate),
            };
        }
    }

    setDate(dateString: string) {
        const fromDateString = dateString.replace(/any/g, '0');
        const [year, month, day] = fromDateString.split('-');
        return {
            selectedYear: parseInt(year),
            selectedMonth: parseInt(month),
            selectedDay: parseInt(day),
        };
    }

    setExceptionDates() {
        const exceptionDateList = this.editedTemplate.exceptionDateRangeList;
        this.selectedExceptionDates = exceptionDateList.map(
            (exceptionDate: any) => {
                if (exceptionDate.activeDate) {
                    return exceptionDate.activeDate
                        .split('-')
                        .reverse()
                        .join('/');
                }
                if (exceptionDate.activeDateRange) {
                    return `${exceptionDate.activeDateRange.fromDate
                        .split('-')
                        .reverse()
                        .join('/')} - ${exceptionDate.activeDateRange.toDate
                        .split('-')
                        .reverse()
                        .join('/')}`;
                }
            }
        );
    }

    setLimitWeekdays() {
        const activeWeekDays =
            this.editedTemplate.activeDateInterval.activeWeekdayList;
        if (activeWeekDays?.length) {
            this.limitWeekdays = true;
            this.weekDays.forEach((day) => {
                if (activeWeekDays.indexOf(day.name) !== -1) {
                    day.isSelected = true;
                }
            });
        }
    }

    generateQueryParam(): AlarmTemplateRule {
        let queryParam: AlarmTemplateRule = {
            condition: this.query.condition,
        };
        queryParam.rules = this.query.rules.map((element) => {
            return this.getRule(element);
        });
        return queryParam;
    }

    getRule(element: AlarmTemplateRule): AlarmTemplateRule {
        let rule: AlarmTemplateRule = {
            condition: element.condition,
            field: element.field,
            value: element.value === '' ? null : element.value,
            operator: element.operator,
            templateVariable: element.templateVariable,
        };
        rule.rules = element?.rules?.length
            ? element.rules.map((item) => this.getRule(item))
            : null;
        return rule;
    }

    onSave() {
        let alarmFilterParam = this.generateQueryParam();
        const alertData: SmartAlertTemplate = {
            name: this.templateName,
            templateType: this.templateType,
            alarmFilter: alarmFilterParam,
            siteIds: [...this.selectedSiteList.map((element) => element.id)],
            activeDateInterval: {
                activeDate: this.isSingleDate(this.selectedEventType)
                    ? this.generateApiDateFormat(this.singleDay)
                    : null,
                activeDateRange: this.isDateRange(this.selectedEventType)
                    ? {
                          fromDate: this.generateApiDateFormat(
                              this.dateRangeStartDate
                          ),
                          toDate: this.generateApiDateFormat(
                              this.dateRangeEndDate
                          ),
                      }
                    : null,
                activeTimeRange: this.limitTimeRange
                    ? {
                          fromTime: moment(this.startTime).format('HH:mm'),
                          toTime: moment(this.endTime).format('HH:mm'),
                      }
                    : null,
                activeWeekdayList: this.limitWeekdays
                    ? this.weekDays
                          .filter((day) => day.isSelected)
                          .map((day) => day.name)
                    : null,
            },
            exceptionDateRangeList: this.generateApiExceptionDatesFormat(),
            timeDelay: {
                amount: this.timeDelayValue,
                unit: this.timeDelayOption,
            },
            timeExpiry: {
                amount: this.alertExpiryValue,
                unit: this.alertExpiryOption,
            },
            priority: this.alertPriority,
            description: this.alertDescription,
            emailRecipients: this.emailRecipientList,
            pointSelector: this.pointSelector,
            customerId: this.customerId,
        };

        if (this.validateParameters(alertData)) {
            if (this.isEditing) {
                this.updateSmartAlertTemplate(alertData);
            } else {
                this.createSmartAlertTemplate(alertData);
            }
        } else {
            this.notificationService.addErrorMessage(
                'Smart Alert Error',
                'Please fill out the mandatory fields'
            );
        }
    }

    isValidRule(rule: AlarmTemplateRule): boolean {
        if (rule?.rules?.length) {
            let isValidRule = true;
            for (const element of rule?.rules) {
                if (!this.isValidRule(element)) {
                    isValidRule = false;
                    break;
                }
            }
            return isValidRule;
        } else {
            if (rule.value === null || rule.value === '') return false;
            if (rule.templateVariable === null) return false;
            return true;
        }
    }

    validateParameters(params: SmartAlertTemplate): boolean {
        if (!params.name) return false;
        if (!params.pointSelector) return false;
        if (!params.templateType) return false;
        if (
            !(
                params.timeDelay.amount >= 0 &&
                params.timeDelay.amount !== null &&
                params.timeDelay.unit
            )
        )
            return false;
        if (
            !(
                params.timeExpiry.amount >= 0 &&
                params.timeExpiry.amount !== null &&
                params.timeExpiry.unit
            )
        )
            return false;
        if (!params.alarmFilter.rules?.length) {
            return false;
        } else {
            let isValid = true;
            for (const rule of params.alarmFilter.rules) {
                isValid = this.isValidRule(rule);
                if (!isValid) break;
            }
            if (!isValid) return false;
        }
        if (
            !(
                params.activeDateInterval.activeDate ||
                params.activeDateInterval.activeDateRange
            )
        )
            return false;

        return true;
    }

    createSmartAlertTemplate(alertData: SmartAlertTemplate) {
        this.isLoading = true;
        this.backendService.createAlertTemplate(alertData).subscribe(
            (res) => {
                this.notificationService.addSuccessMessage(
                    'Smart Alert Rule',
                    'Smart alert template successfully created!'
                );
                this.router.navigateByUrl('settings/smart-alert-templates');
                this.isLoading = false;
            },
            (err) => {
                this.utilsService.displayErrorPopup(err);
                this.isLoading = false;
            }
        );
    }

    updateSmartAlertTemplate(alertData: SmartAlertTemplate) {
        this.isLoading = true;
        this.backendService
            .updateAlertTemplate(alertData, this.templateId)
            .subscribe(
                (res) => {
                    this.notificationService.addSuccessMessage(
                        'Smart Alert Rule',
                        'Smart alert template successfully updated!'
                    );
                    this.router.navigateByUrl('settings/smart-alert-templates');
                    this.isLoading = false;
                },
                (err) => {
                    this.utilsService.displayErrorPopup(err);
                    this.isLoading = false;
                }
            );
    }

    cancel() {
        this.router.navigateByUrl('settings/smart-alert-templates');
    }

    addException() {
        this.resetExceptionDatesErrorMessage();

        if (
            this.selectedExceptionEventType === 'date' &&
            !this.isValidDate(this.exceptionSingleDay)
        ) {
            this.showInvalidExceptionDateError.singleDay = true;
            return;
        }
        if (this.selectedExceptionEventType === 'dateRange') {
            if (
                !this.isValidDate(this.exceptionDateRangeStartDate) ||
                !this.isValidDate(this.exceptionDateRangeEndDate)
            ) {
                this.showInvalidExceptionDateError.rangeStartDate =
                    !this.isValidDate(this.exceptionDateRangeStartDate);
                this.showInvalidExceptionDateError.rangeEndDate =
                    !this.isValidDate(this.exceptionDateRangeEndDate);
                return;
            }
            if (
                !this.isValidRangeDate(
                    this.exceptionDateRangeStartDate,
                    this.exceptionDateRangeEndDate
                )
            ) {
                this.showInvalidExceptionRangeError = true;
                return;
            }
        }
        if (this.selectedExceptionEventType === 'dateRange') {
            if (
                this.selectedExceptionDates.indexOf(
                    this.generateDateRange()
                ) === -1
            ) {
                this.selectedExceptionDates.push(this.generateDateRange());
                this.exceptionDatesApiFormat.push({
                    activeDateRange: {
                        fromDate: this.generateApiDateFormat(
                            this.exceptionDateRangeStartDate
                        ),
                        toDate: this.generateApiDateFormat(
                            this.exceptionDateRangeEndDate
                        ),
                    },
                });
            }
        }

        if (this.selectedExceptionEventType === 'date') {
            if (
                this.selectedExceptionDates.indexOf(
                    this.generateSingleDate(this.exceptionSingleDay)
                ) === -1
            ) {
                this.selectedExceptionDates.push(
                    this.generateSingleDate(this.exceptionSingleDay)
                );
                this.exceptionDatesApiFormat.push({
                    activeDate: this.generateApiDateFormat(
                        this.exceptionSingleDay
                    ),
                });
            }
        }
    }

    removeExceptionDate(index: number) {
        this.selectedExceptionDates.splice(index, 1);
    }

    validateFromSingleDay() {
        if (!this.isValidDate(this.singleDay)) {
            this.showInvalidDateError.singleDay = true;
        } else {
            this.showInvalidDateError.singleDay = false;
        }
    }

    validateFromDateRange() {
        this.resetDatesErrorMessage();

        if (
            !this.isValidDate(this.dateRangeStartDate) ||
            !this.isValidDate(this.dateRangeEndDate)
        ) {
            this.showInvalidDateError.rangeStartDate = !this.isValidDate(
                this.dateRangeStartDate
            );
            this.showInvalidDateError.rangeEndDate = !this.isValidDate(
                this.dateRangeEndDate
            );
            return;
        }
        if (
            !this.isValidRangeDate(
                this.dateRangeStartDate,
                this.dateRangeEndDate
            )
        ) {
            this.showInvalidRangeError = true;
            return;
        }
    }

    activeFromEventTypeChange() {
        this.resetDatesErrorMessage();
        if (this.selectedEventType === 'date') {
            this.validateFromSingleDay();
        }
        if (this.selectedEventType === 'dateRange') {
            this.validateFromDateRange();
        }
    }

    addEmailRecipient() {
        if (this.emailRecipientList.indexOf(this.emailRecipient) === -1) {
            this.emailRecipientList.push(this.emailRecipient);
            this.emailRecipient = '';
        }
        this.maxNoOfEmailsExceeded = this.emailRecipientList.length >= 20;
    }

    removeEmailRecipient(index: number) {
        this.emailRecipientList.splice(index, 1);
        this.maxNoOfEmailsExceeded = this.emailRecipientList.length >= 20;
    }

    private generateDayList() {
        for (let i = 1; i <= 31; i++) {
            let dayNo = `${i}`;
            if (i < 10) {
                dayNo = `0${i}`;
            }
            this.dayList.push({ label: `${dayNo}`, value: i });
            this.dayListWithoutAnyOption.push({ label: `${dayNo}`, value: i });
        }
    }

    private generateYearList() {
        const currentYear = new Date().getFullYear();
        for (let i = 0; i <= 20; i++) {
            this.yearList.push({
                label: `${currentYear + i}`,
                value: currentYear + i,
            });
            this.yearListWithoutAnyOption.push({
                label: `${currentYear + i}`,
                value: currentYear + i,
            });
        }
    }

    private isValidDate(dateObj) {
        let hasWildcard = false;
        let invalidDate = false;

        Object.keys(dateObj).forEach((key) => {
            if (dateObj[key] === undefined || dateObj[key] === '') {
                invalidDate = true;
            }
            if (dateObj[key] === 0) {
                hasWildcard = true;
            }
        });

        if (invalidDate) {
            return false;
        }

        if (hasWildcard) {
            return true;
        }

        let date = moment(
            `${dateObj.selectedMonth}/${dateObj.selectedDay}/${dateObj.selectedYear}`,
            'MM/DD/YYYY'
        );

        return (
            date.isValid() && (date.isSame(new Date(), 'day') || date.isAfter())
        );
    }

    private isValidRangeDate(startDateObj, endDateObj) {
        if (
            !this.isWildCardPositionValid(
                startDateObj.selectedDay,
                endDateObj.selectedDay
            )
        ) {
            return false;
        }

        if (
            !this.isWildCardPositionValid(
                startDateObj.selectedMonth,
                endDateObj.selectedMonth
            )
        ) {
            return false;
        }

        if (
            !this.isWildCardPositionValid(
                startDateObj.selectedYear,
                endDateObj.selectedYear
            )
        ) {
            return false;
        }

        const today = new Date();
        const currentYear = today.getFullYear();

        let startDate = moment(
            `${startDateObj.selectedMonth || 1}/${
                startDateObj.selectedDay || 1
            }/${startDateObj.selectedYear || currentYear}`,
            'MM/DD/YYYY'
        );
        let endDate = moment(
            `${endDateObj.selectedMonth || 2}/${endDateObj.selectedDay || 2}/${
                endDateObj.selectedYear || currentYear + 1
            }`,
            'MM/DD/YYYY'
        );

        if (!startDate.isValid() || !endDate.isValid()) {
            return false;
        }

        return startDate.isBefore(endDate);
    }

    private isWildCardPositionValid(start, end) {
        if ((start === 0 || end === 0) && start !== end) {
            return false;
        }
        return true;
    }

    private resetDatesErrorMessage() {
        this.showInvalidDateError = {
            singleDay: false,
            rangeStartDate: false,
            rangeEndDate: false,
        };
        this.showInvalidRangeError = false;
    }

    private resetExceptionDatesErrorMessage() {
        this.showInvalidExceptionDateError = {
            singleDay: false,
            rangeStartDate: false,
            rangeEndDate: false,
        };
        this.showInvalidExceptionRangeError = false;
    }

    private generateSingleDate(dataObj) {
        return `${this.addZero(
            this.parseValue(dataObj.selectedDay)
        )}/${this.addZero(
            this.parseValue(dataObj.selectedMonth)
        )}/${this.parseValue(dataObj.selectedYear)}`.replace(/null/g, '*');
    }

    private generateDateRange() {
        const startDateSelectedMonth = this.addZero(
            this.parseValue(this.exceptionDateRangeStartDate.selectedMonth)
        );
        const endDateSelectedMonth = this.addZero(
            this.parseValue(this.exceptionDateRangeEndDate.selectedMonth)
        );

        return `${this.addZero(
            this.parseValue(this.exceptionDateRangeStartDate.selectedDay)
        )}/${startDateSelectedMonth}/${this.parseValue(
            this.exceptionDateRangeStartDate.selectedYear
        )} - ${this.addZero(
            this.parseValue(this.exceptionDateRangeEndDate.selectedDay)
        )}/${endDateSelectedMonth}/${this.parseValue(
            this.exceptionDateRangeEndDate.selectedYear
        )}`.replace(/null/g, '*');
    }

    private generateApiDateFormat(dateObj) {
        return `${this.parseValue(dateObj.selectedYear)}-${this.parseValue(
            this.addZero(dateObj.selectedMonth)
        )}-${this.addZero(this.parseValue(dateObj.selectedDay))}`.replace(
            /null/g,
            'any'
        );
    }

    private generateApiExceptionDatesFormat() {
        return this.selectedExceptionDates.map((exceptionDate: any) => {
            const splittedDate = exceptionDate.split(' - ');

            if (splittedDate.length === 1) {
                return {
                    activeDate: splittedDate[0]
                        .split('/')
                        .reverse()
                        .join('-')
                        .replaceAll('*', 'any'),
                };
            }
            if (splittedDate.length === 2) {
                return {
                    activeDateRange: {
                        fromDate: splittedDate[0]
                            .split('/')
                            .reverse()
                            .join('-')
                            .replaceAll('*', 'any'),
                        toDate: splittedDate[1]
                            .split('/')
                            .reverse()
                            .join('-')
                            .replaceAll('*', 'any'),
                    },
                };
            }
        });
    }

    private addZero(index: number) {
        if (index > 0 && index < 10) {
            return `0${index}`;
        }
        return index;
    }

    private parseValue(value: any) {
        return parseInt(value) === 0 ? null : value;
    }

    private isSingleDate(eventType: string) {
        return eventType === DATE;
    }

    private isDateRange(eventType: string) {
        return eventType === RANGE;
    }

    selectSites() {
        this.mapSelectedItemToSiteList();
        const data = this.siteList;
        let dialog = this.dialogConfigService.showSitesSelectionDialog(data);
        dialog.onClose.subscribe((selectedSites: Site[]) => {
            if (selectedSites != null) {
                this.selectedSiteList = selectedSites;
            }
        });
    }

    mapSelectedItemToSiteList(): void {
        this.siteList?.map((site) => {
            this.selectedSiteList.findIndex(
                (selectedSite) => selectedSite.id === site.id
            ) >= 0
                ? (site.selected = true)
                : (site.selected = false);
        });
    }

    getTemplateVariables() {
        this.templateVariableList = this.pointSelectorList.find(
            (element) => element.value === this.pointSelector
        ).variables;
        this.initializeRuleSet();
    }

    handleTimeDelayOptionChange() {
        if (this.timeDelayOption === this.MINUTES) {
            this.timeDelayValue = 0;
        }
    }

    handleAlertExpiryOptionChange() {
        if (this.alertExpiryOption === this.MINUTES) {
            this.alertExpiryValue = 0;
        }
    }
}
