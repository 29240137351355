<ng-container>
    <div [pTooltip]="'No data points selected.'"
         showDelay="1000"
         hideDelay="500"
         [tooltipDisabled]="selectedDataPoints.length">
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="showCompareDataPointToTwinDialog()"
                [disabled]="!selectedDataPoints.length" data-cy="compare-schedule-to-twin">
            <mat-icon svgIcon="compare"></mat-icon>
            Compare Data Point To Twin
        </button>
    </div>
</ng-container>
