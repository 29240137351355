<div class="content-wrapper" *ngIf="!isLoading; else loading">
    <div class="grid align-items-center field">
        <div class="col-2">
            <h3 class="required-field">Template Name</h3>
        </div>
        <div class="col-8">
            <input
                class="width50 q-input-control"
                pInputText
                name="templateName"
                required
                minlength="3"
                [(ngModel)]="templateName"
            />
        </div>
    </div>

    <div class="formgrid grid sites field">
        <div class="col-2">
            <h3>Site</h3>
        </div>
        <div class="col-8">
            <button
                pButton
                class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                (click)="selectSites()"
            >
                Select sites
            </button>
            <span class="sites-number">({{ selectedSiteList?.length }})</span>
        </div>
    </div>

    <div class="ruleset">
        <div class="formgrid grid field">
            <div class="col-2">
                <h3 class="required-field">Point Selector</h3>
            </div>
            <div class="col-8">
                <p-dropdown
                    [filter]="true"
                    class="dd-min-width200 min-width300"
                    [options]="pointSelectorList"
                    (onChange)="getTemplateVariables()"
                    [(ngModel)]="pointSelector"
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Choose point selector"
                    [disabled]="isEditing"
                >
                </p-dropdown>
            </div>
        </div>
        <h3 class="required-field">Ruleset</h3>
        <cbms-query-builder
            [templateVariableList]="templateVariableList"
            [query]="query"
        ></cbms-query-builder>

        <div class="grid align-items-center">
            <div class="field col-2">
                <h3 class="required-field">Template Type</h3>
            </div>
            <div class="field col-8">
                <p-dropdown
                    [options]="templateTypeList"
                    [(ngModel)]="templateType"
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Select template type"
                >
                </p-dropdown>
            </div>
        </div>

        <div class="grid">
            <div class="field col">
                <hr />
            </div>
        </div>

        <div class="grid align-items-center">
            <div class="flex align-items-center col-12">
                    <p-checkbox
                        class="cbms-margin-right"
                        [(ngModel)]="limitWeekdays"
                        binary="true"
                        inputId="days"
                    ></p-checkbox>
                    <span>Limit weekdays</span>
            </div>
            <div
                class="flex align-items-center justify-content-between field col-6"
            >
                <p-toggleButton
                    [styleClass]="'turquoise-on-selected'"
                    *ngFor="let day of weekDays; let i = index"
                    [(ngModel)]="day.isSelected"
                    onLabel="{{ day.name }}"
                    offLabel="{{ day.name }}"
                    [disabled]="!limitWeekdays"
                ></p-toggleButton>
            </div>
        </div>

        <div class="grid align-items-center">
            <div class="flex field align-items-center col-6">
                <p-checkbox
                    class="cbms-margin-right"
                    [(ngModel)]="limitTimeRange"
                    binary="true"
                    inputId="timeRange"
                ></p-checkbox>
                <span>Limit time range</span>
            </div>
        </div>
        <div class="grid align-items-center">
            <div class="col-3 time-picker">
                <label>Start</label>
                <p-calendar
                    [styleClass]="'full-width'"
                    [timeOnly]="true"
                    hourFormat="24"
                    [(ngModel)]="startTime"
                    [disabled]="!limitTimeRange"
                >
                </p-calendar>
            </div>
            <div class="col-3 time-picker">
                <label>End</label>
                <p-calendar
                    [styleClass]="'full-width'"
                    [timeOnly]="true"
                    hourFormat="24"
                    [(ngModel)]="endTime"
                    [disabled]="!limitTimeRange"
                >
                </p-calendar>
            </div>
        </div>
        <div class="grid align-items-center">
            <div class="field col-6">
                <span
                    >If the end time is before the start time (e.g 18:00 -
                    6:00), the hours of the next day are included <br />(even if
                    this weekday is not selected in the filter above)</span
                >
            </div>
        </div>

        <div class="grid">
            <div class="field col">
                <hr />
            </div>
        </div>

        <div class="grid align-items-center">
            <div class="field col-1 lg:col-2">
                <h3 class="required-field">Time delay</h3>
            </div>
            <div class="field col-5">
                <p-dropdown
                    class="dd-min-width200 cbms-margin-right"
                    [options]="timeOptionList"
                    [(ngModel)]="timeDelayOption"
                    optionValue="value"
                    optionLabel="label"
                    (onChange)="handleTimeDelayOptionChange()"
                >
                </p-dropdown>

                <p-inputNumber
                    [hidden]="timeDelayOption === MINUTES"
                    class="q-input-control cbms-input__small-size"
                    pInputNumber
                    name="timeDelayValue"
                    required
                    inputId="integeronly"
                    [(ngModel)]="timeDelayValue"
                ></p-inputNumber>
            </div>
        </div>

        <div class="grid align-items-center">
            <div class="field col-1 lg:col-2">
                <h3 class="required-field">Alert expiry</h3>
            </div>
            <div class="field col-5">
                <p-dropdown
                    class="dd-min-width200 cbms-margin-right"
                    [options]="timeOptionList"
                    [(ngModel)]="alertExpiryOption"
                    optionValue="value"
                    optionLabel="label"
                    (onChange)="handleAlertExpiryOptionChange()"
                >
                </p-dropdown>

                <p-inputNumber
                    [hidden]="alertExpiryOption === MINUTES"
                    class="q-input-control cbms-input__small-size"
                    pInputNumber
                    name="alertExpiryValue"
                    required
                    inputId="integeronly"
                    [(ngModel)]="alertExpiryValue"
                ></p-inputNumber>
            </div>
        </div>

        <div class="grid align-items-center">
            <div class="field col-1 lg:col-2">
                <h3>Alert priority</h3>
            </div>
            <div class="field col-5">
                <p-dropdown
                    class="dd-min-width200"
                    [options]="alertPriorityList"
                    [(ngModel)]="alertPriority"
                    optionValue="value"
                    optionLabel="label"
                >
                </p-dropdown>
            </div>
        </div>

        <div class="grid">
            <div class="field col-6">
                <h3 class="cbms-margin-bottom20 required-field">Rule active from</h3>

                <div class="grid cbms-align-center">
                    <div class="col-4">
                        <span>Event type</span>
                    </div>
                    <div class="col-8">
                        <p-dropdown
                            class="dd-min-width200"
                            [options]="eventTypeList"
                            [(ngModel)]="selectedEventType"
                            (onChange)="activeFromEventTypeChange()"
                        >
                        </p-dropdown>
                    </div>
                </div>
                <ng-container [ngSwitch]="selectedEventType">
                    <ng-container *ngSwitchCase="'date'">
                        <div class="grid cbms-align-center">
                            <div class="col-4">
                                <span>Date (not in the past)</span>
                            </div>
                            <div class="col-8">
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error: showInvalidDateError.singleDay,
                                    }"
                                    [options]="dayListWithoutAnyOption"
                                    [(ngModel)]="singleDay.selectedDay"
                                    (onChange)="validateFromSingleDay()"
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error: showInvalidDateError.singleDay,
                                    }"
                                    [options]="monthListWithoutAnyOption"
                                    [(ngModel)]="singleDay.selectedMonth"
                                    (onChange)="validateFromSingleDay()"
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent"
                                    [ngClass]="{
                                        error: showInvalidDateError.singleDay,
                                    }"
                                    [options]="yearListWithoutAnyOption"
                                    [(ngModel)]="singleDay.selectedYear"
                                    (onChange)="validateFromSingleDay()"
                                >
                                </p-dropdown>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dateRange'">
                        <div class="grid cbms-align-center">
                            <div class="col-4">
                                <span>Start date (not in the past)</span>
                            </div>
                            <div class="col-8">
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error:
                                            showInvalidDateError.rangeStartDate ||
                                            showInvalidRangeError,
                                    }"
                                    [options]="dayListWithoutAnyOption"
                                    [(ngModel)]="dateRangeStartDate.selectedDay"
                                    (onChange)="validateFromDateRange()"
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error:
                                            showInvalidDateError.rangeStartDate ||
                                            showInvalidRangeError,
                                    }"
                                    [options]="monthListWithoutAnyOption"
                                    [(ngModel)]="
                                        dateRangeStartDate.selectedMonth
                                    "
                                    (onChange)="validateFromDateRange()"
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent"
                                    [ngClass]="{
                                        error:
                                            showInvalidDateError.rangeStartDate ||
                                            showInvalidRangeError,
                                    }"
                                    [options]="yearList"
                                    [(ngModel)]="
                                        dateRangeStartDate.selectedYear
                                    "
                                    (onChange)="validateFromDateRange()"
                                >
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="grid cbms-align-center">
                            <div class="col-4">
                                <span>End date</span>
                            </div>
                            <div class="col-8">
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error:
                                            showInvalidDateError.rangeEndDate ||
                                            showInvalidRangeError,
                                    }"
                                    [options]="dayListWithoutAnyOption"
                                    [(ngModel)]="dateRangeEndDate.selectedDay"
                                    (onChange)="validateFromDateRange()"
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error:
                                            showInvalidDateError.rangeEndDate ||
                                            showInvalidRangeError,
                                    }"
                                    [options]="monthListWithoutAnyOption"
                                    [(ngModel)]="dateRangeEndDate.selectedMonth"
                                    (onChange)="validateFromDateRange()"
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent"
                                    [ngClass]="{
                                        error:
                                            showInvalidDateError.rangeEndDate ||
                                            showInvalidRangeError,
                                    }"
                                    [options]="yearList"
                                    [(ngModel)]="dateRangeEndDate.selectedYear"
                                    (onChange)="validateFromDateRange()"
                                >
                                </p-dropdown>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="grid cbms-align-center">
                    <div class="col-4"></div>
                    <div class="col-8">
                        <p
                            *ngIf="
                                showInvalidDateError.singleDay ||
                                showInvalidDateError.rangeStartDate ||
                                showInvalidDateError.rangeEndDate
                            "
                            class="error"
                        >
                            Date is not valid!
                        </p>
                        <p *ngIf="showInvalidRangeError" class="error">
                            Date range is not valid. Start date must be before
                            end date.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid">
            <div class="field col-6">
                <h3 class="cbms-margin-bottom20">Exception dates</h3>

                <div class="grid cbms-align-center">
                    <div class="col-4">
                        <span>Event type</span>
                    </div>
                    <div class="col-8">
                        <p-dropdown
                            class="dd-min-width200"
                            [options]="eventTypeList"
                            [(ngModel)]="selectedExceptionEventType"
                        >
                        </p-dropdown>
                    </div>
                </div>
                <ng-container [ngSwitch]="selectedExceptionEventType">
                    <ng-container *ngSwitchCase="'date'">
                        <div class="grid cbms-align-center">
                            <div class="col-4">
                                <span>Date</span>
                            </div>
                            <div class="col-8">
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error: showInvalidExceptionDateError.singleDay,
                                    }"
                                    [options]="dayList"
                                    [(ngModel)]="exceptionSingleDay.selectedDay"
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error: showInvalidExceptionDateError.singleDay,
                                    }"
                                    [options]="monthList"
                                    [(ngModel)]="
                                        exceptionSingleDay.selectedMonth
                                    "
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent"
                                    [ngClass]="{
                                        error: showInvalidExceptionDateError.singleDay,
                                    }"
                                    [options]="yearList"
                                    [(ngModel)]="
                                        exceptionSingleDay.selectedYear
                                    "
                                >
                                </p-dropdown>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dateRange'">
                        <div class="grid cbms-align-center">
                            <div class="col-4">
                                <span>Start Date</span>
                            </div>
                            <div class="col-8">
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error:
                                            showInvalidExceptionDateError.rangeStartDate ||
                                            showInvalidExceptionRangeError,
                                    }"
                                    [options]="dayList"
                                    [(ngModel)]="
                                        exceptionDateRangeStartDate.selectedDay
                                    "
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error:
                                            showInvalidExceptionDateError.rangeStartDate ||
                                            showInvalidExceptionRangeError,
                                    }"
                                    [options]="monthList"
                                    [(ngModel)]="
                                        exceptionDateRangeStartDate.selectedMonth
                                    "
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent"
                                    [ngClass]="{
                                        error:
                                            showInvalidExceptionDateError.rangeStartDate ||
                                            showInvalidExceptionRangeError,
                                    }"
                                    [options]="yearList"
                                    [(ngModel)]="
                                        exceptionDateRangeStartDate.selectedYear
                                    "
                                >
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="grid cbms-align-center">
                            <div class="col-4">
                                <span>End Date</span>
                            </div>
                            <div class="col-8">
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error:
                                            showInvalidExceptionDateError.rangeEndDate ||
                                            showInvalidExceptionRangeError,
                                    }"
                                    [options]="dayList"
                                    [(ngModel)]="
                                        exceptionDateRangeEndDate.selectedDay
                                    "
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent cbms-margin-right"
                                    [ngClass]="{
                                        error:
                                            showInvalidExceptionDateError.rangeEndDate ||
                                            showInvalidExceptionRangeError,
                                    }"
                                    [options]="monthList"
                                    [(ngModel)]="
                                        exceptionDateRangeEndDate.selectedMonth
                                    "
                                >
                                </p-dropdown>
                                <p-dropdown
                                    class="dd-width-20-percent"
                                    [ngClass]="{
                                        error:
                                            showInvalidDateError.rangeEndDate ||
                                            showInvalidExceptionRangeError,
                                    }"
                                    [options]="yearList"
                                    [(ngModel)]="
                                        exceptionDateRangeEndDate.selectedYear
                                    "
                                >
                                </p-dropdown>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="grid cbms-align-center">
                    <div class="col-4"></div>
                    <div class="col-8">
                        <p
                            *ngIf="
                                showInvalidExceptionDateError.singleDay ||
                                showInvalidExceptionDateError.rangeStartDate ||
                                showInvalidExceptionDateError.rangeEndDate
                            "
                            class="error"
                        >
                            Date is not valid!
                        </p>
                        <p *ngIf="showInvalidExceptionRangeError" class="error">
                            Date range is not valid. Start date must be before
                            end date.
                        </p>
                    </div>
                </div>
                <div class="grid cbms-align-center">
                    <div class="col-4"></div>
                    <div class="col-6">
                        <button
                            pButton
                            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto"
                            type="submit"
                            label="Add"
                            (click)="addException()"
                        ></button>
                    </div>
                </div>
            </div>
            <div class="field col-6">
                <h3 class="cbms-margin-bottom20">Selected exception dates</h3>
                <div class="cbms-chips-with-remove-button">
                    <span
                        class="cbms-chips"
                        *ngFor="
                            let date of selectedExceptionDates;
                            let i = index
                        "
                        >{{ date }}
                        <i
                            class="pi pi-times"
                            (click)="removeExceptionDate(i)"
                        ></i
                    ></span>
                </div>
            </div>
        </div>

        <div class="grid">
            <div class="field col-1 lg:col-2">
                <h3>Alert description</h3>
            </div>
            <div class="field col-5">
                <textarea
                    rows="5"
                    cols="80"
                    pInputTextarea
                    [(ngModel)]="alertDescription"
                ></textarea>
            </div>
        </div>

        <div class="grid">
            <div class="field col-1 lg:col-2">
                <h3>Email notification recipients</h3>
            </div>
            <div class="field col-5">
                <input
                    class="cbms-input__mid-size"
                    type="email"
                    pInputText
                    pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                    #emailRecipientRef="ngModel"
                    [(ngModel)]="emailRecipient"
                />
                <button
                    pButton
                    class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto"
                    type="submit"
                    label="Add"
                    [disabled]="
                        emailRecipientRef.errors || maxNoOfEmailsExceeded
                    "
                    (click)="addEmailRecipient()"
                ></button>

                <div
                    *ngIf="
                        emailRecipientRef.errors &&
                        (emailRecipientRef.invalid || emailRecipientRef.touched)
                    "
                >
                    <p class="error">Please provide a valid email address</p>
                </div>

                <p *ngIf="maxNoOfEmailsExceeded" class="error">
                    Maximum number of recipients exceeded.
                </p>

                <div class="cbms-chips-with-remove-button cbms-margin-top10">
                    <span
                        class="cbms-chips"
                        *ngFor="
                            let emailRecipient of emailRecipientList;
                            let i = index
                        "
                        >{{ emailRecipient }}
                        <i
                            class="pi pi-times"
                            (click)="removeEmailRecipient(i)"
                        ></i
                    ></span>
                </div>
            </div>
        </div>
    </div>

    <div class="grid">
        <div class="field col flex">
            <button
                pButton
                class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto"
                type="submit"
                label="Save"
                [disabled]="
                    !this.templateName ||
                    (this.templateName && this.templateName.length < 3) ||
                    showInvalidDateError.singleDay ||
                    showInvalidDateError.rangeStartDate ||
                    showInvalidDateError.rangeEndDate ||
                    showInvalidExceptionRangeError
                "
                (click)="onSave()"
            ></button>
            <button
                pButton
                class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
                label="Cancel"
                (click)="cancel()"
            ></button>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="cbms-spinner-wrapper">
        <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
    </div>
</ng-template>
