<div class="filter-fields-wrapper">
    <div class="col-1">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="showFilterMenu($event, filterMenuOverlay)">
            <mat-icon svgIcon="filter"></mat-icon>
            <span class="table-caption-text">Add Filter</span>
        </button>
    </div>
    <div class="col-9">
        <div class="form_field" pTooltip="Edit Filter" tooltipPosition="bottom"
            *ngFor="let item of selectedFieldFilter | keyvalue">
            <ng-container [ngSwitch]="item.value.operator">
                <ng-container *ngSwitchCase="'within_range'">
                    <span class="field__name-and-value"
                        (click)="editFieldToFilter($event, item, filterMenuOverlay)">{{item.value.field}}:
                        {{item.value.operator}} "[{{item.value.intervalStart}} - {{item.value.intervalEnd}}]"</span>
                </ng-container>
                <ng-container *ngSwitchCase="'out_of_range'">
                    <span class="field__name-and-value"
                        (click)="editFieldToFilter($event, item, filterMenuOverlay)">{{item.value.field}}:
                        {{item.value.operator}} "[{{item.value.intervalStart}} - {{item.value.intervalEnd}}]"</span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span class="field__name-and-value"
                        (click)="editFieldToFilter($event, item, filterMenuOverlay)">{{item.value.field}}:
                        {{item.value.operator}} "{{item.value.value}}"</span>
                </ng-container>
            </ng-container>
            <span class="field__remove-btn pi pi-times" (click)="removeFieldFromFilter(item)"></span>
        </div>
    </div>
</div>
<p-overlayPanel #filterMenuOverlay appendTo="body" [dismissable]="true" [showCloseIcon]="true" styleClass="filter-overlay" (onHide)="resetAddFilterForm()">
    <h3>{{filterFormTitle}}</h3>
    <form [formGroup]="addFilterForm" (ngSubmit)="saveFieldFilter(filterMenuOverlay)">
        <div class="grid">
            <div class="col-6">
                <p>Field name</p>
                <p-dropdown [options]="fieldList" formControlName="fieldName"></p-dropdown>
            </div>
            <div class="col-6">
                <p>Operator</p>
                <p-dropdown [options]="operatorsList" formControlName="selectedOperator"></p-dropdown>
            </div>
            <div class="col-6">
                <ng-container [ngSwitch]="fieldName.value">
                    <ng-container *ngSwitchCase="'Polled'">
                        <p>Value</p>
                        <p-dropdown [options]="booleanList" formControlName="selectedBooleanOperator"></p-dropdown>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Site Assigned'">
                        <p>Value</p>
                        <p-dropdown [options]="booleanList" formControlName="selectedBooleanOperator"></p-dropdown>
                    </ng-container>
                    <ng-container *ngSwitchCase="FieldList.TYPE">
                        <p>Value</p>
                        <p-multiSelect #typeFilter [options]="filterTypes" formControlName="selectedFilterTypes"
                            [filter]="true" filterBy="label,name" virtualScrollItemSize="30" filterPlaceHolder="IO-Type"
                            selectedItemsLabel="{0} Types selected" maxSelectedLabels="1" placeholder="IO-Type"
                            optionLabel="name">
                            <p-footer>
                                <hr>
                                <div class="filter-footer">
                                    <span *ngIf="typeFilter && !typeFilter._filteredOptions">showing
                                        {{filterTypes.length}} of {{filterTypes.length}}
                                        Types</span>
                                    <span *ngIf="typeFilter && typeFilter._filteredOptions">showing
                                        {{typeFilter._filteredOptions.length}} of {{filterTypes.length}}
                                        Types</span>
                                </div>
                            </p-footer>
                        </p-multiSelect>
                    </ng-container>
                    <ng-container *ngSwitchCase="FieldList.SIGNAL_TYPE">
                        <p>Value</p>
                        <p-multiSelect #selectRef [options]="signalTypeList" formControlName="selectedFilterTypes"
                            [filter]="true" filterBy="label,name" virtualScrollItemSize="30" filterPlaceHolder="Signal Type"
                            selectedItemsLabel="{0} Types selected" maxSelectedLabels="1" placeholder="Signal Type"
                            optionLabel="name">
                            <p-footer>
                                <hr>
                                <div class="filter-footer">
                                    <span *ngIf="selectRef && !selectRef._filteredOptions">showing
                                        {{signalTypeList.length}} of {{signalTypeList.length}}
                                        Types</span>
                                    <span *ngIf="selectRef && selectRef._filteredOptions">showing
                                        {{selectRef._filteredOptions.length}} of {{signalTypeList.length}}
                                        Types</span>
                                </div>
                            </p-footer>
                        </p-multiSelect>
                    </ng-container>
                    <ng-container *ngSwitchCase="FieldList.SOURCE">
                        <p>Value</p>
                        <p-multiSelect #selectRef [options]="sourceTypeList" formControlName="selectedFilterTypes"
                            [filter]="true" filterBy="label,name" virtualScrollItemSize="30" filterPlaceHolder="Source"
                            selectedItemsLabel="{0} Types selected" maxSelectedLabels="1" placeholder="Source"
                            optionLabel="name">
                            <p-footer>
                                <hr>
                                <div class="filter-footer">
                                    <span *ngIf="selectRef && !selectRef._filteredOptions">showing
                                        {{sourceTypeList.length}} of {{sourceTypeList.length}}
                                        Types</span>
                                    <span *ngIf="selectRef && selectRef._filteredOptions">showing
                                        {{selectRef._filteredOptions.length}} of {{sourceTypeList.length}}
                                        Types</span>
                                </div>
                            </p-footer>
                        </p-multiSelect>
                    </ng-container>
                    <ng-template #valueRange>
                        <ng-container *ngIf="!shouldShowInterval">
                            <p>Value</p>
                            <input class="full-width" pInputText placeholder="Value" formControlName="fieldValue">
                        </ng-container>
                        <ng-container *ngIf="shouldShowInterval">
                            <p>Interval</p>
                            <input class="half-width" pInputText placeholder="Value" formControlName="intervalStart">
                            <input class="half-width" pInputText placeholder="Value" formControlName="intervalEnd">
                        </ng-container>
                    </ng-template>
                    <ng-container *ngSwitchCase="FieldList.LAST_RECORDED_VALUE">
                        <ng-container *ngTemplateOutlet="valueRange"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="FieldList.TWIN_VALUE">
                        <ng-container *ngTemplateOutlet="valueRange"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <p>Value</p>
                        <input class="full-width" pInputText placeholder="Value" formControlName="fieldValue">
                    </ng-container>
                </ng-container>
                <br><br>
                <p>All fields are required</p>
            </div>
            <div class="col-6">
            </div>
        </div>

        <hr>
        <div class="filter-action-buttons">
            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-right" type="submit" [disabled]="!addFilterForm.valid">Save</button>
            <button pButton class="p-button-raised p-button-text cbms-button" type="reset" (click)="filterMenuOverlay.hide()">Cancel</button>
        </div>
    </form>
</p-overlayPanel>
