import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IDP_PROVIDER, LOGIN_IDP } from 'src/app/config/constants';
import { DataService } from 'src/app/services/data/data.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from '../../services/utils/authorization.service';

@Component({
    selector: 'cbms-corporate-sso',
    templateUrl: './corporate-sso.component.html',
    styleUrls: ['./corporate-sso.component.scss'],
})
export class CorporateSsoComponent implements OnInit {
    form: FormGroup;
    currentYear = new Date().getFullYear();

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private dataService: DataService,
        private cookieService: CookieService,
        private authorizationService: AuthorizationService,
    ) {
        this.form = this.fb.group({
            email: [''],
        });
    }

    ngOnInit(): void {
        this.authorizationService.clearLoginProviderCookie();
        this.dataService.currentTabHasChanged('corporate-sso');
    }

    signInTo(app: IDP_PROVIDER.TESCO_CC | IDP_PROVIDER.TESCO_DASHBOARDS): void {
        if (this.form.get('email').value.includes('@tesco')) {
            this.setLoginProviderCookie(app);
            this.router.navigate([window.location.origin]);
        }
    }

    goBack(): void {
        this.authorizationService.clearLoginProviderCookie();
        this.router.navigate([window.location.origin]);
    }

    // Set the cookie to expire at the end of the session
    private setLoginProviderCookie(value: string) {
        this.cookieService.set(
            LOGIN_IDP, // Cookie name
            value, // Cookie value
            undefined, // Expires at the end of session
            '/', // Path (root path)
            `.${window.location.hostname}`, // Domain (note the leading dot)
            true, // Secure flag
            'Lax' // SameSite flag
        );
    }

    protected readonly IDP_PROVIDER = IDP_PROVIDER;
}
