import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { SelectItem } from 'primeng/api';
import { FilterParams } from 'src/app/components/filter/interfaces/filter-params.interface';
import { DataPoint } from 'src/app/models/data-point';
import { Site } from 'src/app/models/site';
import { BackendService } from 'src/app/services/backend/backend.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import {
    ActiveDate,
    ActiveDateRange,
    AlarmFilter,
    AlertData,
} from '../../../../models/alert-rule.model';
import { DayOfWeek, Months, MONTH_LIST } from '../../../../config/constants';

const DATE = 'date';
const RANGE = 'dateRange';
@Component({
    selector: 'cbms-add-smart-alert-rule',
    templateUrl: './add-smart-alert-rule.component.html',
    styleUrls: ['./add-smart-alert-rule.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddSmartAlertRuleComponent implements OnInit {
    @Input() selectedSite: Site;
    @Input() alertData: AlertData;
    @Output() close = new EventEmitter<boolean>();

    alertName = '';
    dataPointList: DataPoint[];
    selectedDataPoint: DataPoint;

    alertTypeList: SelectItem[] = [
        { label: 'Maintenance', value: 'Maintenance' },
        { label: 'Energy', value: 'Energy' },
    ];
    alertType: string;
    submitted = false;
    isEdit = false;

    limitWeekdays = false;
    limitTimeRange = false;

    startTime = moment().set('minute', 0).toDate();
    endTime = moment(this.startTime).add(1, 'hours').toDate();

    timeDelayValue: number;

    weekDays: any[] = [
        {
            name: 'Mon',
            isSelected: false,
        },
        {
            name: 'Tue',
            isSelected: false,
        },
        {
            name: 'Wed',
            isSelected: false,
        },
        {
            name: 'Thu',
            isSelected: false,
        },
        {
            name: 'Fri',
            isSelected: false,
        },
        {
            name: 'Sat',
            isSelected: false,
        },
        {
            name: 'Sun',
            isSelected: false,
        },
    ];

    timeOptionList: SelectItem[] = [
        { label: 'Minutes', value: 'minutes' },
        { label: 'Hours', value: 'hours' },
        { label: 'Days', value: 'Days' },
    ];
    timeDelayOption: string;
    alertExpiryOption: string;
    alertExpiryValue: number;

    alertPriorityList: SelectItem[] = [
        { label: 'High', value: 'High' },
        { label: 'Medium', value: 'Medium' },
        { label: 'Low', value: 'Low' },
    ];
    alertPriority: string;
    alertDescription: string = '';

    query: AlarmFilter = {
        condition: 'and',
        rules: [
            {
                condition: null,
                rules: null,
                field: 'datapoint.lastRecordedValue',
                value: '',
                entityName: '',
                isFreeText: true,
            },
        ],
    };

    activeFromForm: FormGroup;

    eventPeriodType = {
        'Single Date': 'date',
        'Date Range': 'dateRange',
    };

    periodTypeName = {
        date: 'Single Date',
        dateRange: 'Date Range',
    };

    showInvalidDateError = {
        singleDay: false,
        rangeStartDate: false,
        rangeEndDate: false,
    };
    showInvalidRangeError = false;

    showInvalidExceptionDateError = {
        singleDay: false,
        rangeStartDate: false,
        rangeEndDate: false,
    };
    showInvalidExceptionRangeError = false;

    singleDay = {
        selectedDay: new Date().getDate(),
        selectedMonth: new Date().getMonth() + 1,
        selectedYear: new Date().getFullYear(),
    };

    dateRangeStartDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    dateRangeEndDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    exceptionSingleDay = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    exceptionDateRangeStartDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    exceptionDateRangeEndDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined,
    };

    eventTypeList: SelectItem[] = [
        { label: 'Single Date', value: 'date', disabled: false },
        { label: 'Date Range', value: 'dateRange', disabled: false },
    ];

    dayList: SelectItem[] = [
        { label: 'Day', value: null },
        { label: 'Any', value: DayOfWeek.Any },
    ];

    dayListWithoutAnyOption: SelectItem[] = [{ label: 'Day', value: null }];

    yearList: SelectItem[] = [
        { label: 'Year', value: '' },
        { label: 'Any', value: DayOfWeek.Any },
    ];

    yearListWithoutAnyOption: SelectItem[] = [{ label: 'Year', value: '' }];

    monthList = MONTH_LIST;
    monthListWithoutAnyOption = [...MONTH_LIST];

    selectedEventType: string;
    selectedExceptionEventType: string;
    selectedExceptionDates: string[] = [];

    exceptionDatesApiFormat: any[] = [];

    emailRecipient: string;
    emailRecipientList: string[] = [];
    maxNoOfEmailsExceeded = false;

    private filterParams: FilterParams;

    constructor(
        private backendService: BackendService,
        private notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.generateDayList();
        this.generateYearList();
        this.monthListWithoutAnyOption.splice(1, 1);

        if (this.alertData) {
            this.isEdit = true;
            this.processRules(this.alertData.alarmFilter.rules);
            this.query = this.alertData.alarmFilter;
            this.alertName = this.alertData.name;
            this.alertType = this.alertData.type;

            this.timeDelayOption = this.alertData.timeDelay.unit;
            this.timeDelayValue = this.alertData.timeDelay.amount;

            this.alertExpiryOption = this.alertData.timeExpiry.unit;
            this.alertExpiryValue = this.alertData.timeExpiry.amount;

            if (this.alertData.activeDateInterval.activeTimeRange?.fromTime) {
                this.limitTimeRange = true;
                this.startTime = moment(
                    this.alertData.activeDateInterval.activeTimeRange.fromTime,
                    'HH:mm'
                ).toDate();
                this.endTime = moment(
                    this.alertData.activeDateInterval.activeTimeRange.toTime,
                    'HH:mm'
                ).toDate();
            }

            this.selectedSite = this.alertData.site;
            this.alertPriority = <string>this.alertData.priority;
            this.alertDescription = this.alertData.description;
            this.emailRecipientList = [...this.alertData.emailRecipients]

            this.setRuleActiveFrom();
            this.setExceptionDates();
            this.setLimitWeekdays();
        }

        this.filterParams = {
            siteFilter: [this.selectedSite],
            isPolled: true,
        };

        this.backendService
            .getDataPointsSummary(this.filterParams, 0, 10000, false)
            .subscribe((response) => {
                this.dataPointList = response.content.map((dataPoint) => {
                    dataPoint.displayName = `${
                        dataPoint.customName ||
                        dataPoint.description ||
                        dataPoint.objectName
                    } (${dataPoint.signalType})`;
                    return dataPoint;
                });
            });
    }

    setRuleActiveFrom() {
        let activeDateString = this.alertData.activeDateInterval.activeDate;
        if (activeDateString) {
            this.selectedEventType = DATE;
            this.singleDay = { ...this.setDate(activeDateString) };
        }

        let activeRangeDateString =
            this.alertData.activeDateInterval.activeDateRange;
        if (activeRangeDateString) {
            this.selectedEventType = RANGE;
            this.dateRangeStartDate = {
                ...this.setDate(activeRangeDateString.fromDate),
            };
            this.dateRangeEndDate = {
                ...this.setDate(activeRangeDateString.toDate),
            };
        }
    }

    setDate(dateString: string) {
        const fromDateString = dateString.replace(/any/g, '0');
        const [year, month, day] = fromDateString.split('-');
        return {
            selectedYear: parseInt(year),
            selectedMonth: parseInt(month),
            selectedDay: parseInt(day),
        };
    }

    setExceptionDates() {
        const exceptionDateList = this.alertData.exceptionDateRangeList;
        this.selectedExceptionDates = exceptionDateList.map(
            (exceptionDate: any) => {
                if (exceptionDate.activeDate) {
                    return exceptionDate.activeDate
                        .split('-')
                        .reverse()
                        .join('/');
                }
                if (exceptionDate.activeDateRange) {
                    return `${exceptionDate.activeDateRange.fromDate
                        .split('-')
                        .reverse()
                        .join('/')} - ${exceptionDate.activeDateRange.toDate
                        .split('-')
                        .reverse()
                        .join('/')}`;
                }
            }
        );
    }

    setLimitWeekdays() {
        const activeWeekDays =
            this.alertData.activeDateInterval.activeWeekdayList;
        if (activeWeekDays?.length) {
            this.limitWeekdays = true;
            this.weekDays.forEach((day) => {
                if (activeWeekDays.indexOf(day.name) !== -1) {
                    day.isSelected = true;
                }
            });
        }
    }

    onSave() {
        const alertData: AlertData = {
            id: this.alertData?.id || null,
            name: this.alertName,
            type: this.alertType,
            isEnabled: true,
            alarmFilter: this.query,
            site: {
                id: this.selectedSite.id,
            },
            activeDateInterval: {
                activeDate: this.isSingleDate(this.selectedEventType)
                    ? this.generateApiDateFormat(this.singleDay)
                    : null,
                activeDateRange: this.isDateRange(this.selectedEventType)
                    ? {
                          fromDate: this.generateApiDateFormat(
                              this.dateRangeStartDate
                          ),
                          toDate: this.generateApiDateFormat(
                              this.dateRangeEndDate
                          ),
                      }
                    : null,
                activeTimeRange: this.limitTimeRange
                    ? {
                          fromTime: moment(this.startTime).format('HH:mm'),
                          toTime: moment(this.endTime).format('HH:mm'),
                      }
                    : null,
                activeWeekdayList: this.limitWeekdays
                    ? this.weekDays
                          .filter((day) => day.isSelected)
                          .map((day) => day.name)
                    : null,
            },
            exceptionDateRangeList: this.generateApiExceptionDatesFormat(),
            timeDelay: {
                amount: this.timeDelayValue,
                unit: this.timeDelayOption,
            },
            timeExpiry: {
                amount: this.alertExpiryValue,
                unit: this.alertExpiryOption,
            },
            priority: this.alertPriority,
            description: this.alertDescription,
            emailRecipients: this.emailRecipientList
        };

        if (this.isEdit) {
            alertData.id = this.alertData.id;
            this.updateAlertRule(alertData);
        } else {
            this.createAlertRule(alertData);
        }
    }

    createAlertRule(alertData) {
        this.backendService.createAlert(alertData).subscribe(() => {
            this.notificationService.addSuccessMessage(
                'Smart Alert Rule',
                'Rule successfully created!'
            );

            this.close.emit(true);
        });
    }

    updateAlertRule(alertData: any) {
        this.backendService.updateAlert(alertData).subscribe(() => {
            this.notificationService.addSuccessMessage(
                'Smart Alert Rule',
                'Rule successfully updated!'
            );

            this.close.emit(true);
        });
    }

    cancel() {
        this.close.emit(true);
    }

    addException() {
        this.resetExceptionDatesErrorMessage();

        if (
            this.selectedExceptionEventType === 'date' &&
            !this.isValidDate(this.exceptionSingleDay)
        ) {
            this.showInvalidExceptionDateError.singleDay = true;
            return;
        }
        if (this.selectedExceptionEventType === 'dateRange') {
            if (
                !this.isValidDate(this.exceptionDateRangeStartDate) ||
                !this.isValidDate(this.exceptionDateRangeEndDate)
            ) {
                this.showInvalidExceptionDateError.rangeStartDate =
                    !this.isValidDate(this.exceptionDateRangeStartDate);
                this.showInvalidExceptionDateError.rangeEndDate =
                    !this.isValidDate(this.exceptionDateRangeEndDate);
                return;
            }
            if (
                !this.isValidRangeDate(
                    this.exceptionDateRangeStartDate,
                    this.exceptionDateRangeEndDate
                )
            ) {
                this.showInvalidExceptionRangeError = true;
                return;
            }
        }
        if (this.selectedExceptionEventType === 'dateRange') {
            if (
                this.selectedExceptionDates.indexOf(
                    this.generateDateRange()
                ) === -1
            ) {
                this.selectedExceptionDates.push(this.generateDateRange());
                this.exceptionDatesApiFormat.push({
                    activeDateRange: {
                        fromDate: this.generateApiDateFormat(
                            this.exceptionDateRangeStartDate
                        ),
                        toDate: this.generateApiDateFormat(
                            this.exceptionDateRangeEndDate
                        ),
                    },
                });
            }
        }

        if (this.selectedExceptionEventType === 'date') {
            if (
                this.selectedExceptionDates.indexOf(
                    this.generateSingleDate(this.exceptionSingleDay)
                ) === -1
            ) {
                this.selectedExceptionDates.push(
                    this.generateSingleDate(this.exceptionSingleDay)
                );
                this.exceptionDatesApiFormat.push({
                    activeDate: this.generateApiDateFormat(
                        this.exceptionSingleDay
                    ),
                });
            }
        }
    }

    removeExceptionDate(index: number) {
        this.selectedExceptionDates.splice(index, 1);
    }

    validateFromSingleDay() {
        if (!this.isValidDate(this.singleDay)) {
            this.showInvalidDateError.singleDay = true;
        } else {
            this.showInvalidDateError.singleDay = false;
        }
    }

    validateFromDateRange() {
        this.resetDatesErrorMessage();

        if (
            !this.isValidDate(this.dateRangeStartDate) ||
            !this.isValidDate(this.dateRangeEndDate)
        ) {
            this.showInvalidDateError.rangeStartDate = !this.isValidDate(
                this.dateRangeStartDate
            );
            this.showInvalidDateError.rangeEndDate = !this.isValidDate(
                this.dateRangeEndDate
            );
            return;
        }
        if (
            !this.isValidRangeDate(
                this.dateRangeStartDate,
                this.dateRangeEndDate
            )
        ) {
            this.showInvalidRangeError = true;
            return;
        }
    }

    activeFromEventTypeChange() {
        this.resetDatesErrorMessage();
        if (this.selectedEventType === 'date') {
            this.validateFromSingleDay();
        }
        if (this.selectedEventType === 'dateRange') {
            this.validateFromDateRange();
        }
    }

    addEmailRecipient() {
        if (this.emailRecipientList.indexOf(this.emailRecipient) === -1) {
            this.emailRecipientList.push(this.emailRecipient);
            this.emailRecipient = '';
        }
        this.maxNoOfEmailsExceeded = this.emailRecipientList.length >= 20;
    }

    removeEmailRecipient(index: number) {
        this.emailRecipientList.splice(index, 1);
        this.maxNoOfEmailsExceeded = this.emailRecipientList.length >= 20;
    }

    private processRules(rules: AlarmFilter[]) {
        if (!rules || !Array.isArray(rules)) {
            return;
        }

        for (const rule of rules) {
            // Process the current rule here
            rule.isFreeText = !['true', 'false'].includes(rule.value);

            // Recursively process nested rules
            if (this.ruleHasChildren(rule)) {
                this.processRules(rule.rules);
            }
        }
    }

    private ruleHasChildren(rule) {
        return rule.rules && Array.isArray(rule.rules);
    }

    private generateDayList() {
        for (let i = 1; i <= 31; i++) {
            let dayNo = `${i}`;
            if (i < 10) {
                dayNo = `0${i}`;
            }
            this.dayList.push({ label: `${dayNo}`, value: i });
            this.dayListWithoutAnyOption.push({ label: `${dayNo}`, value: i });
        }
    }

    private generateYearList() {
        const currentYear = new Date().getFullYear();
        for (let i = 0; i <= 20; i++) {
            this.yearList.push({
                label: `${currentYear + i}`,
                value: currentYear + i,
            });
            this.yearListWithoutAnyOption.push({
                label: `${currentYear + i}`,
                value: currentYear + i,
            });
        }
    }

    private isValidDate(dateObj) {
        let hasWildcard = false;
        let invalidDate = false;

        Object.keys(dateObj).forEach((key) => {
            if (dateObj[key] === undefined || dateObj[key] === '') {
                invalidDate = true;
            }
            if (dateObj[key] === 0) {
                hasWildcard = true;
            }
        });

        if (invalidDate) {
            return false;
        }

        if (hasWildcard) {
            return true;
        }

        let date = moment(
            `${dateObj.selectedMonth}/${dateObj.selectedDay}/${dateObj.selectedYear}`,
            'MM/DD/YYYY'
        );

        return (
            date.isValid() && (date.isSame(new Date(), 'day') || date.isAfter())
        );
    }

    private isValidRangeDate(startDateObj, endDateObj) {
        if (
            !this.isWildCardPositionValid(
                startDateObj.selectedDay,
                endDateObj.selectedDay
            )
        ) {
            return false;
        }

        if (
            !this.isWildCardPositionValid(
                startDateObj.selectedMonth,
                endDateObj.selectedMonth
            )
        ) {
            return false;
        }

        if (
            !this.isWildCardPositionValid(
                startDateObj.selectedYear,
                endDateObj.selectedYear
            )
        ) {
            return false;
        }

        const today = new Date();
        const currentYear = today.getFullYear();

        let startDate = moment(
            `${startDateObj.selectedMonth || 1}/${
                startDateObj.selectedDay || 1
            }/${startDateObj.selectedYear || currentYear}`,
            'MM/DD/YYYY'
        );
        let endDate = moment(
            `${endDateObj.selectedMonth || 2}/${endDateObj.selectedDay || 2}/${
                endDateObj.selectedYear || currentYear + 1
            }`,
            'MM/DD/YYYY'
        );

        if (!startDate.isValid() || !endDate.isValid()) {
            return false;
        }

        return startDate.isBefore(endDate);
    }

    private isWildCardPositionValid(start, end) {
        if ((start === 0 || end === 0) && start !== end) {
            return false;
        }
        return true;
    }

    private resetDatesErrorMessage() {
        this.showInvalidDateError = {
            singleDay: false,
            rangeStartDate: false,
            rangeEndDate: false,
        };
        this.showInvalidRangeError = false;
    }

    private resetExceptionDatesErrorMessage() {
        this.showInvalidExceptionDateError = {
            singleDay: false,
            rangeStartDate: false,
            rangeEndDate: false,
        };
        this.showInvalidExceptionRangeError = false;
    }

    private generateSingleDate(dataObj) {
        return `${this.addZero(
            this.parseValue(dataObj.selectedDay)
        )}/${this.addZero(
            this.parseValue(dataObj.selectedMonth)
        )}/${this.parseValue(dataObj.selectedYear)}`.replace(/null/g, '*');
    }

    private generateDateRange() {
        const startDateSelectedMonth = this.addZero(
            this.parseValue(this.exceptionDateRangeStartDate.selectedMonth)
        );
        const endDateSelectedMonth = this.addZero(
            this.parseValue(this.exceptionDateRangeEndDate.selectedMonth)
        );

        return `${this.addZero(
            this.parseValue(this.exceptionDateRangeStartDate.selectedDay)
        )}/${startDateSelectedMonth}/${this.parseValue(
            this.exceptionDateRangeStartDate.selectedYear
        )} - ${this.addZero(
            this.parseValue(this.exceptionDateRangeEndDate.selectedDay)
        )}/${endDateSelectedMonth}/${this.parseValue(
            this.exceptionDateRangeEndDate.selectedYear
        )}`.replace(/null/g, '*');
    }

    private generateApiDateFormat(dateObj) {
        return `${this.parseValue(dateObj.selectedYear)}-${this.parseValue(
            this.addZero(dateObj.selectedMonth)
        )}-${this.addZero(this.parseValue(dateObj.selectedDay))}`.replace(
            /null/g,
            'any'
        );
    }

    private generateApiExceptionDatesFormat() {
        return this.selectedExceptionDates.map((exceptionDate: any) => {
            const splittedDate = exceptionDate.split(' - ');

            if (splittedDate.length === 1) {
                return {
                    activeDate: splittedDate[0].split('/').reverse().join('-').replaceAll('*', 'any'),
                };
            }
            if (splittedDate.length === 2) {
                return {
                    activeDateRange: {
                        fromDate: splittedDate[0]
                            .split('/')
                            .reverse()
                            .join('-').replaceAll('*', 'any'),
                        toDate: splittedDate[1].split('/').reverse().join('-').replaceAll('*', 'any'),
                    },
                };
            }
        });
    }

    private addZero(index: number) {
        if (index > 0 && index < 10) {
            return `0${index}`;
        }
        return index;
    }

    private parseValue(value: any) {
        return parseInt(value) === 0 ? null : value;
    }

    private isSingleDate(eventType: string) {
        return eventType === DATE;
    }

    private isDateRange(eventType: string) {
        return eventType === RANGE;
    }
}
