import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { DataPoint } from '../../models/data-point';
import { BackendService } from '../../services/backend/backend.service';
import { NotificationService } from '../../services/notification/notification.service';
import { UtilsService } from '../../services/utils/util.service';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { ActionConfig } from '../../models/action-config';
import { EventNotificationService, EventType } from '../../services/notification/event-notification.service';
import { StoreService } from '../../services/data/store.service';
import { Subscription } from 'rxjs';
import { dataPointApiSortFieldMapper } from '../../models/api/data-point';
import { MenuItem, MessageService } from 'primeng/api';


const APP_CONTENT_SELECTOR = '.app-content';

@Component({
    selector: 'cbms-data-points-table',
    templateUrl: './data-points-table.component.html',
    styleUrls: ['./data-points-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DataPointsTableComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
    @Input()
    public dataPoints: DataPoint[] = [];
    @Input()
    public totalRecords: number = null;
    @Input()
    public columnsConfig: string[];
    @Input()
    public isSelectedDatapointMode: boolean;
    @Input()
    public tabName: string;
    @Input()
    public actionConfig: ActionConfig;
    @Input()
    public allDPSelected: boolean;
    @Input()
    private selectedDataPointList: { [key: string]: DataPoint | {id: string} };

    @Output()
    selectedDataPointEmitter = new EventEmitter<DataPoint[]>();
    @Output()
    toggleAllDataPointsSelectionEmitter = new EventEmitter<boolean>();
    @Output()
    lazyLoadEvent = new EventEmitter<LazyLoadEvent>();

    public mappedDataPoints: DataPoint[];
    public selectedDataPoints = [];
    public tableName: string = 'dataPoints';
    public actionsColumnWidth: string = '';
    public isLoading: boolean = false;
    public rows = this.utilsService.getDataPointsPageSize();
    public backupData: { [s: string]: string } = {};
    public editing: { [key: string]: boolean } = {};

    public cols: any[] = [
        { field: dataPointApiSortFieldMapper.deviceSiteName, uiField: 'deviceSiteName', header: 'Site', sortable: true, position: 1, width: '100px' },
        { field: dataPointApiSortFieldMapper.deviceName, header: 'Device', uiField: 'deviceName', sortable: true, position: 2, width: '140px' },
        { field: dataPointApiSortFieldMapper.objectName, header: 'Name', uiField: 'objectName', sortable: true, position: 3, width: '170px' },
        { field: dataPointApiSortFieldMapper.type, header: 'IO-Type', uiField: 'type', sortable: true, position: 4, width: '110px' },
        { field: dataPointApiSortFieldMapper.signalType, header: 'Signal Type', uiField: 'signalType', sortable: true, position: 5, width: '140px' },
        { field: dataPointApiSortFieldMapper.source, header: 'Source', uiField: 'source', sortable: true, position: 6, width: '110px' },
        { field: dataPointApiSortFieldMapper.sourceId, header: 'Source ID', uiField: 'sourceId', sortable: true, position: 7, width: '120px' },
        { field: dataPointApiSortFieldMapper.id, header: 'Datapoint ID', uiField: 'id', sortable: true, position: 8, width: '230px' },
        { field: dataPointApiSortFieldMapper.description, header: 'Source Description', uiField: 'description', sortable: true, position: 9, width: '180px' },
        { field: dataPointApiSortFieldMapper.presentValue, header: 'Value', uiField: 'presentValue', sortable: true, position: 10, width: '90px' },
        { field: dataPointApiSortFieldMapper.presentValueTimestamp, header: 'Time', uiField: 'presentValueTimestamp', sortable: true, position: 11, width: '150px' },
        { field: dataPointApiSortFieldMapper.units, header: 'Units', uiField: 'units', sortable: true, position: 12, width: '90px' },
        { field: dataPointApiSortFieldMapper.twinSchedules, header: 'Weekly Schedule Twin', uiField: 'twinSchedules', sortable: true, position: 13, width: '180px' },
        { field: dataPointApiSortFieldMapper.twinCreationDate, header: 'Twin creation time', uiField: 'twinCreationDate', sortable: true, position: 14, width: '180px' },
        { field: dataPointApiSortFieldMapper.twinDatapointValue, header: 'Twin Value', uiField: 'twinDatapointValue', sortable: true, position: 13, width: '150px' },
        { field: dataPointApiSortFieldMapper.twinDatapointCreationDate, header: 'Twin creation time', uiField: 'twinDatapointCreationDate', sortable: true, position: 14, width: '150px' },
        { field: dataPointApiSortFieldMapper.ocSiteTenantName, header: 'OC Tenant', uiField: 'ocSite.tenant', sortable: true, position: 15, width: '150px' },
        { field: dataPointApiSortFieldMapper.ocSiteCustomerName, header: 'OC Customer', uiField: 'ocSite.customer', sortable: true, position: 16, width: '150px' },
        { field: dataPointApiSortFieldMapper.ocSiteName, header: 'OC Site', uiField: 'ocSite.name', sortable: true, position: 17, width: '150px' },
        { field: dataPointApiSortFieldMapper.ocClassification, header: 'OC Classification', uiField: 'ocSite.classification', sortable: true, position: 18, width: '100px' },
        { field: dataPointApiSortFieldMapper.ocChannel, header: 'OC Channel', uiField: 'ocSite.channel', sortable: true, position: 18, width: '100px' },
        { field: dataPointApiSortFieldMapper.ocGranularity, header: 'OC Granularity', uiField: 'ocSite.granularity', sortable: true, position: 18, width: '100px' },
        { field: dataPointApiSortFieldMapper.ocIsAccumulator, header: 'OC Accumulator', uiField: 'ocSite.isAccumulator', sortable: true, position: 19, width: '100px' },
        { field: dataPointApiSortFieldMapper.ocDataSource, header: 'OC DataSource', uiField: 'ocSite.dataSource', sortable: true, position: 20, width: '100px' },
        { field: dataPointApiSortFieldMapper.tags, header: 'Tags', uiField: 'tags', sortable: true, position: 21, width: '200px' },
        { field: dataPointApiSortFieldMapper.customName, header: 'Custom Name', uiField: 'customName', sortable: true, position: 22, width: '165px' },

    ];

    public multiSortMeta: any[] = [
        { field: dataPointApiSortFieldMapper.deviceSiteName, order: 1 },
        { field: dataPointApiSortFieldMapper.deviceName, order: 1 },
        { field: dataPointApiSortFieldMapper.objectName, order: 1 }
    ]

    public selectedDataPoint: DataPoint;
    public uiCols: any[];
    public ocItems: MenuItem[];
    public twinItems: MenuItem[];
    public pointItems: MenuItem[];

    public selectedColumns: any[] = [];
    private subscriptionList: Subscription[] = [];
    private hiddenColumns: string[] = ['twinDatapointCreationDate','sourceId', 'id', 'source',
        'ocSite.tenant','ocSite.customer','ocSite.name', 'ocSite.classification', 'ocSite.channel', 'ocSite.granularity','ocSite.isAccumulator','ocSite.dataSource'];
    private lastIndex: number;

    constructor(
        private utilsService: UtilsService,
        private backendService: BackendService,
        private notificationService: NotificationService,
        private eventNotificationService: EventNotificationService,
        private storeService: StoreService,
        private confirmationService: ConfirmationService) {
    }

    public ngOnInit() {
        this.selectedDataPoints = Object.keys(this.selectedDataPointList).map( (key) => this.selectedDataPointList[key]);

        sessionStorage.setItem('multiSortOptions', JSON.stringify(this.multiSortMeta));

        this.actionsColumnWidth = this.tabName === 'dataPoints' ? '150px' : '200px';
        this.uiCols = this.columnsConfig.length ? this.cols.filter(col => this.columnsConfig.includes(col['uiField'])) : this.cols;

        this.initSelectedColumns();

        this.subscriptionList.push(this.eventNotificationService.getWeeklyScheduleTemplateSelected$()
            .subscribe(_ => {
                if (this.selectedDataPoint) {
                    this.toggleSelectedOrAssignSelectedDatapoint(this.selectedDataPoint);
                }
            }));
        this.subscriptionList.push(this.eventNotificationService.getExceptionScheduleTemplateSelected$()
            .subscribe(_ => {
                if (this.selectedDataPoint) {
                    this.toggleSelectedOrAssignSelectedDatapoint(this.selectedDataPoint);
                }
            }));
    }

    public ngAfterViewInit() {
        let appWrapperDiv = <HTMLElement>document.querySelector(APP_CONTENT_SELECTOR);
        appWrapperDiv.style.overflow = "hidden";
    }

    public ngOnDestroy() {
        let appWrapperDiv = <HTMLElement>document.querySelector(APP_CONTENT_SELECTOR);
        appWrapperDiv.style.overflow = "auto";
        this.subscriptionList.forEach(value => value.unsubscribe());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.dataPoints && changes.dataPoints.currentValue) {
            this.mappedDataPoints = [...changes.dataPoints.currentValue];
            this.mappedDataPoints.forEach((dataPoint: DataPoint) => {
                dataPoint.tags = this.utilsService.orderListByFieldName(dataPoint.tags, 'name');
            });

            setTimeout(() => {
                this.isLoading = false;
            }, 100);
        }

        if (changes.totalRecords && changes.totalRecords.currentValue) {
            this.totalRecords = changes.totalRecords.currentValue;
        }

        if (changes.allDPSelected && changes.allDPSelected.currentValue) {
            this.allDPSelected = changes.allDPSelected.currentValue;
        }

        if (changes.selectedDataPointList && changes.selectedDataPointList.currentValue) {
            this.selectedDataPointList = {...changes.selectedDataPointList.currentValue};
            this.selectedDataPoints = Object.keys(this.selectedDataPointList).map( (key) => this.selectedDataPointList[key]);
        }
    }

    public onSort(event) {
        sessionStorage.setItem('multiSortOptions', JSON.stringify(event.multisortmeta));
        setTimeout(() => {
            let tableBody = document.getElementsByClassName('p-datatable-wrapper')[0];
            tableBody.scroll(0, 0);
        });
    }

    public updateSelectedDPList(dataPoint: DataPoint, index: number, event: any) {
        if (event.originalEvent.shiftKey === false) {
            this.selectedDataPointEmitter.emit([dataPoint]);
        }

        if (event.originalEvent.shiftKey === true) {
            let start = index % 100;
            let end = this.lastIndex ? this.lastIndex % 100 : start;
            dataPoint.selected = true;

            let selectedDataPoints = [];
            for(let i= Math.min(start, end); i<= Math.max(start, end); i++) {
                this.dataPoints[i].selected = true;
                selectedDataPoints.push(this.dataPoints[i]);
            }
            this.selectedDataPointEmitter.emit(selectedDataPoints);
        }
        this.lastIndex = index;
    }

    public toggleSelectedDP(event: MouseEvent) {
        event.stopPropagation();

        if (this.allDPSelected) {
            this.confirmationService.confirm({
                message: 'You have selected all datapoints, are you sure you want to continue?',
                accept: () => {
                    this.toggleAllDataPointsSelectionEmitter.emit(true);
                },
                reject: () => {
                    this.allDPSelected = !this.allDPSelected;
                }
            });
        } else {
            this.confirmationService.confirm({
                message: 'You have de-selected all datapoints, are you sure you want to continue?',
                accept: () => {
                    this.toggleAllDataPointsSelectionEmitter.emit(false);
                },
                reject: () => {
                    this.allDPSelected = !this.allDPSelected;
                }
            });
        }
    }

    public loadDPLazy(event: LazyLoadEvent) {
        this.isLoading = true;
        this.lazyLoadEvent.emit(event);
    }

    public onCellEditInit(dataPoint: DataPoint) {
        this.editing[dataPoint.id] = true;
        this.backupData[dataPoint.id] = dataPoint.customName;
    }

    public onCellEditSave(dataPoint: DataPoint) {
        this.editing[dataPoint.id] = false;
        if (dataPoint.customName === this.backupData[dataPoint.id]) return;

        this.backendService.updateDataPointUserDescription(dataPoint.id, dataPoint.customName).subscribe((res) => {
            this.notificationService.addSuccessMessage('Edit', 'Custom name successfully updated.');
        });
    }

    public onCellEditCancel(dataPoint: DataPoint) {
        this.editing[dataPoint.id] = false;
        dataPoint.customName = this.backupData[dataPoint.id];
        delete this.backupData[dataPoint.id];
    }

    public selectedColumnsHaveChanged(event) {
        this.selectedColumns = this.utilsService.orderListByFieldName(event, 'position');
        sessionStorage.setItem(`${this.tabName}-selectedColumns`, JSON.stringify(this.selectedColumns));
    }

    public onRowSelected(dataPoint: DataPoint) {
        this.toggleSelectedOrAssignSelectedDatapoint(dataPoint);
        if (this.selectedDataPoint) {
            this.selectedDataPoint.selected = !this.selectedDataPoint.selected;
        }
        this.selectedDataPointEmitter.emit([dataPoint]);
    }

    public updateDataPointsTags(updatedDataPoints: DataPoint[]) {
        updatedDataPoints.forEach(updatedDataPoint => {
            this.mappedDataPoints.forEach(dataPoint => {
                if (dataPoint.id === updatedDataPoint.id) {
                    dataPoint.tags = updatedDataPoint.tags;
                };
            });
        });
    }

    public updateDatapointTwinCreation(updatedDataPoints: DataPoint[]) {
        updatedDataPoints.forEach(updatedDataPoint => {
            this.mappedDataPoints.forEach(dataPoint => {
                if (dataPoint.id === updatedDataPoint.id) {
                    dataPoint.twinDatapointCreationDate = updatedDataPoint.twinDatapointCreationDate;
                    dataPoint.twinDatapointValue = updatedDataPoint.twinDatapointValue;
                };
            });
        });
    }

    public updateDatapointOcSite(updatedDataPoints: DataPoint[]) {
        updatedDataPoints.forEach(updatedDataPoint => {
            this.mappedDataPoints.forEach(dataPoint => {
                if (dataPoint.id === updatedDataPoint.id) {
                    dataPoint.ocSite = updatedDataPoint.ocSite;
                }
            });
        });
    }

    public updateTwinCreation(updatedDataPoints: DataPoint[]) {
        updatedDataPoints.forEach(updatedDataPoint => {
            this.mappedDataPoints.forEach(dataPoint => {
                if (dataPoint.id === updatedDataPoint.id) {
                    dataPoint.twinCreationDate = updatedDataPoint.twinCreationDate;
                    dataPoint.twinSchedules = updatedDataPoint.twinSchedules;
                };
            });
        });
    }

    public onPage(event) {
        this.lastIndex = null;
        //fix for - on page refresh data is loaded from first page but the selected page doesn't change to 1
        let tableState = JSON.parse(sessionStorage.getItem(`${this.tabName}-session`));
        if (tableState === null) {
            return;
        }

        setTimeout(() => {
            tableState.first = 0;
            sessionStorage.setItem(`${this.tabName}-session`, JSON.stringify(tableState));
        }, 100);
    }

    private toggleSelectedOrAssignSelectedDatapoint(dataPoint: DataPoint) {
        if (this.selectedDataPoint && this.selectedDataPoint.id == dataPoint.id) {
            //unselect flow
            this.selectedDataPoint.selected = false;
            this.selectedDataPoint = null;
        } else {
            //select flow
            //first unselect other sources, e.g a template
            if (this.storeService.selectedWeeklyScheduleTemplate) {
                this.eventNotificationService.emittEvent({
                    type: EventType.WEEKLY_SCHEDULE_TEMPLATE_UNSELECTED,
                    payload: { id: this.storeService.selectedWeeklyScheduleTemplate.id }
                });
            }
            if (this.storeService.selectedExceptionScheduleTemplate) {
                this.eventNotificationService.emittEvent({
                    type: EventType.EXCEPTION_SCHEDULE_TEMPLATE_UNSELECTED,
                    payload: { id: this.storeService.selectedExceptionScheduleTemplate.id }
                });
            }

            this.selectedDataPoint = dataPoint;
        }
    }

    private scrollToTableHead() {
        const htmlDataPointsTableRef = document.getElementsByClassName('ui-table-scrollable-body')[0];
        htmlDataPointsTableRef.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    private initSelectedColumns() {
        let selectedColumnsFromSession = JSON.parse(sessionStorage.getItem(`${this.tabName}-selectedColumns`));

        if (selectedColumnsFromSession) {
            this.selectedColumns = selectedColumnsFromSession;
        } else {
            this.selectedColumns = this.uiCols.filter(col => {
                return !this.hiddenColumns.includes(col.uiField);
            });
        }
    }

}
