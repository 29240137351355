import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BackendService, getPageSortPair, getSiteList, SortOrderType } from '../../services/backend/backend.service';
import { NotificationService } from '../../services/notification/notification.service';
import { PAGE_SIZE_1000, ROLE_ADMIN, SUCCESS, SUCCESS_CREATED_OPERATION, SUCCESS_UPDATED_OPERATION, ViewMode } from '../../config/constants';
import { EventNotificationService, EventType } from '../../services/notification/event-notification.service';
import { Subscription } from 'rxjs';
import { Site } from '../../models/site';
import { DataService } from '../../services/data/data.service';
import { FilterService } from 'src/app/components/filter/filter.service';
import { LazyLoadEvent } from 'primeng/api';
import { SiteResponse } from '../../models/api/response';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { ROLE_PERMISSIONS } from '../../models/user';

@Component({
    selector: 'cbms-sites',
    templateUrl: './sites.component.html',
    styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit, OnDestroy {
    public isLoading = false;
    public showSidebar: boolean = false;
    public customerList: any[] = [];

    public formGroup: FormGroup;
    // if we are in an update flow, the selected site will exist
    public selectedSite: Site = null;
    public viewMode: ViewMode = ViewMode.READ_ONLY_MODE;
    public readonly EDIT_MODE = ViewMode.EDIT_MODE;
    public pageSize: number = PAGE_SIZE_1000;

    public requestInProgress = false;
    public siteList: Site[] = [];
    public totalRecords: number;
    public selectedSites: Site[] = [];

    public timezoneList: Array<{ label: string, value: string }> = [];

    public userRolePermissionList: string;
    protected readonly ROLE_PERMISSIONS = ROLE_PERMISSIONS;


    public get customer() {
        return this.formGroup.get('customer').value;
    }

    public get siteName() {
        return this.formGroup.get('siteName').value;
    }

    public get readonlySite() {
        return this.formGroup.get('readonlySite').value;
    }

    public get dashboardEnabled() {
        return this.formGroup.get('dashboardEnabled').value;
    }

    public get siteOverviewEnabled() {
        return this.formGroup.get('siteOverviewEnabled').value;
    }

    public get timezone() {
        return this.formGroup.get('timezone').value;
    }

    public set customer(customer) {
        this.formGroup.get('customer').setValue(customer);
    }

    public set siteName(name) {
        this.formGroup.get('siteName').setValue(name);
    }

    public set readonlySite(isReadonly) {
        this.formGroup.get('readonlySite').setValue(isReadonly);
    }

    public set dashboardEnabled(value) {
        this.formGroup.get('dashboardEnabled').setValue(value);
    }

    public set siteOverviewEnabled(value) {
        this.formGroup.get('siteOverviewEnabled').setValue(value);
    }

    public set timezone(value) {
        this.formGroup.get('timezone').setValue(value);
    }

    private subscription: Subscription;
    private sitesSubscription: Subscription;

    constructor(private formBuilder: FormBuilder,
                private backendService: BackendService,
                private notificationService: NotificationService,
                private eventNotificationService: EventNotificationService,
                private dataService: DataService,
                private dialogConfigService: DialogConfigService,
                private filterService: FilterService) {
    }

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('sites');
        this.isLoading = true;
        this.formGroup = this.formBuilder.group({
            customer: new FormControl({id: JSON.parse(sessionStorage.getItem('selectedCustomer')).id}, Validators.required),
            siteName: new FormControl('', [Validators.required, Validators.minLength(3)]),
            timezone: new FormControl(moment.tz.guess(), Validators.required),
            readonlySite: new FormControl(false),
            dashboardEnabled: new FormControl(false),
            siteOverviewEnabled: new FormControl(false),
        });

        this.formGroup.get('dashboardEnabled').valueChanges.subscribe((value) => {
            if (value) {
                this.formGroup.get('siteOverviewEnabled').enable();
            } else {
                this.formGroup.get('siteOverviewEnabled').disable()
                this.formGroup.get('siteOverviewEnabled').setValue(false);
            }
        })
        this.backendService.getAllCustomersFromResponse().subscribe(value => {
                this.customerList = [...value.content].map(value1 => {
                        return {label: value1.name, value: {id: value1.id}};
                    }
                );
                this.isLoading = false;
            }
        );

        this.subscription = this.eventNotificationService.getSiteEdited$().subscribe(event => {
            this.selectedSite = JSON.parse(event.payload.jsonData);
            this.customer = {id: this.selectedSite.customerId};
            this.siteName = this.selectedSite.name;
            this.readonlySite = this.selectedSite.readOnly;
            this.timezone = this.selectedSite.timezone;
            this.dashboardEnabled = this.selectedSite.dashboardEnabled;
            this.siteOverviewEnabled = this.selectedSite.siteOverviewEnabled;
            this.viewMode = ViewMode.EDIT_MODE;
            this.showSidebar = true;
        });

        this.sitesSubscription = this.filterService.siteFilterChanges$.subscribe(() => {
            this.loadData();
        });


        this.timezoneList = moment.tz.names().map(timezoneName => ({label: timezoneName, value: timezoneName}));

        this.userRolePermissionList = sessionStorage.getItem(
            'userRolePermissionList'
        );
    }

    hasPermission(permission: string) {
        return this.userRolePermissionList.includes(permission)
    }

    resetCloseForm() {
        this.showSidebar = false;
        this.formGroup.reset();
        this.selectedSite = null;
        this.viewMode = ViewMode.READ_ONLY_MODE;
    }

    saveSite() {
        this.backendService.saveSite({
            id: this.selectedSite ? this.selectedSite.id : null,
            customerId: this.customer.id,
            name: this.siteName,
            readOnly: this.readonlySite,
            dashboardEnabled: this.dashboardEnabled,
            siteOverviewEnabled: this.siteOverviewEnabled,
            timezone: this.timezone
        }).subscribe(site => {
            if (ViewMode.EDIT_MODE === this.viewMode) {
                this.notificationService.addSuccessMessage(SUCCESS, `Site ${SUCCESS_UPDATED_OPERATION}`);
            } else {
                this.notificationService.addSuccessMessage(SUCCESS, `Site ${SUCCESS_CREATED_OPERATION}`);
            }
            this.eventNotificationService.emittEvent({type: EventType.SITE_CREATED, payload: {id: site.id}});
            this.resetCloseForm();
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if(this.sitesSubscription) {
            this.sitesSubscription.unsubscribe();
        }
    }

    showAddNewSiteSidebar() {
        this.showSidebar = true;
        this.customer = {id: JSON.parse(sessionStorage.getItem('selectedCustomer')).id};
    }

    exportSiteExcel() {
        let date = new Date();
        let time = `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}_${date.getHours()}${date.getMinutes()}`;
        const selectedCustomer = JSON.parse(sessionStorage.getItem('selectedCustomer'));
        this.requestInProgress = true;
        this.backendService.exportSitesExcel().subscribe(response => {
            saveAs(response, `${selectedCustomer.name}_Sites_Export_${time}.xlsx`);
            this.requestInProgress = false;
        }, (err) => { console.log(err) });
    }

    uploadSiteExcel(event: any, sitesFileUpload) {
        this.requestInProgress = true;
        this.backendService.uploadSitesExcel(event.files[0]).subscribe(
            () => {
                sitesFileUpload.clear();
                this.requestInProgress = false;
                this.loadData();
                this.notificationService.addSuccessMessage(SUCCESS, `Import success! Customer's Sites data has been updated.`);
            },
            () => {
                sitesFileUpload.clear();
                this.notificationService.addErrorMessage('Validation Failed', 'Import does not pass validation. Please check result message in Action Log.');
                this.requestInProgress = false;
            });
    }

    public loadSitesLazy(event: LazyLoadEvent) {
        if (!event || !event.sortField) {
            this.loadData();
            return;
        }

        const {pageNumber, sortOptions} = getPageSortPair(event, this.pageSize);
        this.backendService.getSites(pageNumber, this.pageSize, true, sortOptions).subscribe(response => this.setData(response));
    }

    public showTagManagementDialog(selectedSites: Site[]) {
        const ref = this.dialogConfigService.showSitesTagManagementDialog(selectedSites);
        ref.onClose.subscribe((shouldUpdateSites: boolean) => {
            if (shouldUpdateSites) {
                this.loadData();
            }
        });
    }

    public showDashboardsManagementDialog(selectedSites: Site[]) {
        const ref = this.dialogConfigService.showDashboardsManagementDialog(selectedSites);
        ref.onClose.subscribe((shouldUpdateSites: boolean) => {
            if (shouldUpdateSites) {
                this.loadData();
            }
        });
    }

    deleteSelectedItems() {
        this.backendService.deleteSitesByIdList(this.selectedSites.map(site => site.id))
            .subscribe(_ => {
                this.notificationService.addSuccessMessage('Delete Sites', 'Sites deleted successfully');
                this.selectedSites=[];
                this.loadData();
            });
    }

    selectedSitesEmitterHandler($event: Site[]) {
        this.selectedSites = $event;
    }

    private setData(response: { content: SiteResponse[]; totalElements: number }) {
        this.siteList = getSiteList(response);
        this.totalRecords = response.totalElements;
    }

    private loadData() {
        this.requestInProgress = true;
        this.backendService.getSites(0, this.pageSize, true, {
                sortField: 'customer.name,name',
                sortOrder: SortOrderType.ASC
            }
        ).subscribe(response => {
            this.setData(response);
            this.requestInProgress = false;
        });
    }
}
