<div class="content">
    <div class="cbms-spinner-wrapper" *ngIf="isLoadingProperties">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>

    <p-treeTable class="cbms-generic-tree-table-style" *ngIf="properties" [value]="properties" [scrollable]="true" [scrollHeight]="'500px'">
        <ng-template pTemplate="header">
            <tr>
                <th class="cbms-text-align-left" style="width: 30%; padding-left: 40px;">Name</th>
                <th class="cbms-text-align-left">Value</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr>
                <td style="width: 30%">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{rowData.name}}
                </td>
                <td class="intercept-cell" *ngIf="rowData.name === 'intercept'; else normalCell">
                    <ng-container *ngIf="editing[dataPointId] else interceptReadOnly">
                        <div class="intercept-cell-child">
                            <input
                                (ngModelChange)="validateInterceptValue($event)"
                                [min]="-10"
                                [max]="10"
                                pInputText
                                type="number"
                                [(ngModel)]="rowData.value"
                            />
                        </div>
                    </ng-container>
                    <ng-template #interceptReadOnly>
                        <span class="intercept-cell-child">{{ rowData.value }}</span>
                    </ng-template>
                    <div class="intercept-cell-child intercept-cell-child-btn">
                        <button
                            *ngIf="!editing[dataPointId]"
                            pButton
                            type="button"
                            icon="pi pi-pencil"
                            class="ui-button-info"
                            (click)="onCellEditInit(rowData)"
                        ></button>
                        <button
                            *ngIf="editing[dataPointId]"
                            pButton
                            type="button"
                            icon="pi pi-check"
                            class="ui-button-success"
                            style="margin-right: 0.5em"
                            [disabled]="!isInterceptValueValid"
                            (click)="onCellEditSave(rowData)"
                        ></button>
                        <button
                            *ngIf="editing[dataPointId]"
                            pButton
                            type="button"
                            icon="pi pi-times"
                            class="ui-button-danger"
                            (click)="onCellEditCancel(rowData)"
                        ></button>
                    </div>
                </td>
                <ng-template #normalCell>
                    <td>{{rowData.value}}</td>
                </ng-template>
            </tr>
        </ng-template>
    </p-treeTable>

    <div class="cbms-spinner-wrapper" *ngIf="!properties && !isLoadingProperties">
        <span class="no-datapoints-text">No properties found</span>
    </div>
</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>
