import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { Customer } from 'src/app/models/customer';
import { Site } from 'src/app/models/site';
import { BackendService } from 'src/app/services/backend/backend.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UtilsService } from 'src/app/services/utils/util.service';
import { ASSIGN_BY } from 'src/app/config/constants';
import { DataService } from 'src/app/services/data/data.service';


@Component({
	selector: 'cbms-assign-site',
	templateUrl: './assign-site.component.html',
	styleUrls: ['./assign-site.component.scss']
})
export class AssignSiteComponent implements OnInit {

	public siteList: Site[];
	public customerList: Customer[];
	public selectedCustomer: Customer;
	public selectedSite: Site;
	public customersSubscription: Subscription;
	public sitesSubscription: Subscription;

	constructor(private ref: DynamicDialogRef,
		private config: DynamicDialogConfig,
		private backendService: BackendService,
		private utilsService: UtilsService,
		private dataServ: DataService,
		private notificationService: NotificationService) { }

	ngOnInit(): void {
		this.getCustomerList();
	}

	ngOnDestroy(): void {
		if (this.customersSubscription) {
			this.customersSubscription.unsubscribe();
		}
		if (this.sitesSubscription) {
			this.sitesSubscription.unsubscribe();
		}
	}

	close() {
		this.ref.close();
	}

	public onCustomerChosen() {
		this.getSiteList(this.selectedCustomer.id);
	}

	public send() {
		if (this.config.data.assignBy === ASSIGN_BY.DEVICES) {
			this.backendService.assignDevicesPointsToSite(this.selectedSite.id, this.config.data.idList).subscribe(response => {
				this.notificationService.addSuccessMessage('Assign Site', `Devices points successfully assigned to ${this.selectedSite.name} site`, false);
				this.ref.close();
			}, (err) => {
				this.notificationService.addErrorMessage('Assign Site', err);
			});
		}

		if (this.config.data.assignBy === ASSIGN_BY.DATA_POINTS) {
			this.backendService.assignDataPointsToSite(this.selectedSite.id, this.config.data.idList).subscribe(response => {
				this.notificationService.addSuccessMessage('Assign Site', `${this.config.data.idList.length} points successfully assigned to ${this.selectedSite.name} site`, false);
				this.dataServ.updateDatapointSiteAssignment();
                this.ref.close();
			}, (err) => {
				this.notificationService.addErrorMessage('Assign Site', err);
			});
		}
	}

	private getCustomerList() {
		if (this.customersSubscription) {
			this.customersSubscription.unsubscribe();
		}
		this.customersSubscription = this.backendService.getCustomers().subscribe(customerList => {
			this.customerList = customerList.map(customer => { return { id: customer.id, name: customer.name }; });
		});
	}

	private getSiteList(customerId: string) {
		if (this.sitesSubscription) {
			this.sitesSubscription.unsubscribe()
		}
		this.sitesSubscription = this.backendService.getSitesByCustomer(customerId).subscribe(response => {
			this.siteList = this.utilsService.orderListByFieldName(response.content, 'name');
		});
	}
}
