import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectItem } from 'primeng/api/selectitem';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification/notification.service';
import { uuid } from 'uuidv4';
import moment from 'moment';
import _ from 'lodash';
import { BackendService } from 'src/app/services/backend/backend.service';
import { ViewMode, TRUE, ANALOG, BINARY, MULTISTATE } from '../../config/constants';

const StartTimeValidator: ValidatorFn = (fg: FormGroup) => {
	const start = fg.get('startTime').value;
	const stop = fg.get('stopTime').value;
	return start !== null && stop !== null && start < stop
		? null
		: { startTime: 'not valid' };
};

const StopTimeValidator: ValidatorFn = (fg: FormGroup) => {
	const start = fg.get('startTime').value;
	const stop = fg.get('stopTime').value;
	return start !== null && stop !== null && stop > start
		? null
		: { stopTime: 'not valid' };
};

const atLeastOneCheckboxCheckedValidator: ValidatorFn = (fg: FormGroup) => {
	const applyTo = fg.get('applyTo');
	return (applyTo.value.includes(true) || applyTo.disabled) ? null : { applyTo: 'select at least one day' }
};

const periodValueValidator: ValidatorFn = (fg: FormGroup) => {
	const signalType = fg.get('selectedSignalType');
	const periodValue = fg.get('periodValue');

	if (signalType.value === BINARY) {
		return null;
	}

	if ((signalType.value === ANALOG || signalType.value === MULTISTATE) && isNaN(periodValue.value)) {
		return { periodValue: 'Only numbers are allowed.'}
	}

 	if (signalType.value === MULTISTATE && periodValue.value.toString(10).indexOf('.') !== -1) {
		return { periodValue: 'Decimal value not allowed for multistate signal.' }
	}
};

@Component({
	selector: 'cbms-template-calendar',
	templateUrl: './template-calendar.component.html',
	styleUrls: ['./template-calendar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class TemplateCalendarDialogComponent implements OnInit {
	public template: any;
	public isLoading: boolean = false;
	public saveTemplateForm: FormGroup;
	public savePeriodForm: FormGroup;

	public signalTypeList: SelectItem[] = [
		{ label: 'Select signal type', value: '' },
		{ label: 'Analog', value: ANALOG },
		{ label: 'Binary', value: BINARY },
		{ label: 'MultiState', value: MULTISTATE }
	];

	public binaryTypeList: SelectItem[] = [
		{ label: 'Select value', value: '' },
		{ label: 'On', value: '1' },
		{ label: 'Off', value: '0' }
	];

	public weekDays: any[] = [
		{
			name: 'Monday',
			value: 'monday'
		},
		{
			name: 'Tuesday',
			value: 'tuesday'
		},
		{
			name: 'Wednesday',
			value: 'wednesday'
		},
		{
			name: 'Thursday',
			value: 'thursday'
		},
		{
			name: 'Friday',
			value: 'friday'
		},
		{
			name: 'Saturday',
			value: 'saturday'
		},
		{
			name: 'Sunday',
			value: 'sunday'
		}
	];

	public selectedPeriod: any;
	public isPeriodEditing: boolean = false;
	public shouldReset: boolean = false;
	public isOverlapping: boolean = false;

	private savePeriodFormDefaultValues: any;
	public viewMode: ViewMode;
	public readonly EDIT_MODE = ViewMode.EDIT_MODE;
	public readonly READ_ONLY_MODE = ViewMode.READ_ONLY_MODE;

	constructor(public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private formBuilder: FormBuilder,
		private el: ElementRef,
		private backendService: BackendService,
		private notificationService: NotificationService) {
	}

	ngOnInit() {
		this.viewMode = this.config.data.isReadOnly ? ViewMode.READ_ONLY_MODE : ViewMode.EDIT_MODE;
		this.template = {
			name: this.config.data.name,
			id: this.config.data.id,
			weeklyScheduleCalendarData: _.cloneDeep(this.config.data.weeklyScheduleObject.weeklyScheduleWithInterval),
			scheduleDefault: this.config.data.weeklyScheduleObject.scheduleDefault
		};
        if(this.template.weeklyScheduleCalendarData==null || this.template.weeklyScheduleCalendarData.length==0 ){
            this.template.weeklyScheduleCalendarData=[[],[],[],[],[],[],[]]
        }

		this.template.weeklyScheduleCalendarData.forEach(day => {
			day.forEach(period => {
				period.id = uuid();
			});
		});

		const startTime = new Date();
		const stopTime = moment(startTime).add(1, 'hours').toDate();

		let selectedSignalType = this.getPeriodSignalType(this.config.data.weeklyScheduleObject.weeklyScheduleWithInterval);

		this.savePeriodForm = this.formBuilder.group({
			startTime: new FormControl(startTime, [Validators.required]),
			stopTime: new FormControl(stopTime, [Validators.required]),
			selectedSignalType: new FormControl({value: selectedSignalType, disabled: false}, Validators.required),
			periodValue: new FormControl('', Validators.required),
			allDayPeriod: new FormControl(false),
			applyTo: new FormArray([
				new FormControl(false),
				new FormControl(false),
				new FormControl(false),
				new FormControl(false),
				new FormControl(false),
				new FormControl(false),
				new FormControl(false)
			])
		}, { validators: [StartTimeValidator, StopTimeValidator, atLeastOneCheckboxCheckedValidator, periodValueValidator] });

		this.savePeriodFormDefaultValues = this.savePeriodForm.value;

		this.allDayPeriod.valueChanges.subscribe(value => {
			if (value) {
				this.startTime.disable();
				this.stopTime.disable();
			}
			if (!value) {
				this.startTime.enable();
				this.stopTime.enable();
			}
		});

		this.selectedSignalType.valueChanges.subscribe(value => {
			if (value !== this.selectedSignalType.value) {
				this.periodValue.setValue('');
			}
		});
	}

	get startTime() {
		return this.savePeriodForm.get('startTime') as FormControl;
	}
	get stopTime() {
		return this.savePeriodForm.get('stopTime') as FormControl;
	}
	get periodValue() {
		return this.savePeriodForm.get('periodValue') as FormControl;
	}
	get allDayPeriod() {
		return this.savePeriodForm.get('allDayPeriod') as FormControl;
	}
	get applyTo() {
		return this.savePeriodForm.get('applyTo') as FormControl;
	}
	get selectedSignalType() {
		return this.savePeriodForm.get('selectedSignalType') as FormControl;
	}

	public close() {
		this.ref.close();
	}

	public savePeriod() {
		const formValues = this.savePeriodForm.getRawValue();
		this.isOverlapping = false;

		let start = moment(formValues.startTime).seconds(0).milliseconds(0).format('HH:mm:ss');
		let stop = moment(formValues.stopTime).seconds(0).milliseconds(0).format('HH:mm:ss');

		if (formValues.allDayPeriod) {
			start = "00:00:00";
			stop = "23:59:59"
		}

		const period = {
			start: start,
			end: stop,
			value: Number(formValues.periodValue),
			type: formValues.selectedSignalType,
			id: uuid()
		};

		if (this.isPeriodEditing) {
			if (this.isValidPeriod(period, this.selectedPeriod.weekDay - 1)) {
				let periodIndex = this.template.weeklyScheduleCalendarData[this.selectedPeriod.weekDay - 1].findIndex(period => period.id === this.selectedPeriod.id);
				this.template.weeklyScheduleCalendarData[this.selectedPeriod.weekDay - 1][periodIndex].start = period.start;
				this.template.weeklyScheduleCalendarData[this.selectedPeriod.weekDay - 1][periodIndex].end = period.end;
				this.template.weeklyScheduleCalendarData[this.selectedPeriod.weekDay - 1][periodIndex].value = period.value
				this.template.weeklyScheduleCalendarData[this.selectedPeriod.weekDay - 1][periodIndex].type = period.type;
			} else {
				this.isOverlapping = true;
			}
		} else {
			//adding new period
			if (this.isPeriodValidInEachDay(formValues.applyTo, period)) {
				formValues.applyTo.forEach((day, index) => {
					if (day) {
						period.id = uuid();
						this.template.weeklyScheduleCalendarData[index].push(_.cloneDeep(period));
					};
				});
			} else {
				this.isOverlapping = true;
			}
		}

		if (!this.isOverlapping) {
			this.template.weeklyScheduleCalendarData = [...this.template.weeklyScheduleCalendarData];
			this.resetSavePeriodForm();
		} else {
			this.notificationService.addErrorMessage('Template', 'Period cannot be added because it overlaps with existing ones', false);
		}

		const selectedSignalType = this.getPeriodSignalType(this.template.weeklyScheduleCalendarData);
		if (selectedSignalType) {
			this.selectedSignalType.setValue(selectedSignalType);
			this.selectedSignalType.disable();
			this.savePeriodFormDefaultValues.selectedSignalType = selectedSignalType;
		}
	}

	public onPeriodSelected(period) {
		if (!period) {
			this.resetSavePeriodForm();
			this.resetPeriodSelection();
			return;
		}

		const periodValueInput = this.el.nativeElement.querySelector('.period-value');
		periodValueInput.focus();

		this.isOverlapping = false;
		this.shouldReset = false;
		this.isPeriodEditing = true;
		this.allDayPeriod.disable();
		this.applyTo.disable();
		this.startTime.enable();
		this.stopTime.enable();

		let weekDay = moment(period.start).day();
		if (weekDay === 0) { weekDay = 7; }

		this.selectedPeriod = this.template.weeklyScheduleCalendarData[weekDay - 1].find(existingPeriod => existingPeriod.id === period.id);
		this.selectedPeriod.weekDay = weekDay;

		this.presetFormFieldsOnEditPeriod(period);
	}

	public deletePeriod() {
		const calendarDay = this.selectedPeriod.weekDay - 1;
		let periodIndex = this.template.weeklyScheduleCalendarData[calendarDay].findIndex(period => period.id === this.selectedPeriod.id);

		this.template.weeklyScheduleCalendarData[calendarDay].splice(periodIndex, 1);
		this.template.weeklyScheduleCalendarData = [...this.template.weeklyScheduleCalendarData];

		this.resetSavePeriodForm();

		if (!this.template.weeklyScheduleCalendarData.some((dayPeriod) => dayPeriod.length)) {
			this.savePeriodFormDefaultValues.selectedSignalType = '';
			this.selectedSignalType.setValue('');
			this.selectedSignalType.enable();
		}
	}

	public resetSavePeriodForm() {
		this.isOverlapping = false;
		this.savePeriodForm.reset(this.savePeriodFormDefaultValues);
		this.allDayPeriod.enable();
		this.applyTo.enable();
		this.isPeriodEditing = false;
	}

	public resetPeriodSelection() {
		this.shouldReset = true;
	}

	public saveTemplateChanges() {
        if (this.template.id) {
            //update
            this.backendService.updateWeeklyScheduleTemplate(this.template).subscribe(response => {
                this.ref.close(this.template.id);
                this.notificationService.addSuccessMessage('Template', 'Template successfully updated', false);
            }, (e) => {
                this.notificationService.addErrorMessage('Template', e);
            });
        } else {
            //create
            const createBody = {
                name: this.template.name,
                weeklyScheduleObject: { weeklyScheduleWithInterval: this.template.weeklyScheduleCalendarData },
            };
            this.backendService.saveScheduleAsTemplate(createBody).subscribe((response) => {
                this.ref.close(response.id);
                this.notificationService.addSuccessMessage('Template', 'Template successfully saved', false);
            }, (e) => {
                this.notificationService.addErrorMessage('Template', e);
            });
        }
	}

	private presetFormFieldsOnEditPeriod(event) {
		this.startTime.setValue(event.start);
		this.stopTime.setValue(event.end);
		this.periodValue.setValue(event.extendedProps.value);
		this.selectedSignalType.setValue(this.selectedPeriod.type);
	}

	private isValidPeriod(period, day: number):boolean {
		let isValid = true;
		let start = moment(period.start, 'HH:mm:ss');
		let end = moment(period.end, 'HH:mm:ss');

		this.template.weeklyScheduleCalendarData[day].forEach((existingPeriod) => {
			if (existingPeriod.default) { return; }
			if (this.selectedPeriod && this.selectedPeriod.id === existingPeriod.id) { return; }
			if (moment(existingPeriod.start, 'HH:mm:ss').isBefore(end) && moment(start).isBefore(moment(existingPeriod.end, 'HH:mm:ss'))) {
				isValid = false;
			}
		});
		return isValid;
	}

	private isPeriodValidInEachDay(daysToApply: boolean[], period) {
		let isValid = true;
		daysToApply.forEach((day, index: number) => {
			if (day) {
				if (!this.isValidPeriod(period, index)) {
					isValid = false;
				};
			}
		});
		return isValid;
	}

	private getPeriodSignalType(periodData) {
		let selectedSignalType = '';
		periodData.some((dayPeriods) => {
			if (dayPeriods.length) {
				selectedSignalType = dayPeriods[0].type;
			}
			return dayPeriods.length;
		});

		return selectedSignalType;
	}
}
