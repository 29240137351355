import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { AuthorizationService } from '../../services/utils/authorization.service';

@Component({
    selector: 'cbms-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
    constructor(
        private dataService: DataService,
        private authorizationService: AuthorizationService
    ) {}

    currentYear = new Date().getFullYear();

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('error');
    }

    logout() {
        this.authorizationService.logout();
    }
}
