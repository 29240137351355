import { Site } from './site';
import { Extensions } from './extensions';

export interface DataPointId {
    type: string;
    instance: number;
}

export interface StatusFlags {
    isInAlarm?: boolean;
    isFault?: boolean;
    isOverridden?: boolean;
    isOutOfService?: boolean;
}

export interface DataPointAPI {
    lastRecordedValueTimestamp: string;
    id: string;
    hardwareId?: string;
    externalId?: string;
    name?: string;
    description?: string;
    customName?: string;
    units?: string;
    statusFlags?: StatusFlags;
    lastRecordedValue?: number | boolean;
    polled?: boolean;
    tags?: DataPointTag[];
    type?: string;
    site?: Site;
    deviceName?: string,
    twinSchedules?: any,
    twinCreationDate?: string
    outOfService?: boolean;
    extensions?: Extensions;
    sourceType: string;
    source?:string;
    signalType?:string;
    isCCSource?: boolean;
    isWiseMeterSource?: boolean;
    siteTimezoneOffset?: string;
}

export interface DataPointTag {
    name: string;
    id?: string;
    isNew?: boolean;
    isDeleted?: boolean;
}

export interface ScheduleRequestByTemplateId {
    destinationDataPointIdList: string[],
    scheduleTemplateId?: string,
    updateType: string,
    weeklyScheduleTemplateId?: string,
    exceptionScheduleTemplateId?: string
}

export interface ExceptionScheduleTemplate {
    name: string,
    id?: string,
    siteId?: string,
    exceptionScheduleObject?: any,
    createdBy?: any,
    createdDate?: any,
    modifiedBy?: any
    modifiedDate?: any
}

export const dataPointApiSortFieldMapper = {
    sourceId: 'externalId',
    id: 'id',
    hardwareId: 'hardwareId',
    objectName: 'name',
    description: 'description',
    customName: 'userDescription',
    units: 'units',
    presentValue: 'lastRecordedValue',
    presentValueTimestamp: 'lastRecordedValueTimestamp',
    statusFlags: 'statusFlags',
    polled: 'polled',
    tags: 'tags.name',
    type: 'type',
    site: 'site.name',
    deviceName: 'device.name',
    twinSchedules: 'twinSchedules',
    twinCreationDate: 'twinCreationDate',
    twinDatapointValue: 'twinDatapointValue',
    twinDatapointCreationDate: 'twinDatapointCreationDate',
    deviceSiteName: 'site.name',
    signalType: 'signalType',
    source: 'source',
    ocSiteName: 'ocSiteName',
    ocSiteCustomerId: 'ocSiteCustomerId',
    ocSiteCustomerName: 'ocSiteCustomerName',
    ocSiteTenantName: 'ocSiteTenantName',
    ocIsAccumulator: 'ocIsAccumulator',
    ocDataSource: 'ocDataSource',
    ocClassification: 'ocClassification',
    ocChannel: 'ocChannel',
    ocGranularity: 'ocGranularity',
};
